import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

const PerfilT = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [userPhoto, setUserPhoto] = useState(sessionStorage.getItem("photo")); // Obtener la foto del usuario de sessionStorage

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}users/${id}`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUser();
  }, [id]);

  const handlePhotoChange = async (event) => {
    const selectedPhoto = event.target.files[0];

    const formData = new FormData();
    formData.append("photo", selectedPhoto);

    // Realizar la solicitud PUT al servidor para actualizar la foto del usuario
    const response = await axios.put(
      `${window.BACKEND_URL}users/${id}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );

    if (response.status === 200) {
      window.location.reload(); // Recargar la página después de una actualización exitosa
      setUserPhoto(selectedPhoto.name);

      // Actualizar la foto en sessionStorage
      sessionStorage.setItem("photo", selectedPhoto.name);
    } else {
      console.error("Error al actualizar la foto:", response.statusText);
    }
    // const reader = new FileReader();

    // reader.readAsDataURL(selectedPhoto);
  };

  return (
    <>
      {error && <p>Error: {error}</p>}
      {user && (
        <div className="container bg-custom p-4">
          <div className="row">
            <h1
              className="bg-stemis text-center pb-3 pe-5"
              style={{ fontSize: 45 }}
            >
              Perfil
            </h1>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-6 text-center">
              <div style={{ position: "relative", display: "inline-block" }}>
                <label htmlFor="photoInput">
                  <img
                    src={`${window.BACKEND_URL}uploads/${user.photo}`}
                    alt=""
                    className="rounded-circle img-fluid"
                    style={{
                      border: "solid 6px #009688",
                      minWidth: "12rem",
                      width: "12rem",
                      height: "12rem",
                      objectFit: "cover",
                      maxWidth: "12rem",
                      maxHeight: "12rem",
                      cursor: "pointer",
                    }}
                  />
                  <i
                    className="fa-solid fa-camera"
                    style={{
                      position: "absolute",
                      fontSize: "1.5rem",
                      color: "white",
                      top: "150px",
                      right: "20px",
                      background: "#009688",
                      padding: "12px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  ></i>
                </label>
                <input
                  type="file"
                  id="photoInput"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-6">
              <div className="row mt-lg-0 mt-md-4">
                <div className="col-11">
                  <div className="row">
                    <div className="col-3">
                      <h2 className="text-secondary">Nombre:</h2>
                    </div>
                    <div className="col-9">
                      <input
                        value={user.name}
                        type="text"
                        className="form-control bg-light-subtle rounded-3 border border-3"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-3">
                      <h2 className="text-secondary">Email:</h2>
                    </div>
                    <div className="col-9">
                      <input
                        value={user.email}
                        type="text"
                        className="form-control bg-light-subtle rounded-3 border border-3"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mt-4 text-center">
                    <div className="col">
                      <Link
                        to={`/editpass/${user._id}`}
                        className="btn btn-stemis"
                      >
                        Editar Contraseña
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PerfilT;
