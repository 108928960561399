import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
const Detallescurs = () => {
  const { id } = useParams();
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCursos = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cursos_data_simpl/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setCursos(response.data);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCursos();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Detalles...</p>
        </div>
      ) : (
        <>
          <section>
            <div className="container-fluid">
              <form className="col-12 col-md-10">
                <div className="row d-flex p-2">
                  <div className="col-12 col-md-2  ">
                    <p className=" mb-0 text-center  pt-2">Nombre</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      value={
                        cursos.length > 0
                          ? cursos[0].informacion_titulo[0]?.nombre
                          : ""
                      }
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                      disabled
                    />
                  </div>
                </div>

                <div className="row d-flex p-2">
                  <div className="col-12 col-md-2 ">
                    <p className="mb-0 text-center  pt-2">Aulas</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      disabled
                      value={cursos.length > 0 ? cursos[0].aula : ""}
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                    />
                  </div>
                  <div className="col-12 col-md-2  ">
                    <p className="mb-0 text-center  pt-2">Año</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      value={cursos.length > 0 ? cursos[0].anio : ""}
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                      disabled
                    />
                  </div>
                </div>

                <div className="row d-flex p-2">
                  <div className="col-12 col-md-2 ">
                    <p className="mb-0  text-center  pt-2">Seccion</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      value={cursos.length > 0 ? cursos[0].seccion : ""}
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md-2 ">
                    <p className="mb-0 text-center  pt-2">Periodo</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      value={cursos.length > 0 ? cursos[0].periodo : ""}
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                      disabled
                    />
                  </div>
                </div>

                <div className="row d-flex p-2">
                  <div className="col-12 col-md-2 ">
                    <p className="mb-0  text-center  pt-2">Turno</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      disabled
                      value={
                        cursos.length > 0
                          ? cursos[0].informacion_turno[0].turno
                          : ""
                      }
                      type="text"
                      className="form-control bg-light-subtle rounded-3 border border-3"
                    />
                  </div>
                  <div className="col-12 col-md-2 ">
                    <p className="mb-0 text-center  pt-2">Estado</p>
                  </div>
                  <div className="col-12 col-md">
                    <input
                      type="text"
                      value={
                        cursos.length > 0
                          ? cursos[0].estado
                            ? "activo"
                            : "no activo"
                          : ""
                      }
                      className="form-control bg-light-subtle rounded-3 border border-3"
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Detallescurs;
