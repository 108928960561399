import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // Importa el componente FullCalendar
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";

// Importa el plugin de vista de día

import axios from "axios";

function Calendar() {
  const [events, setEvents] = useState([]); // Estado para almacenar los eventos
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}curso/calendar/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      response.data.forEach((data) => {
        if (data.audiencia) {
          data.audiencia.forEach((grupo) => {
            if (data.seccion) {
              grupo.seccion = data.seccion;
            }
          });
        }
      });

      const audiencias = response.data.reduce((allAudiencias, curso) => {
        if (curso.audiencia) {
          allAudiencias.push(...curso.audiencia);
        }
        return allAudiencias;
      }, []);

      setCursos(audiencias);

      const events = audiencias.map((audiencia) => ({
        title: `${audiencia.tipo_audi}`,
        start: audiencia.fechaHoraInicio,
        end: audiencia.fechaHoraFin,
        datacurso: `${audiencia.curso}`,
        link_audi: `${audiencia.link_audi}`,

        data_seccion: `${audiencia.seccion}`,
      }));

      setEvents(events);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [clickedEvent, setClickedEvent] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleEventClick = (info) => {
    if (clickedEvent && clickedEvent.id === info.event.id) {
      setClickedEvent(null);
    } else {
      setClickedEvent(info.event);
      const rect = info.el.getBoundingClientRect();
      const scroll = window.pageYOffset;
      setModalPosition({
        top: rect.top + scroll,
        left: rect.right + 10,
      });
    }
  };

  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // La hora '0' debe ser '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <div className="container-fluid rounded-end-4">
        <h2 class="py-2 pt-3">Calendario de Audiencias</h2>
        {loading ? (
          <div className="text-center">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p>Cargando Calendario...</p>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4 border rounded-4 shadow ">
              <div class="ps-4 pt-2 mt-2 ">
                <h3>Próximas Audiencias</h3>
              </div>
              <ul
                class="timeline2 ps-0 ms-0"
                style={{
                  marginLeft: "-50px",
                  paddingRight: "-500px",
                }}
                id="linee-scroll"
              >
                {cursos
                  .sort(
                    (a, b) =>
                      new Date(b.fechaHoraInicio) - new Date(a.fechaHoraInicio)
                  ) // Ordenar por fecha descendente
                  .map((audiencia, index) => (
                    <li key={index}>
                      <div
                        class="timeline2-icon ps-3  "
                        style={{ position: "relative", top: "21px" }}
                      >
                        <a href="javascript:;">&nbsp;</a>
                      </div>

                      <div class=" timeline-s ps-5 pt-0 ">
                        <div class="timeline-content ">
                          <div className="row  ">
                            <div className="col-6 pb-3">
                              <h3 className="text-secondary ">
                                {audiencia.tipo_audi}
                              </h3>
                            </div>
                            <div className="col-6 ">
                              <p className="text-secondary text-end">
                                <b>
                                  {new Date(
                                    audiencia.fechaHoraInicio
                                  ).toLocaleDateString("en-US", {
                                    year: "2-digit",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}
                                </b>{" "}
                              </p>
                            </div>

                            <p className="mb-1">
                              <h4>
                                <i class="fa-solid fa-graduation-cap px-2 text-secondary"></i>
                              </h4>
                              {audiencia.curso}
                            </p>
                            <p className="mb-1">
                              <h4>
                                <i class="fa-solid fa-users-between-lines px-2 text-secondary"></i>{" "}
                              </h4>
                              {audiencia.seccion}
                            </p>
                            <p className="mb-1">
                              <h4>
                                <i class="fa-regular fa-clock px-2 text-secondary"></i>
                              </h4>
                              {`${formatTime(
                                new Date(audiencia.fechaHoraInicio)
                              )} - ${formatTime(
                                new Date(audiencia.fechaHoraFin)
                              )} `}
                            </p>
                            <p>
                              <h4>
                                <i class="fa-solid fa-link px-2 text-secondary"></i>
                              </h4>
                              <a
                                href={audiencia.link_audi}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Link
                              </a>
                            </p>
                          </div>
                        </div>
                        {/* <div class="timeline-time">
                        <span class="date">
                          {audiencia.fechaHoraInicio.slice(0, 10)}
                        </span>
                      </div> */}
                      </div>
                    </li>
                  ))}

                {/* <CalendarFecha /> */}
              </ul>
            </div>

            <div className="col-md-8 custom-calendar ">
              <FullCalendar
                plugins={[dayGridPlugin]}
                locale={esLocale}
                headerToolbar={{
                  left: "title",
                  right: "today prev,next",
                }}
                eventClick={handleEventClick}
                themeSystem="bootstrap"
                dayHeaderFormat={{ weekday: "long" }}
                events={events} // Los eventos que se mostrarán en el calendario
                eventClassNames="custom-event"
              />{" "}
              {clickedEvent && (
                <div
                  className=" bg-white p-2 shadow rounded-3"
                  style={{
                    top: modalPosition.top,
                    left: modalPosition.left,
                    zIndex: 9999,
                    position: "absolute",
                  }}
                >
                  <div>
                    <h4>{clickedEvent.title}</h4>{" "}
                    {`${formatTime(
                      new Date(clickedEvent.startStr)
                    )} - ${formatTime(new Date(clickedEvent.endStr))} `}
                  </div>{" "}
                  <h4>{clickedEvent._def.extendedProps.datacurso}</h4> <br />{" "}
                  <h4> {"Seccion : "} </h4>
                  <h4>{clickedEvent._def.extendedProps.data_seccion}</h4>
                  <div>
                    <a
                      href={clickedEvent._def.extendedProps.link_audi}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link de la reunion
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Calendar;
