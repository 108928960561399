import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

function ExpeDate() {
  const [isLoading, setLoading] = useState(true);
  const [demandaLoaded, setDemandaLoaded] = useState(false);

  var style1 = { color: "#4b4b4b", fontSize: "13px" };
  var style2 = {
    border: "1px solid rgb(172, 172, 172)",
    fontSize: "14px",
    color: "#4b4b4b",
    textTransform: "uppercase",
  };
  const { id } = useParams();
  const [expediente, setExpediente] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/data/get/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let alumnoIdsSet = new Set();
      response.data[0].datagrupo[0].alumnose.forEach((alumno) => {
        alumnoIdsSet.add(alumno.alumno_id);
      });
      alumnoIdsSet.add(response.data[0].datacurso[0].docente_id);

      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      const allUsersAlumno = [];
      const allUsersDocente = [];
      const otrosRoles = [];
      users.data.forEach((user) => {
        if (user.role === "Docente") {
          allUsersDocente.push(user);
        } else if (user.role === "Alumno") {
          allUsersAlumno.push(user);
        } else {
          otrosRoles.push(user);
        }
      });

      let pretencioness = new Set();
      pretencioness.add(response.data[0].datagrupo[0].pretencion);
      let dataspretencionIds = Array.from(pretencioness);

      const pretenciones = await axios.get(
        `${window.BACKEND_URL}alumno/pretencion/getids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            Ids: dataspretencionIds,
          },
        }
      );

      const allPretencion = pretenciones.data;

      const promisess = response.data.map(async (expediente) => {
        const updatedDataGrupo = await Promise.all(
          expediente.datagrupo.map(async (grupo) => {
            // Obtener los nombres de los alumnos

            const alumnosPromises = grupo.alumnose.map(async (alumno) => {
              const usuario = allUsersAlumno.find(
                (data) => data._id === alumno.alumno_id
              );

              if (usuario) {
                alumno.nombre = usuario.name;
              } else {
                console.error(
                  "Usuario no encontrado para el ID:",
                  alumno.alumno_id
                );
              }
            });

            await Promise.all(alumnosPromises);

            let pretensionData = {}; // Inicializa pretensionData fuera del bloque if

            const pretencion = allPretencion.find(
              (data) => data._id === grupo.pretencion
            );

            if (pretencion) {
              pretensionData = pretencion; // Asigna todos los datos de la pretensión si se encuentra
            } else {
              console.error(
                "Pretensión no encontrada para el grupo:",
                grupo.pretencion
              );
              // Otra acción si la pretensión no se encuentra
            }

            return {
              ...grupo,
              datapretension: pretensionData,
            };
          })
        );
        const updatedDataCursoPromises = expediente.datacurso.map(
          async (data) => {
            const docente = allUsersDocente.find(
              (user) => user._id === data.docente_id
            );

            if (docente) {
              data.namedocente = docente.name;
            } else {
              console.error(
                "Usuario no encontrado para el ID:",
                data.docente_id
              );
            }

            return data;
          }
        );

        const updatedDataCurso = await Promise.all(updatedDataCursoPromises);

        return {
          ...expediente,
          datagrupo: updatedDataGrupo,
          datacurso: updatedDataCurso,
        };
      });

      // Esperar a que todas las solicitudes se completen
      const updatedExpedientess = await Promise.all(promisess);

      setExpediente(updatedExpedientess);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDemanda = async (demanda) => {
    if (demanda && !demandaLoaded) {
      try {
        var recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };
        if (demanda.sala_suprema_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala_suprema = await axios.get(
            `${window.BACKEND_URL}alumno/sala_suprema/getid/${demanda.sala_suprema_id}`,
            { headers: headers2 }
          );
          recopila_data.sala_suprema = response_sala_suprema.data.nombre;
        }
        if (demanda.sala_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala = await axios.get(
            `${window.BACKEND_URL}alumno/salas/getid/${demanda.sala_id}`,
            { headers: headers2 }
          );
          recopila_data.sala = response_sala.data.nombre;
        }

        const response_sede_id = await axios.get(
          `${window.BACKEND_URL}alumno/sede_judicial/get/${demanda.sede_id}`,
          { headers: headers2 }
        );
        recopila_data.sede = response_sede_id.data.sede;
        const response_distrito = await axios.get(
          `${window.BACKEND_URL}alumno/distrito_judicial/getid/${demanda.distrito_id}`,
          { headers: headers2 }
        );
        recopila_data.distrito = response_distrito.data.nombre;
        const response_juzgado = await axios.get(
          `${window.BACKEND_URL}alumno/juzgados/getid/${demanda.juzgado_id}`,
          { headers: headers2 }
        );
        recopila_data.juzgado = response_juzgado.data.nombrejuzgado;
        console.log();
        const updatedExpediente = {
          ...expediente[0],
          datademanda: recopila_data,
        };
        console.log(recopila_data);
        setExpediente([updatedExpediente, ...expediente.slice(1)]);

        setDemandaLoaded(true);
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }
  };

  useEffect(() => {
    if (expediente.length > 0 && !demandaLoaded) {
      fetchDemanda(expediente[0].demanda);
    }
  }, [expediente[0]?.demanda, demandaLoaded]);

  useEffect(() => {
    fetchData();
  }, [isLoading]);
  return (
    <>
      {isLoading ? (
        <p>Cargando...</p>
      ) : (
        <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
          <div className="container-fluid rounded-end-4">
            <h2 className="py-2 pt-3">Expedientes Electrónicos</h2>
          </div>

          {expediente.map((data) => (
            <section key={data._id}>
              <div className="container-fluid mt-3">
                <h3>Identificación de Procesos</h3>
                <hr className="p-0" />
                <div className="row">
                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          N° del Expediente:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.nexpediente}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Juez:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Juez")
                            .map((alumno) => alumno.nombre)
                            .join(", ")}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Demandado:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <textarea
                          className="form-control"
                          style={{
                            ...style2,
                            overflowY: "hidden",
                            resize: "none",
                            fontSize: "11.5px",
                            lineHeight: "2",
                          }}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Demandado")
                            .map((alumno) => alumno?.nombre)
                            .join("\n")}
                          readOnly
                          disabled
                          rows={
                            data.datagrupo[0].alumnose.filter(
                              (alumno) => alumno.rol_id === "Demandado"
                            ).length
                          }
                          onScroll={(e) => {
                            e.target.scrollTop =
                              e.target.scrollHeight - e.target.clientHeight;
                          }}
                          onWheel={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Distrito Judicial:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.distrito : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Juzgado:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.juzgado : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Sala suprema:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          value={
                            data.datademanda
                              ? data.datademanda.sala_suprema
                              : null
                          }
                          className="form-control"
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Docente:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datacurso[0]?.namedocente}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Especialista:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={data.datagrupo[0].alumnose
                            .filter(
                              (alumno) => alumno.rol_id === "Especialista"
                            )
                            .map((alumno) => alumno.nombre)
                            .join(", ")}
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Demandante:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <textarea
                          className="form-control"
                          style={{
                            ...style2,
                            overflowY: "hidden",
                            resize: "none",
                            fontSize: "11.5px",
                            lineHeight: "2",
                          }}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Demandante")
                            .map((alumno) => alumno?.nombre)
                            .join("\n")}
                          readOnly
                          disabled
                          rows={
                            data.datagrupo[0].alumnose.filter(
                              (alumno) => alumno.rol_id === "Demandante"
                            ).length
                          }
                          onScroll={(e) => {
                            e.target.scrollTop =
                              e.target.scrollHeight - e.target.clientHeight;
                          }}
                          onWheel={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sede:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={
                            data.datademanda ? data.datademanda.sede : null
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sala:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.sala : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sumilla:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          value={data.demanda ? data.demanda.sumilla : null}
                          className="form-control"
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col col-md-2 mt-2 px-4">
                    <p className="text-left" style={style1}>
                      Observaciones:
                    </p>
                  </div>
                  <div className="col-md-10 mt-2 px-1">
                    <textarea
                      type="text"
                      value={data.demanda ? data.demanda.observaciones : null}
                      className="form-control"
                      style={{
                        ...style2,
                        resize: "none",
                        height: "auto",
                        overflowY: "hidden",
                      }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </section>
          ))}
        </div>
      )}
    </>
  );
}

export default ExpeDate;
