import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditRubrica = () => {
  const { cursoid, id } = useParams();

  const [rubrica, setRubrica] = useState(null);
  const guardarBtnRef = useRef(null);
  const [tableData, setTableData] = useState([]);

  const obtenerRubrica = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/${cursoid}/rubrica/${id}`
      );
      setRubrica(response.data);
      console.log(response.data);
      const initialTableData = response.data.criterios.map((criterio) => ({
        criterio: criterio.criterio,
        descripciones: criterio.descripciones.map((desc) => desc.descripcion),
        calificaciones: criterio.descripciones.map((desc) => desc.nota),
      }));
      setTableData(initialTableData);
    } catch (error) {
      console.error("Error al obtener la rúbrica:", error);
    }
  };

  useEffect(() => {
    obtenerRubrica();
  }, []);

  const addRow = () => {
    setTableData((prevTableData) => [
      ...prevTableData,
      {
        criterio: "",
        descripciones: [""],
        calificaciones: [0],
      },
    ]);
  };

  const deleteRow = (index) => {
    setTableData((prevTableData) =>
      prevTableData.filter((_, rowIndex) => rowIndex !== index)
    );
  };

  const sendDataToBackend = async () => {
    try {
      await axios.put(`${window.BACKEND_URL}docente/${cursoid}/rubrica/${id}`, {
        // Assuming req.body contains the updated rubrica data
        titulo: document.getElementById("titulo_rubi").value,
        criterios: tableData.map((rowData) => ({
          criterio: rowData.criterio,
          descripciones: rowData.descripciones.map((descripcion, index) => ({
            descripcion: descripcion,
            nota: rowData.calificaciones[index],
          })),
        })),
      });
      Swal.fire(`Rúbrica actualizada correctamente`, "", "success");
      window.history.back();
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  useEffect(() => {
    const saveRubrica = () => {
      sendDataToBackend(); // Invocar la función para enviar los datos al servidor
    };

    if (guardarBtnRef.current) {
      guardarBtnRef.current.addEventListener("click", saveRubrica);
    }

    return () => {
      if (guardarBtnRef.current) {
        guardarBtnRef.current.removeEventListener("click", saveRubrica);
      }
    };
  }, [tableData]);

  const handleCancelar = () => {
    window.history.back();
  };

  if (!rubrica) {
    return (
      <div>
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Rubrica...</p>
        </div>
      </div>
    );
  }

  const deleteCell = (rowIndex, cellIndex) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            descripciones: rowData.descripciones.filter(
              (_, descIndex) => descIndex !== cellIndex
            ),
            calificaciones: rowData.calificaciones.filter(
              (_, calIndex) => calIndex !== cellIndex
            ),
          };
        }
        return rowData;
      })
    );
  };

  const updateTotal = (rowIndex, cellIndex, value) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            calificaciones: rowData.calificaciones.map((cal, calIndex) =>
              calIndex === cellIndex ? parseInt(value) : cal
            ),
            descripciones: rowData.descripciones.map((desc, descIndex) =>
              descIndex === cellIndex ? desc : desc
            ),
          };
        }
        return rowData;
      })
    );
  };

  const addColumn = (rowIndex) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            descripciones: [...rowData.descripciones, ""],
            calificaciones: [...rowData.calificaciones, 0],
          };
        }
        return rowData;
      })
    );
  };
  return (
    <>
      <section>
        <div className="container-fluid m-2">
          <h2 className="rubrica-title">Título de la Rubrica</h2>
          <input
            type="text"
            placeholder="Ingresa tu texto aquí"
            className=" rounded border border-3"
            name="titulo_rubi"
            id="titulo_rubi"
            defaultValue={rubrica.titulo}
          />
        </div>
        <div className="container">
          <table className="table table-bordered">
            <tbody>
              {tableData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <textarea
                      type="text"
                      rows="4"
                      value={rowData.criterio}
                      onChange={(e) => {
                        const newTableData = [...tableData];
                        newTableData[rowIndex].criterio = e.target.value;
                        setTableData(newTableData);
                      }}
                      className="form-control required"
                    />
                  </td>
                  {rowData.descripciones.map((descripcion, cellIndex) => (
                    <td key={cellIndex}>
                      <textarea
                        className="form-control required"
                        type="text"
                        rows="4"
                        value={descripcion}
                        onChange={(e) => {
                          const newTableData = tableData.map(
                            (prevRowData, prevRowIndex) =>
                              prevRowIndex === rowIndex
                                ? {
                                    ...prevRowData,
                                    descripciones:
                                      prevRowData.descripciones.map(
                                        (prevDesc, prevDescIndex) =>
                                          prevDescIndex === cellIndex
                                            ? e.target.value
                                            : prevDesc
                                      ),
                                  }
                                : prevRowData
                          );
                          setTableData(newTableData);
                        }}
                      />
                      <br />
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteCell(rowIndex, cellIndex)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                        <div className="col">
                          <input
                            type="number"
                            className="form-control"
                            value={rowData.calificaciones[cellIndex]}
                            min="0"
                            max="5"
                            onChange={(e) =>
                              updateTotal(rowIndex, cellIndex, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </td>
                  ))}
                  <td>
                    <br />
                    <button className="btn" onClick={() => addColumn(rowIndex)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </td>
                  <td>
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={() => deleteRow(rowIndex)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-stemis" onClick={addRow}>
            <i className="fas fa-plus"></i> Agregar Criterio
          </button>
        </div>
        <div className="container d-flex justify-content-end">
          {/* Botón para cancelar */}
          <button className="btn btn-cancelar me-2" onClick={handleCancelar}>
            Cancelar
          </button>
          {/* Botón para guardar */}
          <button
            id="guardarBtn"
            ref={guardarBtnRef}
            type="submit"
            className="btn btn-stemis"
          >
            Guardar
          </button>
        </div>
      </section>
    </>
  );
};

export default EditRubrica;
