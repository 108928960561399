import React, { useState, useEffect } from "react";
import Tavnav from "../../components/tabscustom";
import TituloCurso from "../../components/titulodoc";
import { useParams } from "react-router-dom";

const Documentos = () => {
  const { id } = useParams();
  return (
    <>
      <TituloCurso />
      <Tavnav />
    </>
  );
};

export default Documentos;
