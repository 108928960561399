import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
function Calificaciones() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalIdentifier, setModalIdentifier] = useState(null);

  const openModal = (identifier) => {
    setModalIdentifier(identifier);
    setModalIsOpen(true);
  };

  const [datacursos, setDataCursos] = useState([]);

  const [loading, setLoading] = useState(true);

  const [data_id] = useState(sessionStorage.getItem("_id"));

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "35px",
      maxWidth: "1500px",
      width: "90%",
    },
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${window.BACKEND_URL}alumno/grupocurso/calificaciones/getid/${data_id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Suponiendo que filteredalumno es el array de expedientes
      const expedientesActualizados = response.data.map((expediente) => {
        // Filtrar las notificaciones que coincidan con el ID del usuario logeado
        const notificacionesFiltradas =
          expediente.expediente_info[0].notifica.filter(
            (notificacion) => notificacion.alum_id === data_id
          );

        // Clonar el expediente y actualizar la propiedad notifica con las notificaciones filtradas
        return {
          ...expediente,
          expediente_info: [
            {
              ...expediente.expediente_info[0],
              notifica: notificacionesFiltradas,
            },
          ],
        };
      });

      // Filtrar los expedientes actualizados para obtener solo aquellos que tienen notificaciones
      const expedientesConNotificaciones = expedientesActualizados.filter(
        (expediente) => {
          // Verificar si hay notificaciones en el primer elemento del array notifica
          return expediente.expediente_info[0].notifica.length > 0;
        }
      );

      for (const expediente of expedientesConNotificaciones) {
        const tituloId = expediente.datacursos[0].titulo_id;
        const token = sessionStorage.getItem("token");

        const responsetitu = await axios.get(
          `${window.BACKEND_URL}alumno/titulo/get/${tituloId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        for (const datacurso of expediente.datacursos) {
          datacurso.tituloname = responsetitu.data.nombre;
        }
      }

      // Imprimir el contenido de expedientesConNotificaciones

      if (expedientesConNotificaciones[0].expediente_info[0].cuaderno[0]) {
        let datosidss = new Set();
        expedientesConNotificaciones[0].expediente_info[0].cuaderno.forEach(
          (datoid) => {
            datosidss.add(datoid.cuaderno_id);
          }
        );

        let da = Array.from(datosidss);
        console.log(da);
        const respo = await axios.get(
          `${window.BACKEND_URL}alumno/expe_cuaderno/calificaciones/get`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
            params: {
              userIds: da,
            },
          }
        );
        console.log("data", respo.data);
        expedientesConNotificaciones[0].notificaciones = respo.data;
      }

      console.log(expedientesConNotificaciones);
      setDataCursos(expedientesConNotificaciones);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid ">
        <h1 className="py-2 pt-3">Libro de Calificaciones</h1>

        {loading ? (
          <div className="text-center">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p>Cargando Calificaciones...</p>
          </div>
        ) : (
          <div>
            {datacursos.length === 0 ? (
              <div class="d-flex align-items-center justify-content-center h-50">
                <div class="text-center">
                  {/* <h1 class="display-1 fw-bold">:C</h1> */}
                  <p class="fs-3">
                    {/* <span class="text-danger">Opps!</span> */}
                    Todavia no presento archivos.
                  </p>
                </div>
              </div>
            ) : (
              datacursos.map((data, index) => (
                <section
                  key={index}
                  className=" bg-body-secondary  rounded-5 shadow mb-3"
                >
                  <div class=" pt-3">
                    <b className="ps-3">
                      Curso: {data.datacursos[0].tituloname}
                    </b>
                    <hr className="mx-2" />
                  </div>

                  <div className="container pb-4">
                    <div className="row justify-content-center px-4">
                      <div className="col-md-4 px-1 m-0">
                        <div className="px-2 flex-fill mx-2 rounded-4">
                          <h6 className="text-center pb-2 ">
                            <b> Información del caso </b>
                          </h6>

                          <div className="container-fluid bg-dark-subtle  shadow rounded-2   ">
                            <div className="row position-relative m-0 g-0 pt-2">
                              <div className="col-5  p-0 m-0">
                                <b>Pretencion</b>
                              </div>
                              <div className="col-7 p-0 m-0">
                                {data.datapretension[0].nombre}
                              </div>
                            </div>
                            <hr className=" m-0 py-1 " />
                            <div className="row m-0 p-0 ">
                              <div className="col-5  p-0 m-0">
                                <b>Materia</b>
                              </div>
                              <div className="col-7 p-0 m-0">
                                {" "}
                                {data.datapretension[0].tipo}
                              </div>
                            </div>
                            <hr className=" m-0 py-1 " />
                            <div className="row m-0 p-0   ">
                              <div className="col-5  p-0 m-0">
                                <b>Expediente</b>
                              </div>
                              <div className="col-7 p-0 m-0">
                                {data.expediente_info[0].nexpediente}
                              </div>
                            </div>
                            <hr className=" m-0 py-1 " />
                            <div className="row m-0 p-0 ">
                              <div className="col-5  p-0 m-0">
                                <b>Cuadernos</b>
                              </div>
                              <div className="col-7 p-0 m-0">
                                {data.expediente_info[0].cuaderno.length}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 ">
                        <div className="row m-0 p-0">
                          <div class="col-md-6  px-4 text-center">
                            <b> Notas </b>
                          </div>
                          <div class="col-md-6 px-4 text-center">
                            <b> Ver Rúbrica Calificada </b>
                          </div>
                          {data.alumnose.rol_id === "Demandante" && (
                            <div className="row m-0 p-0" key={index}>
                              <div className="col-md-6 px-4 text-center">
                                <div className="row pt-4">
                                  <div className="col-6">
                                    <p>Demanda</p>
                                  </div>

                                  <div className="col-6">
                                    <input
                                      className="form-control form-control-sm"
                                      style={{
                                        border: "1px solid rgb(172, 172, 172)",
                                        fontSize: "14px",
                                        color: "#4b4b4b",
                                        minWidth: "60px",
                                        textAlign: "center",
                                      }}
                                      disabled
                                      value={
                                        data.expediente_info[0].demanda
                                          .calificacion != null
                                          ? data.expediente_info[0].demanda
                                              .calificacion
                                          : "--"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 px-4 text-center">
                                <div className="row m-0 p-0">
                                  <div className="col-md-12 text-center">
                                    {data.expediente_info[0].demanda
                                      .observanoti ? (
                                      <p className="text-center mt-4">
                                        {
                                          data.expediente_info[0].demanda
                                            .observanoti
                                        }
                                      </p>
                                    ) : (
                                      <p className="text-center mt-4">
                                        {data.expediente_info[0].demanda
                                          .calificacion != null
                                          ? "---"
                                          : "Sin Calificar... "}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <table className="">
                            <tbody>
                              {data.expediente_info[0].notifica.map(
                                (notifi, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{ width: "7rem" }}
                                      className="text-truncate px-1"
                                    >
                                      {" "}
                                      {notifi.acto
                                        ? `N° ${index + 1} ${notifi.acto}`
                                        : "--"}
                                    </td>{" "}
                                    <td
                                      style={{ width: "4rem" }}
                                      className="text-truncate px-2"
                                    >
                                      {" "}
                                      {data.alumnose.rol_id}
                                    </td>{" "}
                                    <td
                                      style={{ width: "3rem" }}
                                      className="text-truncate ps-3"
                                    >
                                      {notifi.calificacion != null
                                        ? notifi.calificacion
                                        : "--"}
                                    </td>
                                    <td
                                      style={{ width: "14rem" }}
                                      className="  text-center"
                                    >
                                      {" "}
                                      {notifi.rubrica ? (
                                        <>
                                          <button
                                            id="myButton"
                                            type="button"
                                            className="btn btn-success px-5 mt-4"
                                            onClick={() =>
                                              openModal(notifi.calificacion)
                                            }
                                          >
                                            <i className="fa-regular fa-eye"></i>
                                          </button>

                                          {data.datacursos[0].rubricas.map(
                                            (datarubricas, indexx) => (
                                              <div key={indexx}>
                                                {datarubricas?.rubrica_id ===
                                                  notifi.rubrica && (
                                                  <>
                                                    {data.datacursos[0].rubricas
                                                      .filter(
                                                        (rubrica) =>
                                                          rubrica?.rubrica_id ===
                                                          datarubricas?.rubrica_id
                                                      )
                                                      .map(
                                                        (
                                                          datarubrica,
                                                          index
                                                        ) => (
                                                          <Modal
                                                            key={index}
                                                            isOpen={
                                                              modalIsOpen &&
                                                              modalIdentifier ===
                                                                notifi.calificacion
                                                            }
                                                            onRequestClose={() =>
                                                              setModalIsOpen(
                                                                false
                                                              )
                                                            }
                                                            style={customStyles}
                                                          >
                                                            <h6 className="">
                                                              NOTA DE LA
                                                              CALIFICACION.
                                                              {
                                                                notifi.calificacion
                                                              }
                                                            </h6>

                                                            <div>
                                                              <p>
                                                                {
                                                                  datarubrica.titulo
                                                                }
                                                              </p>

                                                              {datarubrica.criterios.map(
                                                                (
                                                                  data,
                                                                  index
                                                                ) => (
                                                                  <table
                                                                    key={index}
                                                                    className="table  table-light table-responsive rounded "
                                                                  >
                                                                    <thead className="table-secondary bg-secondary bg-opacity-10  shadow">
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            backgroundColor:
                                                                              "#009688",
                                                                            color:
                                                                              "white",
                                                                          }}
                                                                        >
                                                                          {
                                                                            data.criterio
                                                                          }
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            backgroundColor:
                                                                              "#009688",
                                                                            color:
                                                                              "white",
                                                                          }}
                                                                        >
                                                                          NOTAS
                                                                        </td>
                                                                      </tr>
                                                                    </thead>
                                                                    {data.descripciones.map(
                                                                      (
                                                                        data2,
                                                                        index2
                                                                      ) => (
                                                                        <tbody
                                                                          className="bg-stemis shadow"
                                                                          key={
                                                                            index2
                                                                          }
                                                                        >
                                                                          <tr>
                                                                            <td className="w-75 h-75">
                                                                              {data2.descripcion +
                                                                                index}
                                                                            </td>
                                                                            <td
                                                                              className={`text-center fs-5 align-items-center justify-content-center `}
                                                                              style={{
                                                                                backgroundColor:
                                                                                  notifi
                                                                                    .slecion[
                                                                                    index
                                                                                  ]
                                                                                    .criterioIndex ===
                                                                                    index &&
                                                                                  index2 ===
                                                                                    notifi
                                                                                      .slecion[
                                                                                      index
                                                                                    ]
                                                                                      .descripcionIndex
                                                                                    ? "#009688"
                                                                                    : "", // Color de fondo
                                                                                color:
                                                                                  notifi
                                                                                    .slecion[
                                                                                    index
                                                                                  ]
                                                                                    .criterioIndex ===
                                                                                    index &&
                                                                                  index2 ===
                                                                                    notifi
                                                                                      .slecion[
                                                                                      index
                                                                                    ]
                                                                                      .descripcionIndex
                                                                                    ? "white"
                                                                                    : "", // Color del texto
                                                                              }}
                                                                            >
                                                                              {
                                                                                data2.nota
                                                                              }
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      )
                                                                    )}
                                                                  </table>
                                                                )
                                                              )}
                                                            </div>

                                                            <button
                                                              className="btn btn-cancelar  "
                                                              onClick={() =>
                                                                setModalIsOpen(
                                                                  false
                                                                )
                                                              }
                                                            >
                                                              Close Modals
                                                            </button>
                                                          </Modal>
                                                        )
                                                      )}
                                                  </>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : notifi.observanoti ? (
                                        <p className="text-center mt-4">
                                          {notifi.observanoti}
                                        </p>
                                      ) : (
                                        <p className="text-center mt-4">
                                          {notifi.calificacion != null
                                            ? "---"
                                            : "Sin Calificar "}
                                        </p>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>

                          {data.notificaciones &&
                            data.notificaciones.length > 0 && (
                              <div className="row m-0 p-0" key={index}>
                                {data.notificaciones.map((noti, index) => (
                                  <div key={index} className="col-md-12  ">
                                    {" "}
                                    <div class="text-center">
                                      <h3> {noti.nexpediente} </h3>
                                    </div>
                                    <hr className="p-0 m-0" />
                                    <div className="row pt-2">
                                      <table className="">
                                        <tbody>
                                          {noti.notifica &&
                                            noti.notifica.map(
                                              (cuaderdato, index2) => (
                                                <tr key={index2}>
                                                  <td
                                                    style={{ width: "7rem" }}
                                                    className="text-truncate px-1"
                                                  >
                                                    {cuaderdato.acto
                                                      ? `N° ${index + 1} ${
                                                          cuaderdato.acto
                                                        }`
                                                      : "--"}
                                                  </td>
                                                  <td
                                                    style={{ width: "4rem" }}
                                                    className="text-truncate px-2"
                                                  >
                                                    {data.alumnose.rol_id}
                                                  </td>
                                                  <td
                                                    style={{ width: "3rem" }}
                                                    className="text-truncate ps-3"
                                                  >
                                                    {cuaderdato.calificacion !=
                                                    null
                                                      ? cuaderdato.calificacion
                                                      : "--"}
                                                  </td>
                                                  <td
                                                    style={{ width: "14rem" }}
                                                    className="text-end"
                                                  >
                                                    {cuaderdato.rubrica ? (
                                                      <div className="text-center ">
                                                        <button
                                                          id="myButton"
                                                          type="button"
                                                          className="btn btn-success px-5 mt-4"
                                                          onClick={() =>
                                                            openModal(
                                                              cuaderdato.calificacion
                                                            )
                                                          }
                                                        >
                                                          <i className="fa-regular fa-eye"></i>
                                                        </button>

                                                        {data.datacursos[0].rubricas.map(
                                                          (
                                                            datarubricas,
                                                            indexx
                                                          ) => (
                                                            <div key={indexx}>
                                                              {datarubricas?.rubrica_id ===
                                                                cuaderdato.rubrica && (
                                                                <>
                                                                  {data.datacursos[0].rubricas
                                                                    .filter(
                                                                      (
                                                                        rubrica
                                                                      ) =>
                                                                        rubrica?.rubrica_id ===
                                                                        datarubricas?.rubrica_id
                                                                    )
                                                                    .map(
                                                                      (
                                                                        datarubrica,
                                                                        index
                                                                      ) => (
                                                                        <Modal
                                                                          key={
                                                                            index
                                                                          }
                                                                          isOpen={
                                                                            modalIsOpen &&
                                                                            modalIdentifier ===
                                                                              cuaderdato.calificacion
                                                                          }
                                                                          onRequestClose={() =>
                                                                            setModalIsOpen(
                                                                              false
                                                                            )
                                                                          }
                                                                          style={
                                                                            customStyles
                                                                          }
                                                                        >
                                                                          <h6 className="">
                                                                            NOTA
                                                                            DE
                                                                            LA
                                                                            CALIFICACION.
                                                                            {
                                                                              cuaderdato.calificacion
                                                                            }
                                                                          </h6>

                                                                          <div>
                                                                            <p>
                                                                              {
                                                                                datarubrica.titulo
                                                                              }
                                                                            </p>

                                                                            {datarubrica.criterios.map(
                                                                              (
                                                                                data,
                                                                                index
                                                                              ) => (
                                                                                <table
                                                                                  key={
                                                                                    index
                                                                                  }
                                                                                  className="table  table-light table-responsive rounded "
                                                                                >
                                                                                  <thead className="table-secondary bg-secondary bg-opacity-10  shadow">
                                                                                    <tr>
                                                                                      <td>
                                                                                        {
                                                                                          data.criterio
                                                                                        }
                                                                                      </td>
                                                                                      <td>
                                                                                        NOTAS
                                                                                      </td>
                                                                                    </tr>
                                                                                  </thead>
                                                                                  {data.descripciones.map(
                                                                                    (
                                                                                      data2,
                                                                                      index2
                                                                                    ) => (
                                                                                      <tbody
                                                                                        key={
                                                                                          index2
                                                                                        }
                                                                                      >
                                                                                        <tr>
                                                                                          <td>
                                                                                            {data2.descripcion +
                                                                                              index}
                                                                                          </td>
                                                                                          <td
                                                                                            className={`${
                                                                                              cuaderdato
                                                                                                .slecion[
                                                                                                index
                                                                                              ]
                                                                                                .criterioIndex ===
                                                                                                index &&
                                                                                              index2 ===
                                                                                                cuaderdato
                                                                                                  .slecion[
                                                                                                  index
                                                                                                ]
                                                                                                  .descripcionIndex
                                                                                                ? "bg-warning"
                                                                                                : ""
                                                                                            }`}
                                                                                          >
                                                                                            {
                                                                                              data2.nota
                                                                                            }
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    )
                                                                                  )}
                                                                                </table>
                                                                              )
                                                                            )}
                                                                          </div>

                                                                          <button
                                                                            onClick={() =>
                                                                              setModalIsOpen(
                                                                                false
                                                                              )
                                                                            }
                                                                          >
                                                                            Close
                                                                            Modals
                                                                          </button>
                                                                        </Modal>
                                                                      )
                                                                    )}
                                                                </>
                                                              )}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : cuaderdato.observanoti ? (
                                                      <p className="text-center mt-4">
                                                        {cuaderdato.observanoti}
                                                      </p>
                                                    ) : (
                                                      <p className="text-center mt-4">
                                                        {cuaderdato.calificacion !=
                                                        null
                                                          ? "---"
                                                          : "Sin Calificar... "}
                                                      </p>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Calificaciones;
