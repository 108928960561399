import React, { useEffect, useState } from "react";

import DatosCurso from "./DatosCurso";
import { useParams } from "react-router-dom";
import axios from "axios";

function Cursos() {
  const [datos, setCursos] = useState([]);
  const [seccion, setSeccion] = useState("cursos"); // Estado para almacenar la sección actual
  // Función para cambiar la sección
  const cambiarSeccion = (nuevaSeccion) => {
    setSeccion(nuevaSeccion);
  };
  const { id } = useParams();

  useEffect(() => {
    const getCursos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}alumno/curso/titulo/get/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data);

        setCursos(response.data);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };

    getCursos();
  }, [id]);

  // Verificar si hay datos y asignar el nombre del curso a una variable

  return (
    <>
      <div className="container-fluid rounded-end-4">
        {/* Usar la variable cursoNombre en lugar de la verificación directa */}

        <h2 className="py-2 pt-3">
          {datos[0]?.informacion_titulo[0]?.nombre} {"-   Seccion :"}
          {datos[0]?.seccion}
        </h2>

        <hr />
        <section>
          <div className="navbar w-100 d-flex justify-content-start ">
            {/* Botones para cambiar la sección */}
            <a
              onClick={() => cambiarSeccion("cursos")}
              style={{
                borderBottom:
                  seccion === "cursos" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              Todos los cursos
            </a>
            {/* <a
              onClick={() => cambiarSeccion("documentos")}
              style={{
                borderBottom:
                  seccion === "documentos" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              Documentos del curso
            </a> */}
          </div>
        </section>
        {/* Renderizado condicional de la sección de cursos */}
        {seccion === "cursos" && <DatosCurso />}
        {/* {seccion === "documentos" && <DatosCurso />} */}
      </div>
    </>
  );
}

export default Cursos;
