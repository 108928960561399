import { useParams } from "react-router-dom";

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Anuncios = () => {
  const { id } = useParams();
  const [anuncios, setAnuncios] = useState([]);

  const [titulos, setTitulos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [titulo, setTitulo] = useState("");
  const [detalles, setDetalles] = useState("");
  const photo = sessionStorage.getItem("photo");
  const _id = sessionStorage.getItem("_id");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar el rol del usuario
    const userRole = sessionStorage.getItem("role");
    if (userRole !== "Docente") {
      // Mostrar un mensaje de error
      Swal.fire(
        `El usuario no tiene permisos para crear anuncios.`,
        "",
        "error"
      );
      return; // Detener el envío del formulario
    }

    try {
      const anunciodata = {
        curso_id: id,
        titulo: titulo,
        detalles: detalles,
        titulo_curso: titulos,
        doc_id: _id,
      };

      await axios.post(
        `${window.BACKEND_URL}Docente/anuciospost`,
        anunciodata,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": userRole, // Usar userRole en lugar de sessionStorage.getItem("role")
          },
        }
      );
      Swal.fire(`Anuncio creado correctamente`, "", "success");

      getAnuncios();
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setTitulo(""); // Limpiar el valor del titulo
      setDetalles("");
    }
  };

  const handleChangeTitulo = (e) => {
    setTitulo(e.target.value);
  };

  const handleChangeDetalles = (e) => {
    setDetalles(e.target.value);
  };

  const getCursos = async () => {
    const userRole = sessionStorage.getItem("role");

    if (userRole === "Docente") {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/cursos_datatitulounico/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        setTitulos(response.data[0].informacion_titulo[0].nombre);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching cursos:", error);
        setLoading(false);
      }
    } else {
      // Mostrar un mensaje de error o redirigir a otra página
      Swal.fire({
        title: "Error 404",
        text: "Recurso no encontrado",
        icon: "error",
        showConfirmButton: false, // No mostrar el botón de confirmación
        allowOutsideClick: false, // No permitir cerrar haciendo clic fuera del modal
      });
      setLoading(false); // Asegúrate de detener la carga en caso de error
    }
  };

  const getAnuncios = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/anuncios`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setAnuncios(response.data);

      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cursos:", error);
      setLoading(false);
    }
  };

  const deleteAnuncio = async (_id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}docente/anuncios/${_id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      Swal.fire(`Anuncio eliminado correctamente `, "", "success");

      getAnuncios();
      getCursos();
    } catch (error) {
      console.error("Error eliminando el anuncio:", error);
    }
  };

  useEffect(() => {
    getAnuncios();
    getCursos();
  }, [id]);

  const filteredAnuncios = anuncios
    .filter((anuncio) => anuncio.curso_id === id) // Filtrar por curso_id igual a id
    .sort((a, b) => new Date(b.created) - new Date(a.created)) // Ordenar por fecha de creación descendente
    .slice(0, 3); // Obtener solo los tres últimos anuncios

  return (
    <>
      {/* <TituloCurso />
      <Tavnav /> */}
      <section>
        <h2 className="ms-2">Agregar Anuncio</h2>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 ">
              <form onSubmit={handleSubmit}>
                <div className="row  align-items-center pt-2">
                  <div className="col-12 col-md-3 mb-2 mb-md-0">
                    <label htmlFor="inputEmail3">
                      <p className="mb-0">Curso</p>
                    </label>
                  </div>
                  <div className="col-md-9 p-0 ">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      defaultValue={titulos}
                    />
                  </div>
                </div>
                <div className="row align-items-center pt-2">
                  <div className="col-12 col-md-3 mb-2 mb-md-0">
                    <label htmlFor="inputEmail3">
                      <p className="mb-0">Titulo</p>
                    </label>
                  </div>
                  <div className="col-12 col-md-9 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="titulo"
                      name="titulo"
                      value={titulo}
                      onChange={handleChangeTitulo}
                    />
                  </div>
                </div>

                <div className="row align-items-center pt-2">
                  <div className="col-md-3 ">
                    <label htmlFor="inputEmail3">
                      <p className="mb-0">Detalles</p>
                    </label>
                  </div>
                  <div className="col-md-9 p-0 ">
                    <textarea
                      className="form-control"
                      id="detalles"
                      name="detalles"
                      value={detalles} // Cambiado a value
                      onChange={handleChangeDetalles} // Cambiado a handleChangeDetalles
                    />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-12  text-end">
                    <button
                      id="myButton"
                      type="submit"
                      className="btn btn-primary px-4"
                    >
                      Grabar
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-6 p-0  ps-4">
              <b>Mis Anuncios Realizados</b>
              <hr className="my-2 " />
              {loading && (
                <div className="text-center mt-5">
                  <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
                  <p>Cargando Anuncio...</p>
                </div>
              )}
              {filteredAnuncios.map((anuncio, index) => (
                <div
                  key={index}
                  className="flex-column  bg-body-secondary mb-2  shadow-sm rounded-3 "
                >
                  <div className="row py-2 ps-2">
                    <div className="col-2 ">
                      <img
                        src={`${window.BACKEND_URL}uploads/${photo}`}
                        alt="Imagen de usuario"
                        className="border border-black w-100 rounded-circle me-2"
                      />
                    </div>

                    <div className="col-7">
                      <div className="row">
                        <div className="col m-0 p-0">
                          <p className="text-start position-relative m-0 p-0">
                            <b>
                              <small>{anuncio.titulo_curso}</small>{" "}
                              <p className="text-start position-relative p-0 m-0">
                                {anuncio.titulo}
                              </p>
                            </b>
                          </p>
                        </div>
                        <div className="row p-0 m-0">
                          <p className="text-start position-relative p-0 m-0">
                            {anuncio.detalles}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      {" "}
                      <button
                        className="btn btn-stemis btn-sm"
                        onClick={() => deleteAnuncio(anuncio._id)}
                      >
                        <i className="fa fa-trash fa-sm" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Anuncios;
