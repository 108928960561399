import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { PDFDocument } from "pdf-lib";
import ExpedientePdf from "../../components/expe_pdf";
import PDF from "../../components/PDF";
import axios from "axios";

function Expediente() {
  // Uso del componente
  const VideoPreview = ({ src }) => {
    if (src.includes("youtube.com") || src.includes("youtu.be")) {
      // Si es un enlace de YouTube
      const url = new URL(src);
      let videoId = url.searchParams.get("v");
      if (!videoId && src.includes("youtu.be")) {
        videoId = src.split("/").pop();
      }
      return (
        <iframe
          width="300"
          height="200"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("drive.google.com")) {
      // Si es un enlace de Google Drive
      const fileIdMatch = src.match(/\/file\/d\/(.+?)\/(?:.*)/);
      if (fileIdMatch && fileIdMatch.length > 1) {
        const fileId = fileIdMatch[1];
        return (
          <iframe
            width="300"
            height="200"
            src={`https://drive.google.com/file/d/${fileId}/preview`}
            title="Google Drive file preview"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        );
      }
    } else if (src.includes("teamgrupo.com")) {
      // Si es un enlace de Teamgrupo
      const groupId = src.split("/").pop();
      return (
        <iframe
          width="300"
          height="200"
          src={`https://www.teamgrupo.com/embed/${groupId}`}
          title="Teamgrupo video player"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("teams.microsoft")) {
      // Si es un enlace de Microsoft Teams
      const videoId = src.split("/").pop();
      return (
        <iframe
          width="300"
          height="200"
          src={`https://teams.microsoft.com/l/meetup-join/${videoId}`}
          title="Microsoft Teams video player"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("sharepoint.com")) {
      return (
        <div
          style={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          <a href={src}>
            <img
              className=""
              src="/img/sharepoint.jpeg"
              alt=""
              style={{
                width: "45rem",
                height: "12.5rem",
                objectFit: "cover",
              }}
            />
          </a>
        </div>
      );
    } else {
      // Si no se reconoce el tipo de enlace
      return (
        <div className="col-6">
          <p>No se pudo cargar el preview del enlace.</p>
          <a href={src}>Enlace Original</a>
        </div>
      );
    }
  };

  const [dateenvioExpediente, SetDataEnvio] = useState([]);
  const [dateenvioExpedienteDemanda, SetDataEnvioDemanda] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [demandaLoaded, setDemandaLoaded] = useState(false);

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  var style1 = { color: "#4b4b4b", fontSize: "13px" };
  var style2 = {
    border: "1px solid rgb(172, 172, 172)",
    fontSize: "13px",
    textTransform: "uppercase",
  };
  const { id } = useParams();
  const [expediente, setExpediente] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/data/get/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let alumnoIdsSet = new Set();
      response.data[0].datagrupo[0].alumnose.forEach((alumno) => {
        alumnoIdsSet.add(alumno.alumno_id);
      });
      alumnoIdsSet.add(response.data[0].datacurso[0].docente_id);

      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      const allUsersAlumno = [];
      const allUsersDocente = [];
      const otrosRoles = [];
      users.data.forEach((user) => {
        if (user.role === "Docente") {
          allUsersDocente.push(user);
        } else if (user.role === "Alumno") {
          allUsersAlumno.push(user);
        } else {
          otrosRoles.push(user);
        }
      });

      let pretencioness = new Set();
      pretencioness.add(response.data[0].datagrupo[0].pretencion);
      let dataspretencionIds = Array.from(pretencioness);

      const pretenciones = await axios.get(
        `${window.BACKEND_URL}alumno/pretencion/getids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            Ids: dataspretencionIds,
          },
        }
      );

      const allPretencion = pretenciones.data;

      const promisess = response.data.map(async (expediente) => {
        const updatedDataGrupo = await Promise.all(
          expediente.datagrupo.map(async (grupo) => {
            // Obtener los nombres de los alumnos

            const alumnosPromises = grupo.alumnose.map(async (alumno) => {
              const usuario = allUsersAlumno.find(
                (data) => data._id === alumno.alumno_id
              );

              if (usuario) {
                alumno.nombre = usuario.name;
              } else {
                console.error(
                  "Usuario no encontrado para el ID:",
                  alumno.alumno_id
                );
              }
            });

            await Promise.all(alumnosPromises);

            let pretensionData = {}; // Inicializa pretensionData fuera del bloque if

            const pretencion = allPretencion.find(
              (data) => data._id === grupo.pretencion
            );

            if (pretencion) {
              pretensionData = pretencion; // Asigna todos los datos de la pretensión si se encuentra
            } else {
              console.error(
                "Pretensión no encontrada para el grupo:",
                grupo.pretencion
              );
              // Otra acción si la pretensión no se encuentra
            }

            return {
              ...grupo,
              datapretension: pretensionData,
            };
          })
        );
        const updatedDataCursoPromises = expediente.datacurso.map(
          async (data) => {
            const docente = allUsersDocente.find(
              (user) => user._id === data.docente_id
            );

            if (docente) {
              data.namedocente = docente.name;
            } else {
              console.error(
                "Usuario no encontrado para el ID:",
                data.docente_id
              );
            }

            return data;
          }
        );

        const updatedDataCurso = await Promise.all(updatedDataCursoPromises);

        return {
          ...expediente,
          datagrupo: updatedDataGrupo,
          datacurso: updatedDataCurso,
        };
      });

      // Esperar a que todas las solicitudes se completen
      const updatedExpedientess = await Promise.all(promisess);
      var recopila_data = {};

      // Filtrar los elementos con el rol "Demandante"

      // Obtener solo los nombres de los demandantes

      recopila_data.nexpediente = updatedExpedientess[0].nexpediente;
      recopila_data.created = updatedExpedientess[0].created;
      recopila_data.alumnose = updatedExpedientess[0].datagrupo[0].alumnose;
      recopila_data.materia =
        updatedExpedientess[0].datagrupo[0].datapretension.tipo;

      SetDataEnvio(recopila_data);

      console.log("updatedExpedientess", updatedExpedientess);

      setExpediente(updatedExpedientess);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDemanda = async (demanda) => {
    if (demanda && !demandaLoaded) {
      try {
        var recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };

        if (demanda.sala_suprema_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala_suprema = await axios.get(
            `${window.BACKEND_URL}alumno/sala_suprema/getid/${demanda.sala_suprema_id}`,
            { headers: headers2 }
          );
          recopila_data.sala_suprema = response_sala_suprema.data.nombre;
        }

        if (demanda.sala_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala = await axios.get(
            `${window.BACKEND_URL}alumno/salas/getid/${demanda.sala_id}`,
            { headers: headers2 }
          );
          recopila_data.sala = response_sala.data.nombre;
        }

        const response_sede_id = await axios.get(
          `${window.BACKEND_URL}alumno/sede_judicial/get/${demanda.sede_id}`,
          { headers: headers2 }
        );
        recopila_data.sede = response_sede_id.data.sede;
        const response_distrito = await axios.get(
          `${window.BACKEND_URL}alumno/distrito_judicial/getid/${demanda.distrito_id}`,
          { headers: headers2 }
        );
        recopila_data.distrito = response_distrito.data.nombre;
        const response_juzgado = await axios.get(
          `${window.BACKEND_URL}alumno/juzgados/getid/${demanda.juzgado_id}`,
          { headers: headers2 }
        );
        recopila_data.juzgado = response_juzgado.data.nombrejuzgado;

        const updatedExpediente = {
          ...expediente[0],
          datademanda: recopila_data,
        };

        SetDataEnvioDemanda(recopila_data);
        setExpediente([updatedExpediente, ...expediente.slice(1)]);

        setDemandaLoaded(true);
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }

    if (expediente[0].avanze) {
      try {
        var recopila_data2 = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };
        const response_etapa_materia = await axios.get(
          `${window.BACKEND_URL}alumno/etapa_materia/getid/${expediente[0].avanze.etapa_materia}`,
          { headers: headers2 }
        );

        recopila_data2.etapa_materia = response_etapa_materia.data[0].nombre;

        const response_via_procedi = await axios.get(
          `${window.BACKEND_URL}alumno/via_prodi/getid/${expediente[0].avanze.via_procedi}`,
          { headers: headers2 }
        );

        recopila_data2.via_procedi = response_via_procedi.data.viaprocedi;
        const updatedExpediente = {
          ...expediente[0],
          dataavanze: recopila_data2,
        };

        setExpediente([updatedExpediente, ...expediente.slice(1)]);

        // Asignar datademanda directamente a expediente[0].datademanda
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }
  };

  useEffect(() => {
    if (expediente.length > 0 && !demandaLoaded) {
      fetchDemanda(expediente[0].demanda);
    }
  }, [expediente[0]?.demanda, demandaLoaded]);

  useEffect(() => {
    fetchData();
  }, [isLoading]);

  // descargar expediente
  const [submitting, setSubmitting] = useState(false);

  const descargaex = () => {
    obtenerExpediente();
  };

  const obtenerExpediente = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    // const resultado = Prueba({
    //   data_expe: 1,
    //   data_demanda: 2,
    //   fecha: 1,
    //   destinatario: 1,
    //   numnotifica: 1,
    // });
    // console.log(resultado);

    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/get/normal/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const expediente = response.data;
      const demanda = expediente.demanda;
      const notificaciones = expediente.notifica;
      console.log("errror", notificaciones);
      // Array para almacenar las promesas de carga de archivos PDF
      const pdfPromises = [];

      // Manejar la demanda primero
      if (demanda && demanda.file) {
        const demandaPromise = fetch(
          `${window.BACKEND_URL}uploads/${demanda.file}`
        )
          .then((res) => res.arrayBuffer())
          .then((pdfBytes) => ({ pdfBytes, demanda }));

        pdfPromises.push(demandaPromise);
      }

      // Luego manejar las notificaciones
      for (const notificacion of notificaciones) {
        if (notificacion && notificacion.file) {
          if (notificacion.estado === 1) {
            const numnotifica = notificacion.numbenotifi;

            const data_expe = dateenvioExpediente;
            const data_demanda = dateenvioExpedienteDemanda;

            const fecha = notificacion.fechaCreacion.slice(0, 10);

            const resultado = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,
              destinatario: 1,
              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado);
            }

            const resultado2 = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,

              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado2);
            }
          }

          const pdfPromise = fetch(
            `${window.BACKEND_URL}uploads/${notificacion.file}`
          )
            .then((res) => res.arrayBuffer())
            .then((pdfBytes) => ({ pdfBytes, notificacion }))
            .catch((error) => {
              console.error("Error al cargar archivo PDF:", error);
              return null;
            });

          if (pdfPromise) {
            pdfPromises.push(pdfPromise);
          }
        } else {
          console.log("No se encontró archivo en notificación:", notificacion);
        }
      }

      // Esperar a que se completen todas las promesas de carga de archivos PDF
      const pdfDataArray = await Promise.all(pdfPromises);

      // Crear un nuevo documento PDF
      const doc = await PDFDocument.create();

      for (const { pdfBytes, demanda, notificacion } of pdfDataArray) {
        // Agregar las páginas del PDF al documento creado
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await doc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => {
          doc.addPage(page);
        });

        // Aquí puedes hacer algo específico con la demanda o la notificación, por ejemplo:
        console.log("Se ha procesado la demanda:", demanda);
        console.log("Se ha procesado la notificación:", notificacion);
      }

      // Convertir el documento a un array de bytes
      const pdfBytes = await doc.save();

      // Abrir el documento PDF en una nueva ventana
      abrirDocumentoPDF(pdfBytes);
      Swal.fire("Se descargo correctamente", "", "success");
    } catch (error) {
      console.log(error);
      console.error("Error al obtener el expediente:", error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };

  const abrirDocumentoPDF = (pdfBytes) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="text-center">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p className="fs-3">Cargando Expediente...</p>
          </div>
        </div>
      ) : (
        <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
          <div className="row container-fluid rounded-end-4">
            <div className="col-md-8 col-6">
              <h2 className="py-2 pt-3"> Expedientes Electrónicos</h2>
            </div>
            <div className="col-md-3 col-3 ">
              {" "}
              <button
                style={{
                  color: "#fff",
                  backgroundColor: "#009688",
                }}
                className="btn  mt-3"
                onClick={descargaex}
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Descargando...
                  </>
                ) : (
                  "Descargar"
                )}
              </button>
            </div>
          </div>

          {expediente.map((data, index) => (
            <section key={index}>
              <div className="container-fluid mt-3">
                <h3>Identificación de Procesos</h3>
                <div className="row">
                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          N° del Expediente:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.nexpediente}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Juez:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Juez")
                            .map((alumno) => alumno?.nombre)
                            .join(", ")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Distrito Judicial:
                          <text className="text-danger">*</text>
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.distrito : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Juzgado:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.juzgado : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Sala suprema:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          value={
                            data.datademanda
                              ? data.datademanda.sala_suprema
                              : null
                          }
                          className="form-control"
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>

                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Docente:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datacurso[0]?.namedocente}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Especialista:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={data.datagrupo[0].alumnose
                            .filter(
                              (alumno) => alumno.rol_id === "Especialista"
                            )
                            .map((alumno) => alumno?.nombre)
                            .join(", ")}
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sede:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={
                            data.datademanda ? data.datademanda.sede : null
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sala:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.datademanda ? data.datademanda.sala : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Sumilla:
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <textarea
                          type="text"
                          value={data.demanda ? data.demanda.sumilla : ""}
                          className="form-control"
                          style={{
                            ...style2,
                            resize: "none",
                            height: "auto",
                            overflowY: "hidden",
                          }}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>
                </div>
                <hr className="p-0" />
              </div>
              <div className="container-fluid mt-3">
                <h3>Detalle del Procesos</h3>
                <div className="row">
                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Fecha de inicio:<text className="text-danger">*</text>
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="date"
                          className="form-control"
                          style={style2}
                          value={data.created.split("T")[0]} // Asumiendo que data.created es en formato ISO8601
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Pretensión:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datagrupo[0].datapretension?.nombre}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Etapa de materia:
                          <text className="text-danger">*</text>
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.dataavanze
                              ? data.dataavanze.etapa_materia
                              : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Costas Costos:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={data.avanze ? data.avanze.costos : null}
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>

                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Materia:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          style={style2}
                          value={data.datagrupo[0]?.datapretension?.tipo}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2 ">
                        <p className="text-left" style={style1}>
                          Via Procedimental:
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            data.dataavanze ? data.dataavanze.via_procedi : null
                          }
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-6 col-md-4 pt-2 ">
                        <p className="text-left" style={style1}>
                          Indeterminado:
                        </p>
                      </div>
                      <div className="col-md-8 px-0 mt-1">
                        <input
                          type="text"
                          className="form-control"
                          value={data.avanze ? data.avanze.indeterminado : null}
                          style={{
                            border: "1px solid rgb(172, 172, 172)",
                            fontSize: "14px",
                            color: "#4b4b4b",
                            maxWidth: "50px",
                          }}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row pb-2">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Cuantía :
                        </p>
                      </div>
                      <div className="col-md-8 px-0 mt-1">
                        <input
                          type="text"
                          value={data.demanda ? data.demanda.monto_soles : null}
                          className="form-control"
                          style={style2}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>

                  <div className="col col-md-2 mt-2 px-4">
                    <p className="text-left" style={style1}>
                      Observaciones:
                    </p>
                  </div>
                  <div className="col-md-10 px-1">
                    <textarea
                      type="text"
                      value={data.demanda ? data.demanda.observaciones : null}
                      className="form-control"
                      style={{
                        ...style2,
                        resize: "none",
                        height: "auto",
                        overflowY: "hidden",
                      }}
                      disabled
                    />
                  </div>
                </div>
                <hr className="p-0" />
              </div>
              <div className="container-fluid mt-3">
                <h3>Partes de procesales</h3>
                <div className="row">
                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-4 mt-2">
                        <p className="text-left" style={style1}>
                          Parte demandante :
                          <text className="text-danger">*</text>
                        </p>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <textarea
                          className="form-control"
                          style={{
                            ...style2,
                            overflowY: "hidden",
                            resize: "none",
                            fontSize: "13px",
                            lineHeight: "1.5",
                          }}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Demandante")
                            .map((alumno) => alumno?.nombre)
                            .join("\n")}
                          readOnly
                          disabled
                          rows={
                            data.datagrupo[0].alumnose.filter(
                              (alumno) => alumno.rol_id === "Demandante"
                            ).length
                          }
                          onScroll={(e) => {
                            e.target.scrollTop =
                              e.target.scrollHeight - e.target.clientHeight;
                          }}
                          onWheel={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>

                  <div className="col-md-6 px-4">
                    <div className="row">
                      <div className="col-6 col-md-3 mt-2">
                        <p className="text-left" style={style1}>
                          Parte demandada :
                        </p>
                      </div>
                      <div className="col-md-9 p-0 mt-2">
                        <textarea
                          className="form-control"
                          style={{
                            ...style2,
                            overflowY: "hidden",
                            resize: "none",
                            fontSize: "13px",
                            lineHeight: "1.5",
                          }}
                          value={data.datagrupo[0].alumnose
                            .filter((alumno) => alumno.rol_id === "Demandado")
                            .map((alumno) => alumno?.nombre)
                            .join("\n")}
                          readOnly
                          disabled
                          rows={
                            data.datagrupo[0].alumnose.filter(
                              (alumno) => alumno.rol_id === "Demandado"
                            ).length
                          }
                          onScroll={(e) => {
                            e.target.scrollTop =
                              e.target.scrollHeight - e.target.clientHeight;
                          }}
                          onWheel={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>

                    {/* Otros campos similares se pueden agregar aquí */}
                  </div>
                </div>
                <hr className="p-0" />
              </div>
              <div className="container-fluid">
                <div className="container-fluid rounded-2 shadow bg-light ">
                  <div className="row">
                    <div className="col-6 mt-2 px-4 mb-2 ">
                      <span>
                        <h2>Cuadernos</h2>
                      </span>
                    </div>
                  </div>
                  <div className="px-3 p-1">
                    <table className="table  table-light table-responsive rounded  ">
                      <thead className="table-secondary rounded-top-5 shadow">
                        <tr>
                          <th className="custom-border-left  ">
                            Número de Cuaderno
                          </th>
                          <th>Fecha</th>
                          <th className="custom-border-right">
                            Tipo de Cuaderno
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.cuaderno
                          ? data.cuaderno.map((datos_cu, index) => (
                              <tr key={index}>
                                <th>
                                  {datos_cu.cuaderno_id && (
                                    <Link to={`/${id}/${datos_cu.cuaderno_id}`}>
                                      {datos_cu.nexpediente}
                                    </Link>
                                  )}
                                </th>
                                <td>
                                  {new Date(datos_cu.fech).toLocaleString()}
                                </td>
                                <td>{datos_cu?.nombre}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="container ">
                <ul className="timeline  pe-3 pb-4 ">
                  {/* Demanda */}
                  {data.demanda ? (
                    <>
                      <li className="pt-2">
                        <div class="timeline-time d-none d-xl-block">
                          <span class="date">
                            {new Date(data.demanda.fechaCreacion).getDate()} de{" "}
                            {
                              meses[
                                new Date(data.demanda.fechaCreacion).getMonth()
                              ]
                            }{" "}
                            de{" "}
                            {new Date(data.demanda.fechaCreacion).getFullYear()}
                          </span>
                          <span class="time">
                            {new Date(data.demanda.fechaCreacion).getHours()}:
                            {new Date(data.demanda.fechaCreacion).getMinutes()}:
                            {new Date(data.demanda.fechaCreacion).getSeconds()}
                          </span>
                        </div>
                        <div class="timeline-icon d-none d-xl-block">
                          <a href="javascript:;">&nbsp;</a>
                        </div>
                        <div class="timeline-body  rounded shadow ">
                          <div className="d-md-none   ">
                            <h5 style={{ fontSize: 20 }}>
                              {new Date(data.demanda.fechaCreacion).getDate()}
                              {"  "}
                              de{"  "}
                              {
                                meses[
                                  new Date(
                                    data.demanda.fechaCreacion
                                  ).getMonth()
                                ]
                              }
                              {"  "}
                              de{"  "}
                              {new Date(
                                data.demanda.fechaCreacion
                              ).getFullYear()}
                              {"  "}
                              {new Date(data.demanda.fechaCreacion).getHours()}:
                              {new Date(
                                data.demanda.fechaCreacion
                              ).getMinutes()}
                            </h5>
                          </div>

                          <div class="timeline-header">
                            <div className="row ">
                              <div className="col-md-10  col-8 text-white align-content-center">
                                <span>
                                  <h3>Demanda</h3>
                                </span>
                              </div>
                              {data.demanda.file && (
                                <div className="col-md-2 col-4 text-white  text-end">
                                  <a
                                    id="myButton"
                                    className="btn p-0 m-0 pe-3 text-white"
                                    target="_blank"
                                    style={{ fontSize: "28px" }}
                                    href={`${window.BACKEND_URL}uploads/${data.demanda.file}`}
                                    download={`${window.BACKEND_URL}uploads/${data.demanda.file}`}
                                    rel="noreferrer"
                                  >
                                    <i class="fa-solid fa-file-arrow-down "></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="timeline-content">
                            <div className="row justify-content-between">
                              <div className="col-md-5 col-12 p-0">
                                <p>
                                  <h4>Acto:</h4> Demanda
                                </p>
                              </div>

                              <div className="col-md-7 col-12 p-0 text-end">
                                <p>
                                  <h4>Creador por: </h4>
                                  {data.demanda.user}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12  px-0 mx-0">
                                <div className="row">
                                  <div className="col-12 col-md-2 mt-1 py-2 ">
                                    <h4>Sumilla:</h4>
                                  </div>
                                  <div className="row px-0 mx-0 ">
                                    <div className="col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={data.demanda.sumilla}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {data.notifica
                    ? data.notifica.map((datanotifi, index) => (
                        <li key={index} className="pt-2">
                          <div class="timeline-time d-none d-xl-block ">
                            <span class="date">
                              {new Date(datanotifi.fechaCreacion).getDate()} de
                              {"  "}
                              {
                                meses[
                                  new Date(datanotifi.fechaCreacion).getMonth()
                                ]
                              }
                              {"  "}
                              de{"  "}
                              {new Date(datanotifi.fechaCreacion).getFullYear()}
                            </span>
                            <span class="time">
                              {new Date(datanotifi.fechaCreacion).getHours()}:
                              {new Date(datanotifi.fechaCreacion).getMinutes()}:
                              {new Date(datanotifi.fechaCreacion).getSeconds()}
                            </span>
                          </div>
                          <div class="timeline-icon d-none  d-xl-block">
                            <a href="javascript:;">&nbsp;</a>
                          </div>
                          <div class="timeline-body  rounded shadow ">
                            <div className="d-md-none   ">
                              <h5 style={{ fontSize: 20 }}>
                                {new Date(datanotifi.fechaCreacion).getDate()}
                                {"  "}
                                de{"  "}
                                {
                                  meses[
                                    new Date(
                                      datanotifi.fechaCreacion
                                    ).getMonth()
                                  ]
                                }
                                {"  "}
                                de{"  "}
                                {new Date(
                                  datanotifi.fechaCreacion
                                ).getFullYear()}
                                {" - "}
                                {new Date(datanotifi.fechaCreacion).getHours()}:
                                {new Date(
                                  datanotifi.fechaCreacion
                                ).getMinutes()}
                              </h5>
                            </div>
                            <div class="timeline-header">
                              <div className="row">
                                <div className="col-md-10 col-8 text-white align-content-center">
                                  <span>
                                    <h3>
                                      {datanotifi?.tipo}: {"000" + (index + 1)}
                                    </h3>
                                  </span>
                                </div>
                                {datanotifi.file && (
                                  <div className="col-md-2  col-4 text-white  text-end">
                                    <a
                                      id="myButton"
                                      className="btn p-0 m-0 pe-3  text-white"
                                      target="_blank"
                                      style={{ fontSize: "28px" }}
                                      href={`${window.BACKEND_URL}uploads/${datanotifi.file}`}
                                      download={`${window.BACKEND_URL}uploads/${datanotifi.file}`}
                                      rel="noreferrer"
                                    >
                                      <i class="fa-solid fa-file-arrow-down "></i>
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="timeline-content">
                              <div className="row  ">
                                <div className="col-md-5 col-12 p-0">
                                  <p>
                                    <h4>Acto:</h4> {datanotifi.acto}
                                  </p>
                                </div>
                                <div className="col-md-7 col-12 p-0 text-end">
                                  <p>
                                    <h4>Creador por: </h4>
                                    {datanotifi.user}
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 px-0 mx-0">
                                  <div className="row">
                                    {datanotifi.estado === 2 ? (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row px-0 mx-0">
                                          <div
                                            className={`${
                                              datanotifi.grab_audiencia
                                                ? `col-12 col-md-6 mt-1`
                                                : `col-12`
                                            }`}
                                          >
                                            <textarea
                                              type="text"
                                              className="form-control custom-input-width"
                                              value={datanotifi.sumilla}
                                              disabled
                                              style={{
                                                ...(datanotifi?.grab_audiencia
                                                  ? {
                                                      textTransform:
                                                        "uppercase",
                                                      height: "200px",
                                                      paddingTop: "0",
                                                      verticalAlign: "top",
                                                      resize: "none",

                                                      overflowY: "hidden",
                                                    }
                                                  : {
                                                      textTransform:
                                                        "uppercase",

                                                      resize: "none",
                                                    }),
                                              }}
                                            />
                                          </div>

                                          {datanotifi.grab_audiencia ? (
                                            <div className="col-12 col-md-6 mt-1">
                                              <VideoPreview
                                                src={`${datanotifi.grab_audiencia}`}
                                              />
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                        <div className="row">
                                          <div className="col-12 py-2">
                                            <h3 className="">
                                              Notificaciónes:
                                              {datanotifi.parte_notifi ? 1 : 2}
                                            </h3>
                                          </div>
                                        </div>
                                        {datanotifi.parte_notifi ? (
                                          datanotifi.parte_notifi === 1 ? (
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {datanotifi?.numbenotifi +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandados
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12 text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {(
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandantes
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12  text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      destinatario={1}
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        (
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        ) : (
                                          <>
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {datanotifi?.numbenotifi +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandados
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12 text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {(
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandantes
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12  text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      destinatario={1}
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        (
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : datanotifi.estado === 1 ? (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row">
                                          <div
                                            className={`${
                                              datanotifi.grab_audiencia
                                                ? `col-12 col-md-6 mt-1`
                                                : `col-12 `
                                            }`}
                                          >
                                            <textarea
                                              type="text"
                                              className="form-control custom-input-width"
                                              value={datanotifi.sumilla}
                                              disabled
                                              style={{
                                                ...(datanotifi?.grab_audiencia
                                                  ? {
                                                      textTransform:
                                                        "uppercase",

                                                      height: "200px",
                                                      paddingTop: "0",
                                                      verticalAlign: "top",
                                                      resize: "none",
                                                      overflowY: "hidden",
                                                    }
                                                  : {
                                                      textTransform:
                                                        "uppercase",

                                                      resize: "none",
                                                    }),
                                              }}
                                            />
                                          </div>
                                          {datanotifi.grab_audiencia ? (
                                            <div className="col-12 col-md-6 mt-1">
                                              <VideoPreview
                                                src={`${datanotifi.grab_audiencia}`}
                                              />
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                        <div className="row">
                                          <div className="col-12 py-2">
                                            <h3 className="">
                                              Notificaciónes:{" "}
                                              {datanotifi.parte_notifi ? 1 : 2}
                                            </h3>
                                          </div>
                                        </div>
                                        {datanotifi.parte_notifi ? (
                                          datanotifi.parte_notifi === 1 ? (
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {datanotifi?.numbenotifi +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandados
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12 text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {(
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandantes
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12  text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      destinatario={1}
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        (
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        ) : (
                                          <>
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {datanotifi?.numbenotifi +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandados
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12 text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="integrat-cursos-scroll">
                                              <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                                <div className="row justify-content-center m-1 ">
                                                  <div className="col-md-6 col-12 text-start mt-2 ">
                                                    <div className="col">
                                                      <h5>
                                                        {" Notificación  "}
                                                        {(
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                          data.nexpediente.slice(
                                                            5,
                                                            10
                                                          ) +
                                                          data.nexpediente.slice(
                                                            17,
                                                            23
                                                          )}
                                                      </h5>
                                                    </div>
                                                    <div className="col">
                                                      <h4>Destinatario: </h4>
                                                      Demandantes
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4 col-12  text-start align-self-end">
                                                    <div className="pb-2">
                                                      <h4>Fecha: </h4>
                                                      {datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-md-2 col-12 text-center align-self-center">
                                                    <PDF
                                                      data_href={`${datanotifi.file} `}
                                                      data_expe={
                                                        dateenvioExpediente
                                                      }
                                                      data_demanda={
                                                        dateenvioExpedienteDemanda
                                                      }
                                                      destinatario={1}
                                                      fecha={datanotifi.fechaCreacion.slice(
                                                        0,
                                                        10
                                                      )}
                                                      numnotifica={
                                                        (
                                                          parseInt(
                                                            datanotifi?.numbenotifi
                                                          ) + 1
                                                        )
                                                          .toString()
                                                          .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2 ">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={datanotifi.sumilla}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    : null}
                  {/* <DataNotifiExpe /> */}
                </ul>
              </div>
            </section>
          ))}
        </div>
      )}
    </>
  );
}

export default Expediente;
