import React, { useState } from "react";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado para rastrear si se está enviando la solicitud

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== confirmEmail) {
      setMessage("¡Las direcciones de correo electrónico no coinciden!");
      return;
    }
    try {
      setIsLoading(true); // Marcar como cargando antes de enviar la solicitud
      const response = await axios.post(
        `${window.BACKEND_URL}users/forgotpassword`,
        { email }
      );
      setMessage(response.data.mensaje);
    } catch (error) {
      setMessage("Error al solicitar recuperación de contraseña");
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Marcar como no cargando después de recibir la respuesta
    }
  };

  return (
    <div className="container-sm d-flex justify-content-center align-items-center vh-100">
      <div className="container-lg bg-light rounded-5 shadow ">
        <img
          className="mx-auto d-block "
          src="/img/logo stemis normal.png"
          alt=""
          style={{
            width: "17rem",
          }}
        />
        <form className="px-5 pb-5" onSubmit={handleSubmit}>
          <div className="text-center mb-4">
            <h2 className="fw-bold">Recuperar Contraseña</h2>
            <h3 className="text-secondary">
              Escribe el correo con el que te inscribiste
            </h3>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Correo electrónico
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmEmail" className="form-label">
              Confirmar correo electrónico
            </label>
            <input
              type="email"
              className="form-control"
              id="confirmEmail"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              required
            />
          </div>
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-stemis"
              disabled={isLoading}
            >
              {isLoading ? "Enviando..." : "Enviar"}
            </button>
            <a type="button" href="/" className="btn btn-cancelar">
              Cancelar
            </a>
          </div>
        </form>
        {message && <div className="mt-3 alert alert-info">{message}</div>}
      </div>
    </div>
  );
}

export default ForgotPassword;
