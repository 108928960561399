import axios from "axios";

import React, { useEffect } from "react";

import { useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function Avance() {
  const { id } = useParams();
  const [inputDisabled, setInputDisabled] = useState(true); // Estado para habilitar o deshabilitar el input

  const [loading, setLoading] = useState(true);

  const [expediente, setExpediente] = useState([]);
  const [numexpe, setnumexpe] = useState([]);

  const [mostrarFormulariOne, setMostrarFormularioOne] = useState(false);
  const [cuadernoSeleccionado, setCuadernoSeleccionado] = useState([]);

  const [cursogrupo, setCursodata] = useState([]);

  const [etapamateria, setEtapaMateria] = useState([]);

  const [viaprocedi, setViaProcedi] = useState([]);

  const [selectviaprocedi, setselectViaProcedi] = useState("");

  const [cuadernos, setCuaderno] = useState([]);
  // const [etapamateria, setdatamateria] = useState([]);
  const [loading2, setLoading2] = useState(true);

  const [submitting, setSubmitting] = useState(false);

  const handleCancel = () => {
    window.history.back();
  };
  const fetchData = async () => {
    try {
      setLoading2(true);
      const response = await axios.get(
        //curso_grupo
        `${window.BACKEND_URL}alumno/grupocurso/getid/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setCursodata(response.data);
      // Etapa MAteria

      const response2 = await axios.get(
        `${window.BACKEND_URL}alumno/etapa_materia/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setEtapaMateria(response2.data);
      // Via procedimental
      const response3 = await axios.get(
        `${window.BACKEND_URL}alumno/via_prodi/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setViaProcedi(response3.data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading2(false);
    }
  };
  const fetchCuadernoCautelar = () => {
    axios
      // Cuaderno listo
      .get(`${window.BACKEND_URL}alumno/tiposcuaderno/get`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCuaderno(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Distrito Judicial:", error);
      });
  };
  useEffect(() => {
    fetchCuadernoCautelar();

    fetchData();
  }, [id]);

  const cargadataexpe = async (expeId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        // expediente
        `${window.BACKEND_URL}alumno/expediente/data/get/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let alumnoIdsSet = new Set();
      response.data[0].datagrupo[0].alumnose.forEach((alumno) => {
        alumnoIdsSet.add(alumno.alumno_id);
      });
      alumnoIdsSet.add(response.data[0].datacurso[0].docente_id);

      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      const allUsersAlumno = [];
      const allUsersDocente = [];
      const otrosRoles = [];
      users.data.forEach((user) => {
        if (user.role === "Docente") {
          allUsersDocente.push(user);
        } else if (user.role === "Alumno") {
          allUsersAlumno.push(user);
        } else {
          otrosRoles.push(user);
        }
      });

      let pretencioness = new Set();
      pretencioness.add(response.data[0].datagrupo[0].pretencion);
      let dataspretencionIds = Array.from(pretencioness);

      const pretenciones = await axios.get(
        `${window.BACKEND_URL}alumno/pretencion/getids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            Ids: dataspretencionIds,
          },
        }
      );

      const allPretencion = pretenciones.data;

      const promisess = response.data.map(async (expediente) => {
        const updatedDataGrupo = await Promise.all(
          expediente.datagrupo.map(async (grupo) => {
            // Obtener los nombres de los alumnos

            const alumnosPromises = grupo.alumnose.map(async (alumno) => {
              const usuario = allUsersAlumno.find(
                (data) => data._id === alumno.alumno_id
              );

              if (usuario) {
                alumno.nombre = usuario.name;
              } else {
                console.error(
                  "Usuario no encontrado para el ID:",
                  alumno.alumno_id
                );
              }
            });

            await Promise.all(alumnosPromises);

            let pretensionData = {}; // Inicializa pretensionData fuera del bloque if

            const pretencion = allPretencion.find(
              (data) => data._id === grupo.pretencion
            );

            if (pretencion) {
              pretensionData = pretencion; // Asigna todos los datos de la pretensión si se encuentra
            } else {
              console.error(
                "Pretensión no encontrada para el grupo:",
                grupo.pretencion
              );
              // Otra acción si la pretensión no se encuentra
            }

            return {
              ...grupo,
              datapretension: pretensionData,
            };
          })
        );
        const updatedDataCursoPromises = expediente.datacurso.map(
          async (data) => {
            const docente = allUsersDocente.find(
              (user) => user._id === data.docente_id
            );

            if (docente) {
              data.namedocente = docente.name;
            } else {
              console.error(
                "Usuario no encontrado para el ID:",
                data.docente_id
              );
            }

            return data;
          }
        );

        const updatedDataCurso = await Promise.all(updatedDataCursoPromises);

        return {
          ...expediente,
          datagrupo: updatedDataGrupo,
          datacurso: updatedDataCurso,
        };
      });

      // Esperar a que todas las solicitudes se completen
      const updatedExpedientess = await Promise.all(promisess);

      setExpediente(updatedExpedientess);

      const partes = response.data[0].nexpediente.split("-");

      setnumexpe(partes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmsFormu = async (e) => {
    e.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    Swal.fire({
      title: "Cargando",
      text: "Subiendo archivos...",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const dataform = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        dataform[element.name] = element.value;
      }
    }

    try {
      const avanze = new FormData();

      avanze.append("materia", e.target.materia.value);
      avanze.append("pretension", e.target.pretension.value);
      avanze.append("via_procedi", e.target.via_procedi.value);
      avanze.append("etapa_materia", e.target.etapa_materia.value);
      avanze.append("indeterminado", e.target.indeterminado.value);
      avanze.append("costos", e.target.costos.value);

      await axios.put(
        `${window.BACKEND_URL}alumno/expediente/avanze/put/${cursogrupo[0].expe_id}`,
        avanze,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (e.target.cuaderno_id && e.target.cuaderno_id.value) {
        try {
          const nuevoNexpediente = numexpe.slice();

          nuevoNexpediente[2] = expediente[0].cuaderno.length + 1;

          const nuevoNumeroExpediente = nuevoNexpediente.join("-");

          const response23 = await axios.post(
            // expediente cuaderno
            `${window.BACKEND_URL}alumno/expe_cuaderno/data/post`,
            {
              cuaderno: true,
              cuaderno_id: cuadernoSeleccionado.id,
              expe_raiz: expediente[0]._id,
              curso_id: expediente[0].curso_id,
              grupo_id: expediente[0].grupo_id,
              nexpediente: nuevoNumeroExpediente,
              alumnos: expediente[0].datagrupo[0].alumnose,
              pretencion: expediente[0].datagrupo[0].pretencion,
              created: expediente[0].created,
              docente_id: expediente[0].datacurso[0].docente_id,
              demanda: expediente[0]?.demanda || null,
              avanze: expediente[0]?.avanze || null,
            },
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
              },
            }
          );

          const dataformfinal = {};
          dataformfinal.cuaderno_id = response23.data.userId;

          dataformfinal.nexpediente = nuevoNumeroExpediente;

          dataformfinal.fech = expediente[0].created;
          dataformfinal.nombre = cuadernoSeleccionado.nombre;
          // Mostrar un mensaje de éxito al usuario
          await axios.put(
            // expediente cuaderno
            `${window.BACKEND_URL}alumno/expediente/cuaderno/put/${expediente[0]._id}`,
            { cuaderno: dataformfinal },
            {
              headers: {
                Authorization: `${sessionStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: "Guardado avanze y cuaderno",
            text: "¡Se guardó correctamente!",
          });
          //
          window.history.back();
        } catch (error) {
          console.error(error);
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Guardado avanze ",
          text: "¡Se guardó correctamente!",
        });
        window.history.back();
      }

      // Mostrar un mensaje de éxito al usuario
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };

  const toggleFormularioOne = () => {
    setMostrarFormularioOne(!mostrarFormulariOne);
  };
  return (
    <>
      <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
        <div className="container-fluid mt-3 py-3">
          <strong class=" px-3 pt-4">Registro de Avance</strong>
        </div>

        {loading2 ? (
          <p>Cargando...</p>
        ) : (
          <section>
            <div className="container-fluid mt-3">
              <form
                className="row g-3 needs-validation"
                id="myForm"
                onSubmit={confirmsFormu}
              >
                <div className="row">
                  {cursogrupo.map((data) => (
                    <div
                      key={data._id}
                      value={data._id}
                      className="col-md-8 px-4"
                    >
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Materia<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            name="materia"
                            className="form-control"
                            value={data.materia}
                            disabled
                          />
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Pretensión
                              <text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            name="pretension"
                            className="form-control"
                            value={data.datapretension[0].nombre}
                            disabled
                          />
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Via procedimental
                              <text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            required
                            name="via_procedi"
                            onChange={(e) =>
                              setselectViaProcedi(e.target.value)
                            }
                          >
                            <option>...</option>
                            {viaprocedi
                              .filter(
                                (via) =>
                                  via.materia === data.datapretension[0].tipo
                              )
                              .map((viaprocedi) => (
                                <option
                                  key={viaprocedi._id}
                                  value={viaprocedi._id}
                                >
                                  {viaprocedi.viaprocedi}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Etapa de materia
                              <text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="etapa_materia"
                            required
                          >
                            <option>...</option>
                            {etapamateria.map((data) => {
                              if (data.viaprocedi === selectviaprocedi) {
                                return (
                                  <option key={data._id} value={data._id}>
                                    {data.nombre}
                                  </option>
                                );
                              }
                              return null;
                            })}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Indeterminado
                              <text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            name="indeterminado"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === "NO") {
                                setInputDisabled(false);
                              } else if (value === "SI") {
                                setInputDisabled(true);
                              }
                            }}
                          >
                            <option value="">...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Cuantia<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="number"
                            name="costos"
                            className="form-control"
                            disabled={inputDisabled}
                          />
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label
                            htmlFor="inputEmail3"
                            style={{ fontSize: "14px", paddingRight: "20px" }}
                          >
                            Agregar Cuaderno
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <a
                            id="myButton"
                            className="btn"
                            onClick={() => {
                              toggleFormularioOne();
                              if (cursogrupo[0].expe_id) {
                                // Verificar si cursogrupo.expe_id tiene un valor
                                cargadataexpe(cursogrupo[0].expe_id);
                              } else {
                                console.error(
                                  "El ID del expediente no está definido."
                                );
                              }
                            }}
                          >
                            {mostrarFormulariOne}
                          </a>
                        </div>
                      </div>

                      {mostrarFormulariOne && (
                        <div className="row">
                          <div className="col-md-4 mt-3">
                            <label htmlFor="inputEmail3">
                              <p>
                                Tipo de cuaderno
                                <text className="text-danger">*</text>
                              </p>
                            </label>
                          </div>
                          {loading ? (
                            <p>Cargando...</p>
                          ) : (
                            <div className="col-md-8 p-0 mt-2">
                              <select
                                className="form-control"
                                name="cuaderno_id"
                                value={cuadernoSeleccionado._id}
                                onChange={(e) => {
                                  const dataselect = cuadernos.find(
                                    (distrito) =>
                                      distrito._id === e.target.value
                                  );
                                  setCuadernoSeleccionado(dataselect);
                                }}
                              >
                                <option disabled value="">
                                  Seleccionar
                                </option>
                                {cuadernos.map((data) => (
                                  <option key={data._id} value={data._id}>
                                    {data.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="row">
                        <div className="col-6 p-0 mb-3 mt-4">
                          <a
                            className="btn px-5"
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#868686",
                              borderColor: "#868686",
                              textAlign: "right",
                              fontWeight: "500",
                            }}
                            onClick={handleCancel}
                          >
                            Cancelar
                          </a>
                        </div>
                        <div className="col-6 mb-4 mt-4 d-flex flex-row-reverse ">
                          <button
                            id="myButton"
                            className="btn btn-primary btn px-5"
                            type="submit"
                            disabled={submitting} // Deshabilitar el botón cuando el formulario se está enviando
                          >
                            {submitting ? "Enviando..." : "Grabar"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default Avance;
