import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link, useParams } from "react-router-dom";

const TituloCurso = () => {
  const { id } = useParams();
  const [cursos, setCursos] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCursos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}cursosget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setCursos(response.data);
        const titulo_id = response.data.titulo_id;
        const titulosRespose = await axios.get(
          `${window.BACKEND_URL}tituloget/${titulo_id}`
        );
        setTitulos(titulosRespose.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching cursos:", error);
        setLoading(false);
      }
    };

    getCursos();
  }, [id]);


  return (
    <>
      <div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <>
            <div className="mt-3">
              <h2 className="bg-stemis  ">
                <b>{titulos?.nombre}  - Seccion: {cursos.seccion} </b>
              </h2>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TituloCurso;
