import React from "react";
import { Link, useParams } from "react-router-dom";

const Tavnav = () => {
  const { id } = useParams();

  return (
    <>
      <section style={{ fontSize: "14px" }}>
        <div className="navbar  nav-category  bg-body">
          <Link className="activates" to={`/curso-docente/${id}`}>
            Lista de Alumnos
          </Link>
          <Link className="activates" to={`/lista-grupos/${id}`}>
            Grupos
          </Link>
          <Link className="activates" to={`/libro-calificacion/${id}`}>
            Libro de Calificaciones
          </Link>
          <Link className="activates" to={`/slaaudiencia/${id}`}>
            Sala de Audiencias
          </Link>
          <Link className="activates" to={`/rubricas/${id}`}>
            Rúbricas
          </Link>
          <Link className="activates" to={`/agregar-anuncio/${id}`}>
            Anuncios
          </Link>
         {/*  <Link className="activates" to={`/Documentos/${id}`}>
            Documentos
          </Link> */}
          <Link className="activates" to={`/detalle-curso/${id}`}>
            Detalles de Curso
          </Link>
        </div>
      </section>
    </>
  );
};

export default Tavnav;
