import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const LibroCalifica = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [tipoResolu, setTipoResolu] = useState({}); // Estado optimizado

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/vercalificaciones/${id}`
        );

        // Procesar datos del backend
        const groupedData = response.data.map((item) => {
          const groupedAlumnose = item.alumnose.reduce(
            (acc, alumno) => {
              const { name, rol, ...rest } = alumno;

              const groupKey =
                rol === "Juez" || rol === "Especialista"
                  ? "juez_y_especialista"
                  : rol === "Demandante"
                  ? "demandantes"
                  : "demandados";

              if (!acc[groupKey].nombre) acc[groupKey].nombre = [];
              acc[groupKey].nombre.push(name);

              if (!acc[groupKey].notas) acc[groupKey].notas = {};

              for (let key in rest) {
                acc[groupKey].notas[key] = rest[key] || 0;
              }

              return acc;
            },
            { juez_y_especialista: {}, demandantes: {}, demandados: {} }
          );

          return { ...item, alumnose: groupedAlumnose };
        });

        setData(groupedData);
        setCargando(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setCargando(false);
      }
    };

    fetchData();
  }, [id]);

  const [openItems, setOpenItems] = useState({});

  const handleToggle = (index) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleTipoResoluChange = (index, e) => {
    setTipoResolu((prevState) => ({
      ...prevState,
      [index]: e.target.value,
    }));
  };

  const calcularPromedio = (notas, tipoResolu) => {
    const valores = Object.values(notas).map(Number); // Asegurar que sean números
    // Comprobar si las notas están vacías, si es así, devolver 0
    if (valores.length === 0) {
      return 0;
    }
    if (tipoResolu === "1") {
      return valores.reduce((a, b) => a + b, 0); // Suma
    } else {
      return valores.reduce((a, b) => a + b, 0) / valores.length; // Promedio
    }
  };

  const TablaCalificaciones = ({ rol, data, calcularPromedio, tipoResolu }) => (
    <div className="table-responsive">
      <table className="table table-sm">
        <thead>
          <tr className="table-active">
            <th scope="col">{rol}</th>
            {Object.entries(data.notas).map(([key], idx) => (
              <th key={idx}>
                <h4>{key}</h4>
              </th>
            ))}
            <th style={{ width: "6.5rem" }}>Promedio</th>
          </tr>
        </thead>
        <tbody>
          {data.nombre.map((alumno, idx) => (
            <tr key={idx}>
              <td>{alumno}</td>
              {Object.entries(data.notas).map(([key, value], idx) => (
                <td key={idx}>{value ? value : 0}</td>
              ))}
              <td>{calcularPromedio(data.notas, tipoResolu)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <section>
      {cargando ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Calificaciones...</p>
        </div>
      ) : (
        data.map((expediente, index) => (
          <div key={index} className="px-3">
            <div
              className="container-fluid mt-3 border shadow rounded-4 mb-3"
              onClick={() => handleToggle(index)}
              aria-expanded={openItems[index] || false}
            >
              <div className="row mb-3 text-left pt-2">
                <div className="col-4">
                  <Link
                    to={`/expediente-docente/${expediente.id}/${id}`}
                    className="custom-link"
                  >
                    <h5 className="m-0 p-1">Expediente: {expediente.n_expe}</h5>
                  </Link>
                </div>
                <div className="col-3">
                  <p>Materia: {expediente.pretencion?.tipo}</p>
                </div>
                <div className="col-3">
                  <p>Pretensión: {expediente.pretencion?.nombre}</p>
                </div>
                <div className="col-2">
                  <select
                    className="form-select"
                    name="tipo_resolu"
                    onChange={(e) => handleTipoResoluChange(index, e)}
                    onClick={(e) => e.stopPropagation()}
                    value={tipoResolu[index] || "1"}
                  >
                    <option value="1">Suma</option>
                    <option value="0">Promedio</option>
                  </select>
                </div>
              </div>

              <div
                style={{
                  maxHeight: openItems[index] ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease",
                }}
              >
                <TablaCalificaciones
                  rol="Juez y Especialista"
                  data={expediente.alumnose.juez_y_especialista}
                  calcularPromedio={calcularPromedio}
                  tipoResolu={tipoResolu[index]}
                />
                <TablaCalificaciones
                  rol="Parte Demandante"
                  data={expediente.alumnose.demandantes}
                  calcularPromedio={calcularPromedio}
                  tipoResolu={tipoResolu[index]}
                />
                <TablaCalificaciones
                  rol="Parte Demandado"
                  data={expediente.alumnose.demandados}
                  calcularPromedio={calcularPromedio}
                  tipoResolu={tipoResolu[index]}
                />
              </div>
            </div>
          </div>
        ))
      )}
    </section>
  );
};

export default LibroCalifica;
