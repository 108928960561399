/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Sliderdoc from "./sildvardoc";
import SliderAlu from "./slideralum";
import { useNavigate, Link } from "react-router-dom";

const NavBar = () => {
  const [username] = useState(sessionStorage.getItem("userName"));
  const [loggedOut, setLoggedOut] = useState(false);
  const [id] = useState(sessionStorage.getItem("_id"));
  const navigate = useNavigate();
  const [role] = useState(sessionStorage.getItem("role"));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    if (!loggedOut) {
      sessionStorage.clear();
      navigate("/login");
      window.location.reload();
      setLoggedOut(true);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="container mb-n4">
      <a href="/">
        <img
          src="/img/logo-blanco-stemis .svg"
          alt="Logo"
          width="90"
          height="auto"
          className="ms-5"
        />
      </a>

      <div className="d-flex align-items-center">
        <nav className="as navbar-expand navbar-light navbar-bg">
          <a className="sidebar-toggle">
            <i className="hamburger align-self-center"></i>
          </a>

          <div className="navbar-collapse   ">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a className="nav-link " onClick={toggleDropdown}>
                  <span className="btn navbar-text text-light">
                    <b>{username}</b>
                  </span>
                </a>

                <ul
                  className={`dropdown-menu   ${dropdownOpen ? "show" : ""}`}
                  style={{ right: "1rem" }}
                >
                  {role === "Alumno" && (
                    <div className="d-block d-sm-none">
                      <SliderAlu />
                      <hr />
                    </div>
                  )}
                  {role === "Docente" && (
                    <div className="d-block d-sm-none">
                      <Sliderdoc />
                      <hr />
                    </div>
                  )}

                  <li>
                    <Link
                      to={`/perfil/${id}`}
                      className="dropdown-item"
                      href="#"
                    >
                      <i className="fa-solid fa-user icon px-1"></i>
                      Perfil
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      <i className="fa-solid fa-right-from-bracket px-1"></i>
                      Cerrar sesión
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
