import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Escrito = () => {
  const [loading, setLoading] = useState(true);
  const [expediente, setExpediente] = useState("");
  const [username] = useState(sessionStorage.getItem("userName"));
  const [role] = useState(sessionStorage.getItem("role"));
  const { id } = useParams();

  const getExpediente = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expediente/get/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const expedienteValue = response.data[0].nexpediente;
      setExpediente(expedienteValue);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expediente:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("estado", 0);

      formData.append("n_expe", expediente);
      formData.append("user", username);
      formData.append("rol", role);
      formData.append("tipo", "Escritos");
      formData.append("acto", "Escritos");
      formData.append("sumilla", e.target.sumilla.value);
      formData.append("observaciones", e.target.observaciones.value);
      formData.append("file", e.target.file.files[0]);
      // Obtener el archivo del input de archivo

      await axios.put(
        `${window.BACKEND_URL}docente/expediente/put/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó el Escrito!",
      });

      window.history.back();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  useEffect(() => {
    getExpediente();
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid">
          <h2>Agregar Escrito</h2>
          <form
            onSubmit={handleSubmit}
            className="row g-3 needs-validation mt-4"
            noValidate
          >
            <div className="row">
              <div className="col-md-8 px-4">
                <div className="row">
                  <div className="col-6 col-md-4 mt-2">
                    <p className="text-left mt-2">
                      N° de Expediente<span className="text-danger">*</span>
                    </p>
                  </div>
                  <div className="col-md-8 p-0 mt-2">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={expediente}
                      required
                      placeholder="Ingrese un Expediente..."
                    />
                    <div className="invalid-feedback">Campo Obligatorio.</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4 mt-2">
                    <p className="text-left mt-2">
                      Resumen<span className="text-danger">*</span>
                    </p>
                  </div>
                  <div className="col-md-8 p-0 mt-2">
                    <input
                      type="text"
                      name="sumilla"
                      id="sumilla"
                      className="form-control"
                      required
                    />
                    <div className="invalid-feedback">Campo Obligatorio.</div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6 col-md-4">
                    <p>Observaciones:</p>
                  </div>
                  <div className="col-md-8 p-0 mt-2">
                    <textarea
                      className="form-control"
                      name="observaciones"
                      id="observaciones"
                      style={{
                        border: "1px solid rgb(231, 231, 231)",
                        height: "100px",
                        fontSize: "14px",
                        color: "#4b4b4b",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 px-2">
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        accept="application/pdf"
                        name="file"
                        id="file"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3 mt-4">
                  <Link
                    onClick={handleCancel}
                    type="button"
                    className="btn btn-cancelar px-2"
                  >
                    <i className="fa-solid fa-times me-2"></i>
                    Cancelar
                  </Link>
                </div>
                <div className="col-6 mb-3 mt-4 d-flex flex-row-reverse p-0">
                  <button type="submit" className="btn btn-stemis px-5">
                    Grabar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Escrito;
