import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "datatables.net";
import ReactModal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2";
import { PDFDocument } from "pdf-lib";
import ExpedientePdf from "../../components/expe_pdf";
import PDF from "../../components/PDF";
ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "35px",
    maxWidth: "500px",
    width: "90%",
  },
};

const customStyles2 = {
  content: {
    justifyContent: "center",

    position: "static", // Elimina position: absolute
    margin: "auto",
    padding: "35px",
    width: "100%",
    height: "90%",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

var notifuera = 0;
var style1 = { color: "#4b4b4b", fontSize: "13px" };
var style2 = {
  border: "1px solid rgb(172, 172, 172)",
  fontSize: "13px",
  color: "#4b4b4b",
  textTransform: "uppercase",
};

var style3 = {
  textTransform: "uppercase",
};
function ExpedienteDoc() {
  const VideoPreview = ({ src }) => {
    if (src.includes("youtube.com") || src.includes("youtu.be")) {
      // Si es un enlace de YouTube
      const url = new URL(src);
      let videoId = url.searchParams.get("v");
      if (!videoId && src.includes("youtu.be")) {
        videoId = src.split("/").pop();
      }
      return (
        <iframe
          width="300"
          height="200"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("drive.google.com")) {
      // Si es un enlace de Google Drive
      const fileIdMatch = src.match(/\/file\/d\/(.+?)\/(?:.*)/);
      if (fileIdMatch && fileIdMatch.length > 1) {
        const fileId = fileIdMatch[1];
        return (
          <iframe
            width="300"
            height="200"
            src={`https://drive.google.com/file/d/${fileId}/preview`}
            title="Google Drive file preview"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        );
      }
    } else if (src.includes("teamgrupo.com")) {
      // Si es un enlace de Teamgrupo
      const groupId = src.split("/").pop();
      return (
        <iframe
          width="300"
          height="200"
          src={`https://www.teamgrupo.com/embed/${groupId}`}
          title="Teamgrupo video player"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("teams.microsoft")) {
      // Si es un enlace de Microsoft Teams
      const videoId = src.split("/").pop();
      return (
        <iframe
          width="300"
          height="200"
          src={`https://teams.microsoft.com/l/meetup-join/${videoId}`}
          title="Microsoft Teams video player"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("sharepoint.com")) {
      return (
        <div
          style={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          <a href={src}>
            <img
              className=""
              src="/img/sharepoint.jpeg"
              alt=""
              style={{
                width: "45rem",
                height: "12.5rem",
                objectFit: "cover",
              }}
            />
          </a>
        </div>
      );
    } else {
      // Si no se reconoce el tipo de enlace
      return (
        <div className="col-6">
          <p>No se pudo cargar el preview del enlace.</p>
          <a href={src}>Enlace Original</a>
        </div>
      );
    }
  };

  const { id } = useParams();

  const [cuadernos, setCuaderno] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [rubricas, setRubricas] = useState([]);

  const [idNoti, setArraynoti] = useState(null);
  const [notificacion, setNotificacion] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [revisarModal, setRevisarModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nota, setNota] = useState(0);

  const [notiObser, setObservacion] = useState("");
  const [rubricaSeleccionada, setRubricaSeleccionada] = useState(null);
  const [tipoCalificacion, setTipoCalificacion] = useState("");
  const [selectedCuaderno, setSelectedCuaderno] = useState("");
  const [numexpe, setnumexpe] = useState([]);
  const [rubricaOpen, setRubricaOpen] = useState(false);
  // DATA QUE SE ENVIA AL PDF

  const [dateenvioExpediente, SetDataEnvio] = useState([]);
  const [dateenvioExpedienteDemanda, SetDataEnvioDemanda] = useState([]);

  const [seleccionadas, setSeleccionadas] = useState(
    Array(rubricas.length).fill(null)
  );
  const handleSeleccionarDescripcion = (descripcionIndex, criterioIndex) => {
    const nuevasSeleccionadas = [...seleccionadas];
    nuevasSeleccionadas[criterioIndex] = descripcionIndex;
    setSeleccionadas(nuevasSeleccionadas);

    // Calcular la nota total
    setNota(calcularNotaTotal);
  };

  const calcularNotaTotal = () => {
    let total = 0;
    seleccionadas.forEach((descripcionIndex, criterioIndex) => {
      const rubrica = rubricas.find(
        (r) => r.rubrica_id === rubricaSeleccionada
      );
      if (rubrica && rubrica.criterios[criterioIndex]) {
        const criterio = rubrica.criterios[criterioIndex];
        if (criterio.descripciones[descripcionIndex]) {
          total +=
            parseFloat(criterio.descripciones[descripcionIndex].nota) || 0;
        }
      }
    });
    // Redondear a dos decimales
    return Math.round(total * 100) / 100;
  };

  const openRubricaModal = () => {
    setRubricaOpen(true);
  };
  const handleSelectChange = (e) => {
    setSelectedCuaderno(e.target.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const revisarmol = (noti_id) => {
    setRevisarModal(true);
    setArraynoti(noti_id);
    notifuera = noti_id;
    setTipoCalificacion("");
  };

  const closeModa = () => {
    setRevisarModal(false);
    setArraynoti(null);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchCuadernoCautelar = () => {
      axios
        .get(`${window.BACKEND_URL}docente/tiposcuaderno`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setCuaderno(response.data);
        })
        .catch((error) => {
          console.error("Error fetching Distrito Judicial:", error);
        });
    };

    fetchCuadernoCautelar();
  }, []);
  const fetchData = async () => {
    try {
      setIsLoading(true);

      // Primer request
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expediente/get/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data);
      // Segundo request
      const response2 = await axios.get(
        `${window.BACKEND_URL}docente/cursogrupo/data/get/${response.data[0]?.grupo_id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Mapear y obtener nombres de alumnos
      const updatedGrupos = response2.data.map(async (grupo) => {
        const alumnosWithNames = await Promise.all(
          grupo.alumnose.map(async (alumno) => {
            try {
              const userResponse = await axios.get(
                `${window.BACKEND_URL}users/${alumno.alumno_id}`,
                {
                  headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                  },
                }
              );
              const usuario = userResponse.data;
              return {
                ...alumno,
                nombre: usuario.name,
              };
            } catch (error) {
              console.error("Error fetching user data:", error);
              return null;
            }
          })
        );
        return { ...grupo, alumnose: alumnosWithNames };
      });

      // Esperar a que todos los grupos se actualicen con los nombres de los usuarios
      const datagrupo = await Promise.all(updatedGrupos);
      Object.assign(response.data[0], { datagrupo });

      // Obtener nombres de docentes
      await Promise.all(
        response.data.map(async (data) => {
          const docenteResponse = await axios.get(
            `${window.BACKEND_URL}users/${data.datacurso[0].docente_id}`,
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
              },
            }
          );
          setdocente(docenteResponse.data.name);
        })
      );

      // Procesar datos
      const partes = response.data[0].nexpediente.split("-");
      setnumexpe(partes);

      var recopila_data = {};
      recopila_data.nexpediente = response.data[0].nexpediente;
      recopila_data.created = response.data[0].created;
      recopila_data.alumnose = response.data[0].datagrupo[0].alumnose;
      recopila_data.materia = response.data[0].datagrupo[0].datapretension.tipo;

      SetDataEnvio(recopila_data);
      setExpediente(response.data);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      // Esto se ejecuta después de que todas las promesas anteriores se hayan completado o hayan fallado
      setIsLoading(false);
    }
  };

  //eliminar cudernos
  const handleDelete = async (cuaderno_id) => {
    try {
      // Paso 1: Eliminar el cuaderno_id del array
      const response1 = await axios.delete(
        `${window.BACKEND_URL}docente/cuaderno/delet/${id}`,
        {
          data: { cuaderno_id: cuaderno_id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Paso 2: Eliminar el documento con el cuaderno_id
      const response2 = await axios.delete(
        `${window.BACKEND_URL}docente/expe_cuaderno/delet/${cuaderno_id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response1.status === 200 && response2.status === 200) {
        Swal.fire("Cuaderno eliminado", "", "success");
        fetchData();
      } else {
        Swal.fire("Error al eliminar expediente", "", "error");
      }
    } catch (error) {
      Swal.fire("Error al eliminar expediente", "", "error");
      console.error("Error deleting expediente:", error);
    }
  };

  //elimnar notiificaciones
  const notidelete = async (noti_id) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}docente/expediente/noti/delet/${id}`,
        {
          data: { noti_id: noti_id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire("Notificacion eliminada", "", "success");

      fetchData();
    } catch (error) {
      Swal.fire("Error al eliminar la notificación", "", "error");
      console.error("Error deleting notification:", error);
    }
  };

  // EDITAR INICIO

  const [demandaLoaded, setDemandaLoaded] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => {
    setDisabled(!disabled);
  };

  const [sala, setsala] = useState([]);
  const [salasupre, setsalasupre] = useState([]);

  // Data importante
  const [distrito_judicial, setJudicial] = useState([]);
  const [sede_judi, setSedeJudicial] = useState([]);
  const [juzgado, settipojuzgado] = useState([]);

  const [etapamateria, setEtapaMateria] = useState([]);

  const [viaprocedi, setViaProcedi] = useState([]);

  // SELECT AVANZE

  const [selectviaprocedi, setselectViaProcedi] = useState("");
  const [selectinderter, setSelectInder] = useState("");
  const [inputDisabled, setInputDisabled] = useState(true);
  // SELECT DEMANDA
  const [selectedJudicial, setSelectedJudicial] = useState("");
  const [selectedSede, setSede] = useState("");

  const [selectedJuzga, setJuzgado] = useState("");
  const [seleJuzgado, setSeleJuzgado] = useState("");

  const [docente, setdocente] = useState([]);

  const getsalasupre = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}docente/sala_suprema`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setsalasupre(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getsala = async () => {
    try {
      const response = await fetch(`${window.BACKEND_URL}docente/salas`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        setsala(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getsede_judicial = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/sede_judicial`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getdistrito_judicial = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}docente/distrito_judicial`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      if (Array.isArray(data)) {
        setJudicial(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getjuzgado = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/juzgados`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      settipojuzgado(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getetapamateria = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/etapa_materias`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setEtapaMateria(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getviaprocedi = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/via_prodi`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setViaProcedi(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };

  useEffect(() => {
    //demanda
    getdistrito_judicial();
    getsede_judicial();

    getjuzgado();
    getsala();
    getsalasupre();
    //avanze
    getetapamateria();
    getviaprocedi();
  }, []);
  const fetchDemanda = async (demanda) => {
    setIsLoading(true);
    if (demanda && !demandaLoaded) {
      try {
        var recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };
        if (demanda.sala_suprema_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala_suprema = await axios.get(
            `${window.BACKEND_URL}docente/sala_suprema/get/${demanda.sala_suprema_id}`,
            { headers: headers2 }
          );
          recopila_data.sala_suprema = response_sala_suprema.data.nombre;
        }

        if (demanda.sala_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala = await axios.get(
            `${window.BACKEND_URL}docente/salas/get/${demanda.sala_id}`,
            { headers: headers2 }
          );
          recopila_data.sala = response_sala.data.nombre;
        }
        const response_sede_id = await axios.get(
          `${window.BACKEND_URL}docente/sede_judicial/get/${demanda.sede_id}`,
          { headers: headers2 }
        );
        recopila_data.sede = response_sede_id.data.sede;
        const response_distrito = await axios.get(
          `${window.BACKEND_URL}docente/distrito_judicial/get/${demanda.distrito_id}`,
          { headers: headers2 }
        );
        recopila_data.distrito = response_distrito.data.nombre;

        const response_juzgado = await axios.get(
          `${window.BACKEND_URL}docente/juzgado/get/${demanda.juzgado_id}`,
          { headers: headers2 }
        );

        setSeleJuzgado(response_juzgado.data);
        recopila_data.juzgado = response_juzgado.data.nombrejuzgado;

        const updatedExpediente = {
          ...expediente[0],
          datademanda: recopila_data,
        };

        setExpediente([updatedExpediente, ...expediente.slice(1)]);

        SetDataEnvioDemanda(recopila_data);

        // Actualizar el estado selectedJudicial
        setSelectedJudicial(expediente[0]?.demanda.distrito_id);
        setSede(expediente[0]?.demanda.sede_id);
        setJuzgado(expediente[0]?.demanda.juzgado_id);

        setDemandaLoaded(true);
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }
    if (expediente[0]?.avanze) {
      try {
        var recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };
        const response_etapa_materia = await axios.get(
          `${window.BACKEND_URL}docente/etapa_materia/get/${expediente[0]?.avanze.etapa_materia}`,
          { headers: headers2 }
        );
        recopila_data.etapa_materia = response_etapa_materia.data.nombreet;
        const response_via_procedi = await axios.get(
          `${window.BACKEND_URL}docente/via_prodi/get/${expediente[0]?.avanze.via_procedi}`,
          { headers: headers2 }
        );
        recopila_data.via_procedi = response_via_procedi.data.viaprocedi;
        const updatedExpediente = {
          ...expediente[0],
          dataavanze: recopila_data,
        };

        setExpediente([updatedExpediente, ...expediente.slice(1)]);

        // Asignar datademanda directamente a expediente[0].datademanda

        setselectViaProcedi(expediente[0]?.avanze.via_procedi);
        setSelectInder(expediente[0]?.avanze.indeterminado);
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (expediente.length > 0 && !demandaLoaded) {
      fetchDemanda(expediente[0].demanda);
    }
  }, [expediente[0]?.demanda, demandaLoaded]);
  const confirmsFormu = async (e) => {
    e.preventDefault();

    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const formulario = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        formulario[element.name] = element.value;
      }
    }

    const selectsede = sede_judi.find((dat) => dat._id === selectedSede);

    const selectjuzgado = juzgado.find((dat) => dat._id === selectedJuzga);

    numexpe[3] = selectsede.numero;
    numexpe[6] = selectjuzgado.njuzgado;

    const nuevoNumeroExpediente = numexpe.join("-");

    const dataexpe = {
      nexpediente: nuevoNumeroExpediente,
    };

    let avanzee = {};

    let demandas = {};

    demandas.sala_id = formulario.sala_id ? formulario.sala_id : "";
    demandas.sala_suprema_id = formulario.sala_suprema_id
      ? formulario.sala_suprema_id
      : "";
    demandas.distrito_id = formulario?.distrito_id;
    demandas.juzgado_id = formulario?.juzgado_id;
    demandas.sede_id = formulario?.sede_id;
    demandas.sumilla = formulario?.sumilla ? formulario.sumilla : "";
    demandas.observaciones = formulario?.observaciones
      ? formulario.observaciones
      : "";
    demandas.monto_soles = formulario?.monto_soles;

    // Avanze

    avanzee.via_procedi = formulario.via_procedi;
    avanzee.etapa_materia = formulario.etapa_materia;
    avanzee.costos = formulario.costos;
    avanzee.indeterminado = formulario.indeterminado;

    try {
      await axios.put(
        `${window.BACKEND_URL}docente/expediente/demanda/put/${id}`,
        demandas,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      await axios.put(
        `${window.BACKEND_URL}docente/expediente/num/expe/${id}`,
        dataexpe,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (expediente[0].avanze) {
        await axios.put(
          `${window.BACKEND_URL}docente/expediente/avanze/put/${id}`,
          avanzee,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      // Mostrar un mensaje de éxito al usuario
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó correctamente!",
      }).then(() => {
        // Una vez que el usuario hace clic en OK en el cuadro de diálogo de SweetAlert,
        // recargamos la página
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
    }
  };
  // EDITAR FIN
  useEffect(() => {
    fetchData();
  }, [id]);
  //mostrar los meses
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  //crear el cuaderno
  const handleSubmit = async (e) => {
    const numexpe = expediente[0].nexpediente.split("-");
    const nuevoNumeroCuadernos = expediente[0].cuaderno.length + 1; // Incrementar en uno el número de cuaderno
    numexpe[2] = nuevoNumeroCuadernos.toString(); // Actualizar el número de cuaderno en formato de string
    const nuevoNumeroExpediente = numexpe.join("-");
    e.preventDefault();
    const Data = {
      cuaderno: true,
      cuaderno_id: selectedCuaderno,
      expe_raiz: id,
      curso_id: expediente[0].curso_id,
      grupo_id: expediente[0].grupo_id,
      nexpediente: nuevoNumeroExpediente,
      alumnos: expediente[0].datagrupo[0].alumnose,
      materia: expediente[0].datagrupo[0].materia,
      pretencion: expediente[0].datagrupo[0].pretencion,
      titulo_id: expediente[0].datacurso[0].titulo_id,
      docente_id: expediente[0].datacurso[0].docente_id,
      demanda: expediente[0]?.demanda || null,
      avanze: expediente[0]?.avanze || null,
      fecha: expediente[0].created,
    };
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}docente/expe_cuaderno/post`,
        Data,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        const cuadernoId = response.data.userId;

        const putData = {
          cuaderno_id: cuadernoId,
          nexpediente: nuevoNumeroExpediente,
          fech: new Date(),
          nombre: selectedCuaderno,
        };

        const putResponse = await axios.put(
          `${window.BACKEND_URL}docente/expedientecuaderno/put/${id}`,
          { cuaderno: putData },
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        );

        if (putResponse.status === 200) {
          handleCloseModal();
          Swal.fire({
            icon: "success",
            title: "Guardado",
            text: "¡Se guardó correctamente!",
          });
          fetchData();
        } else {
          console.error("Error en la operación de actualización");
        }
      } else {
        console.error("Error en la operación");
      }
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó correctamente!",
      });
    } catch (error) {
      console.error("Error en la operación", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getnotificaion = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expediente/getnoti/${id}/notifica/${notifuera}`
      );

      setNotificacion(response.data);
      setNota(response.data.calificacion); // Establecer la calificación como valor inicial de nota
      setObservacion(response.data.observanoti); // Establecer la observación como valor inicial de notiObser
    } catch (error) {
      console.error("Error al obtener la notificación del expediente:", error);
    }
  };
  //califacar alumno
  const calificarAlumno = async (e) => {
    e.preventDefault();
    try {
      let notaNumber = parseInt(nota); // Convertir nota a número
      if (tipoCalificacion === "simple") {
        await axios.put(
          `${window.BACKEND_URL}docente/expediente/nota/${id}/notifica/${idNoti}`,
          { tipoCalificacion, nota: notaNumber, notiObser } // Incluir la observacion en los datos enviados al servidor
        );
      } else if (tipoCalificacion === "rubrica") {
        const descripcionSeleccionada = seleccionadas.map(
          (descripcionIndex, criterioIndex) => ({
            criterioIndex,
            descripcionIndex,
          })
        );
        await axios.put(
          `${window.BACKEND_URL}docente/expediente/nota/${id}/notifica/${idNoti}`,
          {
            tipoCalificacion,
            nota: calcularNotaTotal(),
            rubricaSeleccionada,
            descripcionSeleccionada,
            notiObser,
          }
        );
      }

      Swal.fire("Se califico Correctamente", "", "success");
      setRubricaOpen(false);
      closeModa();
    } catch (error) {
      console.error("Error al enviar la calificación:", error);
    }
  };

  useEffect(() => {
    const obtenerRubricas = async () => {
      if (expediente[0]?.curso_id) {
        try {
          const response = await axios.get(
            `${window.BACKEND_URL}docente/curso/rubrica/${expediente[0].curso_id}`
          );
          setRubricas(response.data);
        } catch (error) {
          console.error("Error al obtener las rubricas:", error);
        }
      } else {
      }
    };

    obtenerRubricas();
  }, [expediente]);

  const obtenerExpediente = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    // const resultado = Prueba({
    //   data_expe: 1,
    //   data_demanda: 2,
    //   fecha: 1,
    //   destinatario: 1,
    //   numnotifica: 1,
    // });
    // console.log(resultado);

    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expediente/get/normal/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const expediente = response.data;
      const demanda = expediente.demanda;
      const notificaciones = expediente.notifica;
      console.log("errror", notificaciones);
      const pdfPromises = [];

      // Manejar la demanda primero
      if (demanda && demanda.file) {
        const demandaPromise = fetch(
          `${window.BACKEND_URL}uploads/${demanda.file}`
        )
          .then((res) => res.arrayBuffer())
          .then((pdfBytes) => ({ pdfBytes, demanda }));

        pdfPromises.push(demandaPromise);
      }

      // Luego manejar las notificaciones
      for (const notificacion of notificaciones) {
        if (notificacion && notificacion.file) {
          if (notificacion.estado === 1) {
            const numnotifica = notificacion.numbenotifi;

            const data_expe = dateenvioExpediente;
            const data_demanda = dateenvioExpedienteDemanda;

            const fecha = notificacion.fechaCreacion.slice(0, 10);

            const resultado = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,
              destinatario: 1,
              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado);
            }

            const resultado2 = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,

              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado2);
            }
          }

          const pdfPromise = fetch(
            `${window.BACKEND_URL}uploads/${notificacion.file}`
          )
            .then((res) => res.arrayBuffer())
            .then((pdfBytes) => ({ pdfBytes, notificacion }))
            .catch((error) => {
              console.error("Error al cargar archivo PDF:", error);
              return null;
            });

          if (pdfPromise) {
            pdfPromises.push(pdfPromise);
          }
        } else {
          console.log("No se encontró archivo en notificación:", notificacion);
        }
      }

      // Esperar a que se completen todas las promesas de carga de archivos PDF
      const pdfDataArray = await Promise.all(pdfPromises);

      // Crear un nuevo documento PDF
      const doc = await PDFDocument.create();

      for (const { pdfBytes, demanda, notificacion } of pdfDataArray) {
        // Agregar las páginas del PDF al documento creado
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await doc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => {
          doc.addPage(page);
        });
      }

      const pdfBytes = await doc.save();

      abrirDocumentoPDF(pdfBytes);
      Swal.fire("Se descargo correctamente", "", "success");
    } catch (error) {
      console.log(error);
      console.error("Error al obtener el expediente:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const abrirDocumentoPDF = (pdfBytes) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const descargaex = () => {
    obtenerExpediente();
  };
  //abrir demanda

  const [RevisarDeman, setDemandaRevisar] = useState(false);
  const ArbirDemanda = () => {
    setDemandaRevisar(true);
    setTipoCalificacion("");
  };

  const CerrarDemanda = () => {
    setDemandaRevisar(false);
  };
  const [DemandaRe, setDemandaRe] = useState(false);

  const getDemandaCu = async () => {
    setIsLoading(true); // Iniciar carga
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expediente/get/normal/demanda/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setDemandaRe(response.data);
      setNota(response.data.calificacion);
      setObservacion(response.data.observanoti);
    } catch (error) {
      console.error("Error fetching demanda:", error);
    } finally {
      setIsLoading(false); // Finalizar carga, tanto en caso de éxito como de error
    }
  };

  //demanda calificar
  const handleChangeTipoCalificacion = (e) => {
    setTipoCalificacion(e.target.value);
    setRubricaSeleccionada(null); // Reiniciar la rubrica seleccionada al cambiar el tipo de calificación
  };

  const CalificarDemanda = async (e) => {
    e.preventDefault();
    try {
      let notaNumber = parseInt(nota); // Convertir nota a número
      if (tipoCalificacion === "simple") {
        await axios.put(
          `${window.BACKEND_URL}docente/demanda/califcar/${id}`,
          { tipoCalificacion, nota: notaNumber, notiObser } // Incluir la observación en los datos enviados al servidor
        );
      } else if (tipoCalificacion === "rubrica") {
        const descripcionSeleccionada = seleccionadas.map(
          (descripcionIndex, criterioIndex) => ({
            criterioIndex,
            descripcionIndex,
          })
        );
        await axios.put(`${window.BACKEND_URL}docente/demanda/califcar/${id}`, {
          tipoCalificacion,
          nota: calcularNotaTotal(),
          rubricaSeleccionada,
          descripcionSeleccionada,
          notiObser,
        });
      }

      Swal.fire("Se calificó correctamente", "", "success");
      setRubricaOpen(false);
      CerrarDemanda();
    } catch (error) {
      console.error("Error al enviar la calificación:", error);
    }
  };

  const [submitting, setSubmitting] = useState(false);

  //notificaicon

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="text-center">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p className="fs-3">Cargando Expediente...</p>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
            <div className="container-fluid rounded-end-4">
              {expediente.map((data) => (
                <div className="row " key={data._id}>
                  <div className="col-md-8 col-8">
                    <h2 className="py-2 pt-3"> Expedientes Electrónicos</h2>
                  </div>
                  <div className="col-md-3 col-2 ">
                    {" "}
                    <button
                      style={{
                        color: "#fff",
                        backgroundColor: "#009688",
                      }}
                      className="btn  mt-3"
                      onClick={descargaex}
                      disabled={submitting}
                    >
                      {submitting ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          <span class="d-none d-sm-inline">
                            {" "}
                            Descargando...
                          </span>
                        </>
                      ) : (
                        <>
                          <i class="fa-solid fa-download"></i>

                          <span class="d-none d-sm-inline"> Descargar</span>
                        </>
                      )}
                    </button>
                  </div>
                  <div className="col-md-1 col-2  dropdown ">
                    <button
                      className="btn mt-3"
                      onClick={toggleDropdown}
                      aria-expanded={isOpen ? "true" : "false"}
                    >
                      <i className="fas fa-ellipsis fa-lg bg-stemis"></i>
                    </button>
                    <ul
                      className={`dropdown-menu ${isOpen ? "show" : ""}`}
                      onClick={toggleDropdown}
                      style={{ left: "-5rem" }}
                    >
                      <li>
                        <button
                          onClick={toggleEdit}
                          className={`dropdown-item bg-stemis ${
                            data.typedema ? "" : "disabled"
                          }`}
                        >
                          <i className="fa-solid fa-pen-to-square"></i> Editar
                        </button>
                      </li>
                      <li>
                        <Link
                          to={`/agregar-escrito/${data._id}`}
                          className="dropdown-item bg-stemis"
                        >
                          <i className="fa-regular fa-plus bg-stemis me-1"></i>
                          Escrito
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/Agregar-notificacion/${data._id}`}
                          className="dropdown-item bg-stemis"
                        >
                          <i className="fa-regular fa-plus bg-stemis me-1"></i>
                          Notificación
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item bg-stemis"
                          onClick={() => handleOpenModal()}
                        >
                          <i className="fa-regular fa-plus bg-stemis me-1"></i>
                          Cuadernos
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {expediente.map((data) => (
              <section key={data._id}>
                <form onSubmit={confirmsFormu} id="myForm">
                  <div className="container-fluid mt-3">
                    <h3>Identificación de Procesos</h3>
                    <div className="row">
                      <div className="col-md-6 px-4">
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2 ">
                            <p className="mb-0 text-left" style={style1}>
                              N° del Expediente:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={
                                data.nexpediente
                                  ? data.nexpediente.toUpperCase()
                                  : ""
                              }
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0  text-left" style={style1}>
                              Juez:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={data.datagrupo[0].alumnose
                                .filter((alumno) => alumno.rol_id === "Juez")
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Distrito Judicial:
                              <text className="text-danger">*</text>
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <select
                              className="form-select"
                              name="distrito_id"
                              id="distrito_id"
                              disabled={disabled}
                              style={style2}
                              onChange={(e) => {
                                setSelectedJudicial(e.target.value);
                              }}
                            >
                              {distrito_judicial
                                .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                                .map((judicial) => (
                                  <option
                                    key={judicial._id}
                                    value={judicial._id}
                                    selected={
                                      judicial._id ===
                                      (data?.demanda?.distrito_id || "")
                                    }
                                  >
                                    {judicial.nombre}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Juzgado:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <select
                              type="text"
                              className="form-select"
                              name="juzgado_id"
                              disabled={disabled}
                              onChange={(e) => {
                                setJuzgado(e.target.value);
                              }}
                              style={style2}
                              id="juzgado_id"
                            >
                              {juzgado
                                .filter(
                                  (juzga) => juzga.sede_id === selectedSede
                                )
                                .map((datos) => (
                                  <option
                                    key={datos._id}
                                    value={datos._id}
                                    selected={
                                      datos._id ===
                                      (data?.demanda.juzgado_id || "sa")
                                    }
                                  >
                                    {datos.nombrejuzgado}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Sala suprema:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <select
                              className="form-select"
                              name="sala_suprema_id"
                              id="sala_suprema_id"
                              disabled={disabled}
                              style={style2}
                            >
                              <option value=""></option>

                              {salasupre
                                .filter(
                                  (dat) => dat.materia === seleJuzgado.materia
                                )
                                .map((datos) => (
                                  <option
                                    key={datos._id}
                                    value={datos._id}
                                    selected={
                                      datos._id ===
                                      (data?.demanda?.sala_suprema_id || "")
                                    }
                                  >
                                    {datos.nombre}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>

                      <div className="col-md-6 px-4">
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-3 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Docente:
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={docente}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-3 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Especialista:
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={data.datagrupo[0].alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Especialista"
                                )
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-3 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Sede:
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <select
                              className="form-select"
                              name="sede_id"
                              id="sede_id"
                              disabled={disabled}
                              onChange={(e) => {
                                const select = sede_judi.find(
                                  (data) => data._id === e.target.value
                                );

                                setSede(select._id);
                              }}
                              style={style2}
                              required
                            >
                              {sede_judi
                                .filter(
                                  (sede) => sede.distrito === selectedJudicial
                                )
                                .map((sedej) => (
                                  <option
                                    key={sedej._id}
                                    value={sedej._id}
                                    selected={
                                      sedej._id ===
                                      (data?.demanda.sede_id || "")
                                    }
                                  >
                                    {sedej.sede}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-3 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Sala:
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <select
                              className="form-select"
                              name="sala_id"
                              id="sala_id"
                              disabled={disabled}
                              style={style2}
                            >
                              <option value=""></option>
                              {sala
                                .filter((sala) => sala.sede_id === selectedSede)
                                .map((datos) => (
                                  <option
                                    key={datos._id}
                                    value={datos._id}
                                    selected={
                                      datos._id ===
                                      (data?.demanda?.sala_id || "sa")
                                    }
                                  >
                                    {datos.nombre}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-3 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Sumilla:
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <textarea
                              type="text"
                              name="sumilla"
                              defaultValue={
                                data.demanda ? data.demanda.sumilla : ""
                              }
                              className="form-control"
                              style={{
                                ...style2,
                                textTransform: "uppercase",
                                resize: "none",
                                height: "auto",
                                overflowY: "hidden",
                                fontSize: "13px",
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>
                    </div>
                    <hr className="p-0" />
                  </div>
                  <div className="container-fluid mt-3">
                    <h3>Detalle del Procesos</h3>
                    <div className="row">
                      <div className="col-md-6 px-4">
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Fecha de inicio:
                              <text className="text-danger">*</text>
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="date"
                              className="form-control"
                              style={style2}
                              value={data.created.split("T")[0]} // Asumiendo que data.created es en formato ISO8601
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Pretencion:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={data.datagrupo[0].datapretension[0].nombre.replace(
                                /\s+/g,
                                ""
                              )}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Etapa de materia:
                              <text className="text-danger">*</text>
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <select
                              className="form-select"
                              name="etapa_materia"
                              style={style2}
                              disabled={disabled}
                            >
                              {etapamateria
                                .filter(
                                  (etapa) =>
                                    etapa?.viaprocedi === selectviaprocedi
                                )
                                .map((datos) => (
                                  <option
                                    key={datos._id}
                                    value={datos._id}
                                    selected={
                                      datos._id ===
                                      (data?.avanze?.etapa_materia || "")
                                    }
                                  >
                                    {datos.nombre}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Costas Costos:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="number"
                              name="costos"
                              className="form-control"
                              defaultValue={data?.avanze?.costos}
                              style={style2}
                              disabled={disabled}
                            />
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>

                      <div className="col-md-6 px-4">
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Materia:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              style={style2}
                              value={data.datagrupo[0].materia}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Via Procedimental:
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <select
                              className="form-select"
                              name="via_procedi"
                              id="via_procedi"
                              disabled={disabled}
                              style={style2}
                              onChange={(e) => {
                                const select = viaprocedi.find(
                                  (data) => data._id === e.target.value
                                );

                                setselectViaProcedi(select?._id);
                              }}
                            >
                              <option value=""></option>
                              {viaprocedi
                                .filter(
                                  (via) =>
                                    via.materia ===
                                    data.datagrupo[0].datapretension[0].tipo
                                )
                                .map((viaprocedi) => (
                                  <option
                                    key={viaprocedi._id}
                                    value={viaprocedi._id}
                                    selected={
                                      viaprocedi._id ===
                                      (data?.avanze?.via_procedi || "")
                                    }
                                  >
                                    {viaprocedi.viaprocedi}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Indeterminado:
                            </p>
                          </div>
                          <div className="col-md-8 px-0 mt-1">
                            <select
                              className="form-select"
                              name="indeterminado"
                              id="indeterminado"
                              defaultValue={
                                selectinderter === "SI" ? "SI" : "NO"
                              } // Asignamos "SI" si selectinderter es "SI", de lo contrario, asignamos "NO"
                              disabled={disabled}
                              onChange={(e) => {
                                console.log(selectinderter);
                                const value = e.target.value;
                                setInputDisabled(value === "SI"); // Si el valor es "SI", setInputDisabled(true); de lo contrario, setInputDisabled(false);
                              }}
                              style={{
                                border: "1px solid rgb(172, 172, 172)",
                                fontSize: "14px",
                                color: "#4b4b4b",
                                maxWidth: "90px",
                              }}
                            >
                              {selectinderter === "SI" ? (
                                <>
                                  <option value="SI">SI</option>
                                  <option value="NO">NO</option>
                                </>
                              ) : (
                                <>
                                  <option value="NO">NO</option>
                                  <option value="SI">SI</option>
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="row  align-items-center pt-2">
                          <div className="col-6 col-md-4 mt-2">
                            <p className="mb-0 text-left" style={style1}>
                              Cuantia :
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="number"
                              defaultValue={data?.demanda?.monto_soles}
                              className="form-control"
                              name="monto_soles"
                              style={style2}
                              disabled={disabled ? true : inputDisabled}
                            />
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>

                      <div className="col col-md-12 mt-2 px-4">
                        <div className="row  align-items-center pt-2">
                          <div className="col col-md-2 mb-0">
                            <p className="mb-0 text-left" style={style1}>
                              Observaciones:
                            </p>
                          </div>
                          <div className="col-md-10 p-0 mt-2">
                            <textarea
                              type="text"
                              name="observaciones"
                              defaultValue={
                                data.demanda ? data.demanda.observaciones : null
                              }
                              className="form-control"
                              style={{
                                ...style2,
                                resize: "none",
                                height: "auto",
                                overflowY: "hidden",
                                fontSize: "13px",
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="p-0" />
                  </div>
                  <div className="container-fluid mt-3">
                    <h3>Partes de procesales</h3>
                    <div className="row">
                      <div className="col-md-6 px-4">
                        <div className="row">
                          <div className="col-6 col-md-4 mt-2">
                            <p className=" mb-0 text-left" style={style1}>
                              Parte demandante :
                            </p>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <textarea
                              className="form-control"
                              style={{
                                ...style2,
                                overflowY: "hidden",
                                resize: "none",
                                fontSize: "13px",
                                lineHeight: "1.5",
                              }}
                              value={data.datagrupo[0].alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Demandante"
                                )
                                .map((alumno) => alumno?.nombre)
                                .join("\n")}
                              readOnly
                              disabled
                              rows={
                                data.datagrupo[0].alumnose.filter(
                                  (alumno) => alumno.rol_id === "Demandante"
                                ).length
                              }
                              onScroll={(e) => {
                                e.target.scrollTop =
                                  e.target.scrollHeight - e.target.clientHeight;
                              }}
                              onWheel={(e) => e.preventDefault()}
                            />
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>

                      <div className="col-md-6 px-4">
                        <div className="row">
                          <div className="col-6 col-md-3 mt-2">
                            <p className=" mb-0 text-left" style={style1}>
                              Parte demandada :
                            </p>
                          </div>
                          <div className="col-md-9 p-0 mt-2">
                            <textarea
                              className="form-control"
                              style={{
                                ...style2,
                                overflowY: "hidden",
                                resize: "none",
                                fontSize: "13px",
                                lineHeight: "1.5",
                              }}
                              value={data.datagrupo[0].alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Demandado"
                                )
                                .map((alumno) => alumno?.nombre)
                                .join("\n")}
                              readOnly
                              disabled
                              rows={
                                data.datagrupo[0].alumnose.filter(
                                  (alumno) => alumno.rol_id === "Demandado"
                                ).length
                              }
                              onScroll={(e) => {
                                e.target.scrollTop =
                                  e.target.scrollHeight - e.target.clientHeight;
                              }}
                              onWheel={(e) => e.preventDefault()}
                            />
                          </div>
                        </div>

                        {/* Otros campos similares se pueden agregar aquí */}
                      </div>
                    </div>
                    <hr className="p-0" />
                    {!disabled && (
                      <div className="row">
                        <div className="col-6  mb-3 mt-4">
                          {/* <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                             setFile(e.target.value);
                        }}
                        className="form-control "
                        id="file"
                      /> */}
                        </div>

                        <div className="col-1 px-0 mt-2 d-flex flex-row-reverse ">
                          {/* <a
                        className="btn "
                        target="_blank"
                        style={{ fontSize: "34px" }}
                        href={`${window.BACKEND_URL}uploads/${data.demanda.file}`}
                        download={`${window.BACKEND_URL}uploads/${data.demanda.file}`}
                        rel="noreferrer"
                      >
                        <i class="fa-solid fa-file-arrow-down "></i>
                      </a> */}
                        </div>
                        <div className="col-4 mb-4 mt-4 d-flex flex-row-reverse ">
                          <button
                            id="myButton"
                            className="btn btn-primary btn px-5"
                            type="submit"
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>

                <div className="container-fluid">
                  <div className="container-fluid rounded-2 shadow bg-light ">
                    <div className="row">
                      <div className="col-6 mt-2 px-4 mb-2 ">
                        <span>
                          <h2>Cuadernos</h2>
                        </span>
                      </div>
                    </div>
                    <div className="px-3 p-1">
                      <table className="table table-light table-responsive">
                        <thead className="table-secondary rounded-top-5 shadow">
                          <tr>
                            <th>Número de Cuaderno</th>
                            <th>Fecha</th>
                            <th>Tipo de Cuaderno</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.cuaderno
                            ? data.cuaderno.map((datos_cu) => (
                                <tr key={datos_cu._id}>
                                  <th>
                                    {datos_cu.cuaderno_id && (
                                      <Link
                                        to={`/cuadernoedit/${id}/${datos_cu.cuaderno_id}`}
                                      >
                                        {datos_cu.nexpediente}
                                      </Link>
                                    )}
                                  </th>
                                  <td>
                                    {new Date(datos_cu.fech).toLocaleString()}
                                  </td>
                                  <td>{datos_cu.nombre}</td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        handleDelete(datos_cu.cuaderno_id);
                                      }}
                                      className="btn btn-stemis btn-sm"
                                    >
                                      <i className="fa-solid fa-trash"></i>{" "}
                                    </button>
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <ul
                    className="timeline pe-3  pb-4"
                    // id="expediante-scroll
                  >
                    {data.demanda ? (
                      <>
                        <li className="pt-2">
                          <div class="timeline-time d-none  d-xl-block">
                            <span class="date">
                              {new Date(data.demanda.fechaCreacion).getDate()}{" "}
                              de{" "}
                              {
                                meses[
                                  new Date(
                                    data.demanda.fechaCreacion
                                  ).getMonth()
                                ]
                              }{" "}
                              de{" "}
                              {new Date(
                                data.demanda.fechaCreacion
                              ).getFullYear()}
                            </span>
                            <span class="time">
                              {new Date(data.demanda.fechaCreacion).getHours()}:
                              {new Date(
                                data.demanda.fechaCreacion
                              ).getMinutes()}
                              :
                              {new Date(
                                data.demanda.fechaCreacion
                              ).getSeconds()}
                            </span>
                          </div>
                          <div class="timeline-icon d-none  d-xl-block">
                            <a href="javascript:;">&nbsp;</a>
                          </div>
                          <div class="timeline-body  rounded shadow ">
                            <div className="d-md-none   ">
                              <h5 style={{ fontSize: 18 }}>
                                {new Date(data.demanda.fechaCreacion).getDate()}
                                {"  "}
                                de{"  "}
                                {
                                  meses[
                                    new Date(
                                      data.demanda.fechaCreacion
                                    ).getMonth()
                                  ]
                                }
                                {"  "}
                                de{"  "}
                                {new Date(
                                  data.demanda.fechaCreacion
                                ).getFullYear()}
                                {"  "}
                                <span>
                                  {new Date(
                                    data.demanda.fechaCreacion
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  })}
                                </span>
                              </h5>
                            </div>

                            <div class="timeline-header py-3">
                              <div className="row">
                                <div className="col-8 text-white align-content-center">
                                  <span>
                                    <h3>Demanda</h3>
                                  </span>
                                </div>

                                <div className="col-2 px-0  text-end">
                                  <Link
                                    to={`/Editar-demanda/${id}`}
                                    className="btn btn-noti  btn-sm"
                                  >
                                    <span className=" me-1 d-none d-sm-inline">
                                      Editar
                                    </span>
                                    <i className="fa-solid fa-circle-plus "></i>{" "}
                                  </Link>
                                </div>
                                <div className="col-2 px-0  text-end pe-2  ">
                                  <button
                                    className="btn btn-noti btn-sm"
                                    onClick={() => ArbirDemanda()}
                                  >
                                    <span className="me-1 d-none d-sm-inline">
                                      Revisar
                                    </span>
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="timeline-content">
                              <div className="row">
                                <div className="col-md-6 ">
                                  <p>
                                    <h4>Acto:</h4> Demanda
                                  </p>
                                </div>
                                <div className="col-md-6 ">
                                  <p>
                                    <h4>Creador por: </h4>
                                    {data.demanda.user}
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12  ">
                                  <div className="row">
                                    <div className="col-12 col-md-2 mt-1  py-2">
                                      <h4>Sumilla:</h4>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 px-0 ms-2">
                                        <input
                                          style={style3}
                                          type="text"
                                          className="form-control"
                                          value={data.demanda.sumilla}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    {data.notifica.map((datanotifi, index) => (
                      <li key={index} className="pt-2">
                        <div class="timeline-time d-none  d-xl-block">
                          <span class="date">
                            {new Date(datanotifi.fechaCreacion).getDate()} de{" "}
                            {
                              meses[
                                new Date(datanotifi.fechaCreacion).getMonth()
                              ]
                            }{" "}
                            de{" "}
                            {new Date(datanotifi.fechaCreacion).getFullYear()}
                          </span>
                          <span class="time">
                            {new Date(datanotifi.fechaCreacion).getHours()}:
                            {new Date(datanotifi.fechaCreacion).getMinutes()}:
                            {new Date(datanotifi.fechaCreacion).getSeconds()}
                          </span>
                        </div>
                        <div class="timeline-icon d-none  d-xl-block">
                          <a href="javascript:;">&nbsp;</a>
                        </div>
                        <div class="timeline-body  rounded shadow ">
                          <div className="d-md-none  pt-2 ">
                            <h5 style={{ fontSize: 18 }}>
                              {new Date(datanotifi.fechaCreacion).getDate()}
                              {"  "}
                              de{"  "}
                              {
                                meses[
                                  new Date(datanotifi.fechaCreacion).getMonth()
                                ]
                              }
                              {"  "}
                              de{"  "}
                              {new Date(datanotifi.fechaCreacion).getFullYear()}
                              {"  "}
                              <span>
                                {new Date(
                                  datanotifi.fechaCreacion
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })}
                              </span>
                            </h5>
                          </div>
                          <div class="timeline-header pt-3">
                            <div className="row">
                              <div className="col-md-6 text-white pb-2 ">
                                <span>
                                  <h3>
                                    {datanotifi?.tipo}: {"000" + (index + 1)}
                                  </h3>
                                </span>
                              </div>

                              {datanotifi.estado !== 3 && (
                                <div className="col-md-2 col text-end ">
                                  <Link
                                    to={`/Editar-notificacion/${id}/edit/${datanotifi.noti_id}`}
                                    className="btn btn-noti  btn-sm"
                                  >
                                    <span className=" me-1 d-none d-sm-inline">
                                      Editar
                                    </span>
                                    <i className="fa-solid fa-circle-plus "></i>{" "}
                                  </Link>
                                </div>
                              )}
                              <div className="col-md-2 col text-center   ">
                                <button
                                  onClick={async () => {
                                    if (datanotifi.noti_id) {
                                      revisarmol(datanotifi.noti_id);

                                      await getnotificaion();
                                    } else {
                                      console.error(
                                        "El ID de notificación es nulo"
                                      );
                                    }
                                  }}
                                  className="btn btn-noti btn-sm"
                                >
                                  <span className="me-1 d-none d-sm-inline ">
                                    Revisar
                                  </span>
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                              </div>

                              {datanotifi.estado !== 3 ? (
                                <div className="col-md-2  col text-start  ">
                                  <button
                                    onClick={() => {
                                      notidelete(datanotifi.noti_id);
                                    }}
                                    className="btn btn-noti btn-sm"
                                  >
                                    <span className="ml-2 me-1 text-sm d-none d-sm-inline">
                                      Eliminar
                                    </span>
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                              ) : (
                                <div className="col-md-2">
                                  <button
                                    onClick={() => {
                                      // Acción para el nuevo botón cuando el estado es igual a 3
                                    }}
                                    className="btn btn-noti btn-sm"
                                  >
                                    <span className="ml-2 me-1 text-sm">
                                      Eliminar demadna
                                    </span>
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="timeline-content">
                            <div className="row">
                              <div className="col-md-6">
                                <p>
                                  <h4>Acto:</h4> {datanotifi.acto}
                                </p>

                                {datanotifi.tipo_resolu ? (
                                  <p className="p-0 m-0">
                                    <h4>Resolucion:</h4>{" "}
                                    {datanotifi.tipo_resolu}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-md-6">
                                <p
                                  className={
                                    window.innerWidth <= 768
                                      ? "text-start "
                                      : "text-end"
                                  }
                                >
                                  <h4>Creador por: </h4>
                                  {datanotifi.user}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 ">
                                <div className="row">
                                  {datanotifi.estado === 2 ? (
                                    <>
                                      <div className="col-12 col-md-2 mt-1 py-2">
                                        <h4>Sumilla:</h4>
                                      </div>
                                      <div className="row px-0 mx-0">
                                        <div
                                          className={`${
                                            datanotifi.grab_audiencia
                                              ? `col-12 col-md-6 mt-1`
                                              : `col-12`
                                          }`}
                                        >
                                          <textarea
                                            type="text"
                                            className="form-control custom-input-width"
                                            value={datanotifi.sumilla}
                                            disabled
                                            style={{
                                              ...(datanotifi?.grab_audiencia
                                                ? {
                                                    ...style3,
                                                    height: "200px",
                                                    paddingTop: "0",
                                                    verticalAlign: "top",
                                                    resize: "none",

                                                    overflowY: "hidden",
                                                  }
                                                : {
                                                    ...style3,
                                                    resize: "none",
                                                  }),
                                            }}
                                          />
                                        </div>

                                        {datanotifi.grab_audiencia ? (
                                          <div className="col-12 col-md-6 mt-1">
                                            <VideoPreview
                                              src={`${datanotifi.grab_audiencia}`}
                                            />
                                          </div>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                      <div className="row">
                                        <div className="col-12 py-2">
                                          <h3 className="">
                                            Notificaciónes:{" "}
                                            {datanotifi.parte_notifi ? 1 : 2}
                                          </h3>
                                        </div>
                                      </div>
                                      {datanotifi.parte_notifi ? (
                                        datanotifi.parte_notifi === 1 ? (
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandados
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12 text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {(
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandantes
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12  text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    destinatario={1}
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      (
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <>
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandados
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12 text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {(
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandantes
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12  text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    destinatario={1}
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      (
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : datanotifi.estado === 1 ? (
                                    <>
                                      <div className="col-12 col-md-2 mt-1 py-2">
                                        <h4>Sumilla:</h4>
                                      </div>
                                      <div className="row">
                                        <div
                                          className={`${
                                            datanotifi.grab_audiencia
                                              ? `col-12 col-md-6 mt-1`
                                              : `col-12 `
                                          }`}
                                        >
                                          <textarea
                                            type="text"
                                            className="form-control custom-input-width"
                                            value={datanotifi.sumilla}
                                            disabled
                                            style={{
                                              ...(datanotifi?.grab_audiencia
                                                ? {
                                                    ...style3,
                                                    height: "200px",
                                                    paddingTop: "0",
                                                    verticalAlign: "top",
                                                    resize: "none",
                                                    overflowY: "hidden",
                                                  }
                                                : {
                                                    ...style3,
                                                    resize: "none",
                                                  }),
                                            }}
                                          />
                                        </div>
                                        {datanotifi.grab_audiencia ? (
                                          <div className="col-12 col-md-6 mt-1">
                                            <VideoPreview
                                              src={`${datanotifi.grab_audiencia}`}
                                            />
                                          </div>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                      <div className="row">
                                        <div className="col-12 py-2">
                                          <h3 className="">
                                            Notificaciónes:{" "}
                                            {datanotifi.parte_notifi ? 1 : 2}
                                          </h3>
                                        </div>
                                      </div>
                                      {datanotifi.parte_notifi ? (
                                        datanotifi.parte_notifi === 1 ? (
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandados
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12 text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {(
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandantes
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12  text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    destinatario={1}
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      (
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <>
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {datanotifi?.numbenotifi +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandados
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12 text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="integrat-cursos-scroll">
                                            <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                              <div className="row justify-content-center m-1 ">
                                                <div className="col-md-6 col-12 text-start mt-2 ">
                                                  <div className="col">
                                                    <h5>
                                                      {" Notificación  "}
                                                      {(
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                        data.nexpediente.slice(
                                                          5,
                                                          10
                                                        ) +
                                                        data.nexpediente.slice(
                                                          17,
                                                          23
                                                        )}
                                                    </h5>
                                                  </div>
                                                  <div className="col">
                                                    <h4>Destinatario: </h4>
                                                    Demandantes
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-12  text-start align-self-end">
                                                  <div className="pb-2">
                                                    <h4>Fecha: </h4>
                                                    {datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="col-md-2 col-12 text-center align-self-center">
                                                  <PDF
                                                    data_href={`${datanotifi.file} `}
                                                    data_expe={
                                                      dateenvioExpediente
                                                    }
                                                    data_demanda={
                                                      dateenvioExpedienteDemanda
                                                    }
                                                    destinatario={1}
                                                    fecha={datanotifi.fechaCreacion.slice(
                                                      0,
                                                      10
                                                    )}
                                                    numnotifica={
                                                      (
                                                        parseInt(
                                                          datanotifi?.numbenotifi
                                                        ) + 1
                                                      )
                                                        .toString()
                                                        .padStart(5, "0") +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className="col-12 col-md-2 mt-1 py-2 ">
                                        <h4>Sumilla:</h4>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <input
                                            style={style3}
                                            type="text"
                                            className="form-control"
                                            value={datanotifi.sumilla}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            ))}
          </div>

          <div className="col">
            <button
              type="button"
              className="btn btn-cancelar"
              onClick={() => {
                window.history.back();
              }}
            >
              Salir del expediente
            </button>
          </div>
        </>
      )}

      {/* cuaderno */}
      <ReactModal
        onRequestClose={handleCloseModal}
        isOpen={showModal}
        style={customStyles}
        contentLabel="Enviar tarea"
      >
        <h2>Agregar cuaderno</h2>
        <form onSubmit={handleSubmit}>
          <div className="row pb-4">
            <div className="col-md-4 mt-2">
              <label htmlFor="path">
                <p>
                  Tipo de cuaderno
                  <span className="text-danger">*</span>
                </p>
              </label>
            </div>
            <div className="col-md-8 p-0 mt-2">
              <select
                className="form-control"
                id="path"
                name="path"
                value={selectedCuaderno}
                onChange={handleSelectChange}
              >
                <option value="">Seleccionar un cuaderno...</option>
                {cuadernos.map((data) => (
                  <option key={data._id} value={data.nombre}>
                    {data.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-cancelar"
                onClick={handleCloseModal}
              >
                Cancelar
              </button>
            </div>
            <div className="col text-end">
              <button
                type="submit"
                className="btn btn-stemis"
                disabled={isLoading}
              >
                {isLoading ? "Agregando..." : "Agregar"}
              </button>
            </div>
          </div>
        </form>
      </ReactModal>

      {/* //notificacion calificar */}
      <ReactModal
        onAfterOpen={getnotificaion}
        isOpen={revisarModal}
        onRequestClose={closeModa}
        contentLabel="Enviar tarea"
        style={customStyles2}
      >
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {notificacion ? (
              <>
                <form onSubmit={calificarAlumno}>
                  <div className="row">
                    <h2>Revisar {notificacion.acto}</h2>
                    <div className="col col-md-7">
                      <iframe
                        src={`${window.BACKEND_URL}uploads/${notificacion.file}`}
                        width="100%"
                        height="1000rem"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>
                    <div className="col col-md-4">
                      <select
                        value={tipoCalificacion}
                        onChange={handleChangeTipoCalificacion}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Selecione una opccion</option>
                        <option value="simple">Calificación Simple</option>
                        <option value="rubrica">
                          Calificación por Rubrica
                        </option>
                      </select>
                      {tipoCalificacion === "simple" && (
                        <>
                          <label htmlFor="calificacionSimple">
                            Calificación:
                          </label>
                          <input
                            type="number"
                            name="nota"
                            className="form-control"
                            min="0"
                            max="20"
                            value={nota}
                            onChange={(e) => setNota(e.target.value)}
                          />
                          <label htmlFor="observaciones">Observaciones1:</label>
                          <textarea
                            id="observacion"
                            name="observacion"
                            className="form-control"
                            value={notiObser}
                            onChange={(e) => setObservacion(e.target.value)}
                          ></textarea>
                        </>
                      )}
                      {tipoCalificacion === "rubrica" && (
                        <>
                          <select
                            id="selectRubrica"
                            value={rubricaSeleccionada}
                            onChange={(e) => {
                              setRubricaSeleccionada(e.target.value);
                            }}
                            className="form-select mt-3"
                          >
                            <option value="">Seleccione una rúbrica</option>
                            {rubricas.map((rubrica) => (
                              <option
                                key={rubrica.rubrica_id}
                                value={rubrica.rubrica_id}
                              >
                                {rubrica.titulo}
                              </option>
                            ))}
                          </select>
                          {rubricaSeleccionada &&
                            rubricas
                              .filter(
                                (rubrica) =>
                                  rubrica.rubrica_id === rubricaSeleccionada
                              )
                              .map((rubrica) => (
                                <div
                                  key={rubrica.rubrica_id}
                                  className="rubrica-details"
                                >
                                  <h2>{rubrica.titulo}</h2>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Criterio</th>
                                        {rubrica.criterios[0]?.descripciones.map(
                                          (_, index) => (
                                            <th key={index}>
                                              Descripción {index + 1}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rubrica.criterios.map(
                                        (criterio, criterioIndex) => (
                                          <tr key={criterioIndex}>
                                            <td>{criterio.criterio}</td>
                                            {criterio.descripciones.map(
                                              (
                                                descripcion,
                                                descripcionIndex
                                              ) => (
                                                <td
                                                  key={descripcionIndex}
                                                  className={
                                                    seleccionadas[
                                                      criterioIndex
                                                    ] === descripcionIndex
                                                      ? "selected"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleSeleccionarDescripcion(
                                                      descripcionIndex,
                                                      criterioIndex
                                                    )
                                                  }
                                                >
                                                  {descripcion.descripcion}{" "}
                                                  (Nota: {descripcion.nota})
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <label htmlFor="observaciones">
                                    Observaciones22:
                                  </label>
                                  <textarea
                                    id="observacion"
                                    name="observacion"
                                    className="form-control"
                                    value={notiObser}
                                    onChange={(e) =>
                                      setObservacion(e.target.value)
                                    }
                                  ></textarea>
                                  <h3>Nota final: {calcularNotaTotal()}</h3>
                                </div>
                              ))}
                        </>
                      )}
                      <div className="row mt-3">
                        <div className="col col-md-6">
                          <button
                            className="btn btn-cancelar"
                            onClick={closeModa}
                          >
                            Cerrar
                          </button>
                        </div>
                        <div className="col col-md-3">
                          <button
                            type="submit"
                            className="btn btn-stemis"
                            disabled={
                              isLoading || // Si isLoading es true, el botón está deshabilitado
                              tipoCalificacion === "" || // Si no se ha seleccionado el tipo de calificación, el botón está deshabilitado
                              (tipoCalificacion === "simple" &&
                                (nota === 0 ||
                                  (notiObser && notiObser.trim() === ""))) || // Si es calificación simple y nota es 0 o la observación está vacía, el botón está deshabilitado
                              (tipoCalificacion === "rubrica" &&
                                rubricaSeleccionada === "") // Si es calificación por rúbrica y no se ha seleccionado una rúbrica, el botón está deshabilitado
                            }
                          >
                            Calificar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <p>Notificación no encontrada</p>
            )}
          </>
        )}
      </ReactModal>

      {/*     //demadna calificar */}
      <ReactModal
        onAfterOpen={getDemandaCu}
        isOpen={RevisarDeman}
        onRequestClose={CerrarDemanda}
        contentLabel="revisar tarea"
        style={customStyles2}
      >
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {DemandaRe ? (
              <>
                <h2>Revisar Demanda</h2>
                <form onSubmit={CalificarDemanda}>
                  <div className="row">
                    <div className="col col-md-8">
                      <iframe
                        src={`${window.BACKEND_URL}uploads/${DemandaRe.file}`}
                        width="100%"
                        height="1000rem"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>
                    <div className="col col-md-3">
                      <select
                        value={tipoCalificacion}
                        onChange={handleChangeTipoCalificacion}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Selecione una opccion</option>
                        <option value="simple">Calificación Simple</option>
                        <option value="rubrica">
                          Calificación por Rubrica
                        </option>
                      </select>
                      {tipoCalificacion === "simple" && (
                        <>
                          <label htmlFor="calificacionSimple">
                            Calificación:
                          </label>
                          <input
                            type="number"
                            name="nota"
                            className="form-control"
                            min="0"
                            max="20"
                            value={nota}
                            onChange={(e) => setNota(e.target.value)}
                          />
                          <label htmlFor="observaciones">Observaciones:</label>
                          <textarea
                            id="observacion"
                            name="observacion"
                            className="form-control"
                            value={notiObser}
                            onChange={(e) => setObservacion(e.target.value)}
                          ></textarea>
                        </>
                      )}
                      {tipoCalificacion === "rubrica" && (
                        <>
                          <select
                            id="selectRubrica"
                            value={rubricaSeleccionada}
                            onChange={(e) => {
                              setRubricaSeleccionada(e.target.value);
                            }}
                            className="form-select mt-3"
                          >
                            <option value="">Seleccione una rúbrica</option>
                            {rubricas.map((rubrica) => (
                              <option
                                key={rubrica.rubrica_id}
                                value={rubrica.rubrica_id}
                              >
                                {rubrica.titulo}
                              </option>
                            ))}
                          </select>
                          {rubricaSeleccionada &&
                            rubricas
                              .filter(
                                (rubrica) =>
                                  rubrica.rubrica_id === rubricaSeleccionada
                              )
                              .map((rubrica) => (
                                <div
                                  key={rubrica.rubrica_id}
                                  className="rubrica-details"
                                >
                                  <h2>{rubrica.titulo}</h2>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Criterio</th>
                                        {rubrica.criterios[0]?.descripciones.map(
                                          (_, index) => (
                                            <th key={index}>
                                              Descripción {index + 1}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rubrica.criterios.map(
                                        (criterio, criterioIndex) => (
                                          <tr key={criterioIndex}>
                                            <td>{criterio.criterio}</td>
                                            {criterio.descripciones.map(
                                              (
                                                descripcion,
                                                descripcionIndex
                                              ) => (
                                                <td
                                                  key={descripcionIndex}
                                                  className={
                                                    seleccionadas[
                                                      criterioIndex
                                                    ] === descripcionIndex
                                                      ? "selected"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleSeleccionarDescripcion(
                                                      descripcionIndex,
                                                      criterioIndex
                                                    )
                                                  }
                                                >
                                                  {descripcion.descripcion}
                                                  (Nota: {descripcion.nota})
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <label htmlFor="observaciones">
                                    Observaciones:
                                  </label>
                                  <textarea
                                    id="observacion"
                                    name="observacion"
                                    className="form-control"
                                    value={notiObser}
                                    onChange={(e) =>
                                      setObservacion(e.target.value)
                                    }
                                  ></textarea>
                                  <h3>Nota final: {calcularNotaTotal()}</h3>
                                </div>
                              ))}
                        </>
                      )}
                      <div className="row mt-3">
                        <div className="col col-md-6">
                          <button
                            className="btn btn-cancelar"
                            onClick={CerrarDemanda}
                          >
                            Cerrar
                          </button>
                        </div>
                        <div className="col col-md-3">
                          <button
                            type="submit"
                            className="btn btn-stemis"
                            disabled={
                              isLoading || // Si isLoading es true, el botón está deshabilitado
                              tipoCalificacion === "" || // Si no se ha seleccionado el tipo de calificación, el botón está deshabilitado
                              (tipoCalificacion === "simple" &&
                                (nota === 0 ||
                                  (notiObser && notiObser.trim() === ""))) || // Si es calificación simple y nota es 0 o la observación está vacía, el botón está deshabilitado
                              (tipoCalificacion === "rubrica" &&
                                rubricaSeleccionada === "") // Si es calificación por rúbrica y no se ha seleccionado una rúbrica, el botón está deshabilitado
                            }
                          >
                            Calificar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <p>Demanda no encontrada</p>
            )}
          </>
        )}
      </ReactModal>
    </>
  );
}

export default ExpedienteDoc;
