import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import ReactModal from "react-modal";
import PDF from "../../components/PDF";
import { PDFDocument } from "pdf-lib";
import ExpedientePdf from "../../components/expe_pdf";

function CuadernoExpeDoc() {
  const VideoPreview = ({ src }) => {
    if (src.includes("youtube.com")) {
      // Si es un enlace de YouTube
      const videoId = src.split("/").pop();
      return (
        <iframe
          className="col-6"
          width="300"
          height="200"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (src.includes("drive.google.com")) {
      // Si es un enlace de Google Drive

      const fileIdMatch = src.match(/\/file\/d\/(.+?)\/(?:.*)/);
      if (fileIdMatch && fileIdMatch.length > 1) {
        const fileId = fileIdMatch[1];
        return (
          <iframe
            className="col-6"
            width="300"
            height="200"
            src={`https://drive.google.com/file/d/${fileId}/preview`}
            title="Google Drive file preview"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        );
      }
    } else {
      // Si no se reconoce el tipo de enlace
      return <p>No se pudo cargar el preview.</p>;
    }
  };

  const [isLoading, setLoading] = useState(true);

  const [demandaLoaded, setDemandaLoaded] = useState(false);
  const [dateenvioExpediente, SetDataEnvio] = useState([]);
  const [dateenvioExpedienteDemanda, SetDataEnvioDemanda] = useState([]);

  const [etapamateria, setEtapaMateria] = useState([]);

  const [viaprocedi, setViaProcedi] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [inputDisabled, setInputDisabled] = useState(true); // Estado para habilitar o deshabilitar el input
  const [docente, setdocente] = useState([]);
  var style1 = { color: "#4b4b4b", fontSize: "13px" };
  var style2 = {
    border: "1px solid rgb(172, 172, 172)",
    fontSize: "13px",
    textTransform: "uppercase",
    color: "#4b4b4b",
  };
  const toggleEdit = () => {
    setDisabled(!disabled);
  };
  const { id } = useParams();
  const [expediente, setExpediente] = useState([]);
  const [idcuaderno, setIdcuaderno] = useState([]);

  // --------- Inicio --------Data para editar demanda
  const [sala, setsala] = useState([]);
  const [salasupre, setsalasupre] = useState([]);

  // Data importante
  const [distrito_judicial, setJudicial] = useState([]);
  const [sede_judi, setSedeJudicial] = useState([]);
  const [juzgado, settipojuzgado] = useState([]);
  const [selectedSede, setSede] = useState("");
  const [selectedJuzga, setJuzgado] = useState("");
  const [seleJuzgado, setSeleJuzgado] = useState("");
  const [tipo_juz, settipojuzg] = useState([]);
  const [selectedJudicial, setSelectedJudicial] = useState("");

  const [selectviaprocedi, setselectViaProcedi] = useState("");
  const [selectinderter, setSelectInder] = useState("");

  //rubirca
  const [rubricas, setRubricas] = useState([]);
  //rubrica selecionada
  const [seleccionadas, setSeleccionadas] = useState(
    Array(rubricas.length).fill(-1)
  );

  const getsalasupre = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}docente/sala_suprema`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setsalasupre(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };

  const getsala = async () => {
    try {
      const response = await fetch(`${window.BACKEND_URL}docente/salas`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        setsala(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };

  const getsede_judicial = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/sede_judicial`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getdistrito_judicial = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}docente/distrito_judicial`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      if (Array.isArray(data)) {
        setJudicial(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getjuzgado = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/juzgados`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      settipojuzgado(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const gettipo_juzgo = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}docente/tipo_juzgado`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      if (Array.isArray(data)) {
        settipojuzg(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };

  const getetapamateria = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/etapa_materias`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      console.log(response.data);
      setEtapaMateria(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getviaprocedi = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/via_prodi`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setViaProcedi(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };

  useEffect(() => {
    getdistrito_judicial();
    getsede_judicial();
    gettipo_juzgo();
    getjuzgado();
    getsala();
    getsalasupre();
    getetapamateria();
    getviaprocedi();
  }, []);

  // --------- Fin -------- Data para editar demanda
  // --------- Inicio -------- Data para editar Avanze

  // --------- Fin -------- Data para editar Avanze

  const fetchData = async () => {
    console.log("id", id);

    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/expe_cuaderno/datget/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setIdcuaderno(response.data[0]._id);

      response.data.map(async (data) => {
        const docenteResponse = await axios.get(
          `${window.BACKEND_URL}users/${data.docente_id}`,
          { headers: { Authorization: sessionStorage.getItem("token") } }
        );

        setdocente(docenteResponse.data.name);
      });

      var recopila_data = {};

      recopila_data.nexpediente = response.data[0].nexpediente;
      recopila_data.created = response.data[0].created;
      recopila_data.alumnose = response.data[0].alumnos;
      recopila_data.materia = response.data[0].datapretension[0].tipo;
      console.log("recopila_data", recopila_data);
      SetDataEnvio(recopila_data);

      // Actualizar el estado de los grupos con los nombres de los usuarios
      setExpediente(response.data);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDemanda = async (demanda) => {
    if (demanda && !demandaLoaded) {
      try {
        var recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };

        console.log(demanda);

        const response_sede_id = await axios.get(
          `${window.BACKEND_URL}docente/sede_judicial/get/${demanda.sede_id}`,
          { headers: headers2 }
        );
        recopila_data.sede = response_sede_id.data.sede;
        const response_distrito = await axios.get(
          `${window.BACKEND_URL}docente/distrito_judicial/get/${demanda.distrito_id}`,
          { headers: headers2 }
        );
        recopila_data.distrito = response_distrito.data.nombre;
        console.log(demanda.juzgado_id);

        const response_juzgado = await axios.get(
          `${window.BACKEND_URL}docente/juzgado/get/${demanda.juzgado_id}`,
          { headers: headers2 }
        );
        recopila_data.juzgado = response_juzgado.data.nombrejuzgado;
        setSeleJuzgado(response_juzgado.data);
        if (demanda.sala_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala = await axios.get(
            `${window.BACKEND_URL}docente/salas/get/${demanda.sala_id}`,
            { headers: headers2 }
          );

          recopila_data.sala = response_sala.data.nombre;
        }
        console.log(demanda.sala_suprema_id);
        if (demanda.sala_suprema_id !== null) {
          // Realizar la solicitud solo si demanda.sala_suprema_id no es null
          const response_sala_suprema = await axios.get(
            `${window.BACKEND_URL}docente/sala_suprema/get/${demanda.sala_suprema_id}`,
            { headers: headers2 }
          );
          recopila_data.sala_suprema = response_sala_suprema.data.nombre;
        }
        const updatedExpediente = {
          ...expediente[0],
          datademanda: recopila_data,
        };

        setExpediente([updatedExpediente, ...expediente.slice(1)]);
        setSelectedJudicial(expediente[0]?.demanda.distrito_id);
        setSede(expediente[0]?.demanda.sede_id);

        setJuzgado(expediente[0]?.demanda.juzgado_id);
        setDemandaLoaded(true);
      } catch (error) {
        // Manejar errores de ambas solicitudes aquí
        console.error("Error al obtener los salasuprema:", error);
      }
    }
    if (expediente?.length > 0 && expediente[0]?.avanze) {
      try {
        const recopila_data = {};
        const headers2 = {
          Authorization: `${sessionStorage.getItem("token")}`,
        };

        // Validar y obtener etapa_materia
        if (expediente[0]?.avanze?.etapa_materia) {
          try {
            const response_etapa_materia = await axios.get(
              `${window.BACKEND_URL}docente/etapa_materia/get/${expediente[0].avanze.etapa_materia}`,
              { headers: headers2 }
            );
            recopila_data.etapa_materia =
              response_etapa_materia.data[0]?.nombre || "Sin nombre";
          } catch (error) {
            console.error("Error al obtener etapa_materia:", error);
            recopila_data.etapa_materia = "Error al obtener datos";
          }
        }

        // Validar y obtener via_procedi
        if (expediente[0]?.avanze?.via_procedi) {
          try {
            const response_via_procedi = await axios.get(
              `${window.BACKEND_URL}docente/via_prodi/get/${expediente[0].avanze.via_procedi}`,
              { headers: headers2 }
            );
            recopila_data.via_procedi =
              response_via_procedi.data?.viaprocedi || "Sin dato";
          } catch (error) {
            console.error("Error al obtener via_procedi:", error);
            recopila_data.via_procedi = "Error al obtener datos";
          }
        }

        // Actualizar el expediente con los nuevos datos
        const updatedExpediente = {
          ...expediente[0],
          dataavanze: recopila_data,
        };

        // Actualizar estados
        SetDataEnvioDemanda(recopila_data);
        setExpediente([updatedExpediente, ...expediente.slice(1)]);
        setselectViaProcedi(expediente[0]?.avanze?.via_procedi || null);
        setSelectInder(expediente[0]?.avanze?.indeterminado || null);
      } catch (error) {
        console.error("Error general:", error);
      }
    } else {
      console.warn("El expediente o los datos de avanze están vacíos o nulos.");
    }
  };

  useEffect(() => {
    if (expediente.length > 0 && !demandaLoaded) {
      fetchDemanda(expediente[0].demanda);
    }
  }, [expediente[0]?.demanda, demandaLoaded]);

  useEffect(() => {
    fetchData();
  }, [isLoading]);

  const confirmsFormu = async (e) => {
    e.preventDefault();

    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const formulario = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        formulario[element.name] = element.value;
      }
    }

    let demanda = {};
    let avanze = {};

    // Mostrar los datos en la consola

    // demanda
    demanda.distrito_id = formulario.distrito_id;
    demanda.juzgado_id = formulario.juzgado_id;

    demanda.sede_id = formulario.sede_id;

    demanda.sumilla = formulario.sumilla;
    demanda.observa = formulario.observa;
    demanda.monto_soles = formulario.monto_soles;
    demanda.observaciones = formulario?.observaciones
      ? formulario.observaciones
      : "";
    demanda.sala_id = formulario.sala_id ? formulario.sala_id : "";
    demanda.sala_suprema_id = formulario.sala_suprema_id
      ? formulario.sala_suprema_id
      : "";
    // Avanze
    avanze.via_procedi = formulario.via_procedi;
    avanze.etapa_materia = formulario.etapa_materia;
    avanze.costos = formulario.costos;
    avanze.indeterminado = formulario.indeterminado;

    console.log(demanda);
    try {
      await axios.put(
        `${window.BACKEND_URL}docente/expe_cuaderno/demanda/${id}`,
        { demanda: demanda },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      await axios.put(
        `${window.BACKEND_URL}docente/expe_cuaderno/avanze/${id}`,
        { avanze: avanze },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      // Mostrar un mensaje de éxito al usuario
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó correctamente!",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  //calificar alumno_cuaderno
  const [RevisarNoti, setNotiopen] = useState(false);
  const [notiId, setNotiId] = useState(null);
  const [notificacion, setNotificacion] = useState("");
  const [nota, setNota] = useState(0);
  const [notiObser, setObservacion] = useState("");
  const [tipoCalificacion, setTipoCalificacion] = useState("");
  const [rubricaSeleccionada, setRubricaSeleccionada] = useState(null);

  const AbrirNotiRevisar = (notiId) => {
    setNotiId(notiId);
    setNotiopen(true);
    setTipoCalificacion("");
  };

  const CerrarNotiRevisar = () => {
    setNotiId(null);
    setNotiopen(false);
  };
  //obtener la notificacion
  const getNotiCuaderno = async () => {
    setLoading(true); // Iniciar carga
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cuaderno_noti_revisar/${id}/notificacion/${notiId}`
      );

      setNotificacion(response.data);
      setNota(response.data.calificacion); // Establecer la calificación como valor inicial de nota
      setObservacion(response.data.observanoti);
    } catch (error) {
      console.error("Error al obtener la notificación:", error);
    } finally {
      setLoading(false); // Detener carga
    }
  };
  useEffect(() => {
    const obtenerRubricas = async () => {
      if (expediente[0]?.curso_id) {
        try {
          const response = await axios.get(
            `${window.BACKEND_URL}docente/curso/rubrica/${expediente[0].curso_id}`
          );
          setRubricas(response.data);
        } catch (error) {
          console.error("Error al obtener las rubricas:", error);
        }
      } else {
      }
    };

    obtenerRubricas();
  }, [expediente]);

  //calificar la notificacion
  const calificarAlumno = async (e) => {
    e.preventDefault();
    try {
      let notaNumber = parseInt(nota); // Convertir nota a número
      if (tipoCalificacion === "simple") {
        await axios.put(
          `${window.BACKEND_URL}docente/cuadernos_expe/${id}/notifica_cuadernos/${notiId}`,
          { tipoCalificacion, nota: notaNumber, notiObser } // Incluir la observacion en los datos enviados al servidor
        );
      } else if (tipoCalificacion === "rubrica") {
        const descripcionSeleccionada = seleccionadas.map(
          (descripcionIndex, criterioIndex) => ({
            criterioIndex,
            descripcionIndex,
          })
        );
        await axios.put(
          `${window.BACKEND_URL}docente/cuadernos_expe/${id}/notifica_cuadernos/${notiId}`,
          {
            tipoCalificacion,
            nota: calcularNotaTotal(),
            rubricaSeleccionada,
            descripcionSeleccionada,
          }
        );
      }

      Swal.fire("Se califico Correctamente", "", "success");

      setNotiopen();
    } catch (error) {
      console.error("Error al enviar la calificación:", error);
    }
  };
  //selecionar rubrica y mostrarl el toala
  const handleSeleccionarDescripcion = (descripcionIndex, criterioIndex) => {
    const nuevasSeleccionadas = [...seleccionadas];
    nuevasSeleccionadas[criterioIndex] = descripcionIndex;
    setSeleccionadas(nuevasSeleccionadas);
  };

  const calcularNotaTotal = () => {
    let total = 0;
    seleccionadas.forEach((descripcionIndex, criterioIndex) => {
      const criterio = rubricas.find(
        (rubrica) => rubrica.rubrica_id === rubricaSeleccionada
      ).criterios[criterioIndex];
      if (criterio && criterio.descripciones[descripcionIndex]) {
        total += criterio.descripciones[descripcionIndex].nota;
      }
    });
    return total;
  };
  const handleChangeTipoCalificacion = (e) => {
    setTipoCalificacion(e.target.value);
    setRubricaSeleccionada(null); // Reiniciar la rubrica seleccionada al cambiar el tipo de calificación
  };

  //calificar Demanda
  const [RevisarDeman, setDemandaRevisar] = useState(false);
  const [DemandaRe, setDemandaRe] = useState(false);

  const ArbirDemanda = () => {
    setDemandaRevisar(true);
    setTipoCalificacion("");
  };

  const CerrarDemanda = () => {
    setDemandaRevisar(false);
  };

  const getDemandaCu = async () => {
    setLoading(true); // Iniciar carga
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cuaderno_demanda_revisar/${id}`
      );

      setDemandaRe(response.data);
      setNota(response.data.calificacion); // Establecer la calificación como valor inicial de nota
      setObservacion(response.data.observanoti);
    } catch (error) {
      console.error("Error al obtener la notificación:", error);
    } finally {
      setLoading(false); // Detener carga
    }
  };
  //calificar demadna
  const calificarDemanda = async (e) => {
    e.preventDefault();
    try {
      let notaNumber = parseInt(nota); // Convertir nota a número
      if (tipoCalificacion === "simple") {
        await axios.put(
          `${window.BACKEND_URL}docente/cuaderno/calificar/demanda/${id}`,
          { tipoCalificacion, nota: notaNumber, notiObser } // Incluir la observacion en los datos enviados al servidor
        );
      } else if (tipoCalificacion === "rubrica") {
        const descripcionSeleccionada = seleccionadas.map(
          (descripcionIndex, criterioIndex) => ({
            criterioIndex,
            descripcionIndex,
          })
        );
        await axios.put(
          `${window.BACKEND_URL}docente/cuaderno/calificar/demanda/${id}`,
          {
            tipoCalificacion,
            nota: calcularNotaTotal(),
            rubricaSeleccionada,
            descripcionSeleccionada,
          }
        );
      }

      Swal.fire("Se califico Correctamente", "", "success");

      setDemandaRevisar(false);
    } catch (error) {
      console.error("Error al enviar la calificación:", error);
    }
  };

  //eliminar notificiaones
  const notidelete = async (noti_id) => {
    try {
      // no se encuentra Cuaderno_noti
      await axios.delete(
        `${window.BACKEND_URL}Cuaderno_noti/${id}/${noti_id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire("Notificacion eliminada", "", "success");

      fetchData();
    } catch (error) {
      Swal.fire("Error al eliminar la notificación", "", "error");
      console.error("Error deleting notification:", error);
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const descargaex = () => {
    obtenerExpediente();
  };

  const obtenerExpediente = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    // const resultado = Prueba({
    //   data_expe: 1,
    //   data_demanda: 2,
    //   fecha: 1,
    //   destinatario: 1,
    //   numnotifica: 1,
    // });
    // console.log(resultado);

    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/cuader_expediente/get/normal/${id}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const expediente = response.data;
      const demanda = expediente.demanda;
      const notificaciones = expediente.notifica;
      console.log("errror", notificaciones);
      // Array para almacenar las promesas de carga de archivos PDF
      const pdfPromises = [];

      // Manejar la demanda primero
      if (demanda && demanda.file) {
        const demandaPromise = fetch(
          `${window.BACKEND_URL}uploads/${demanda.file}`
        )
          .then((res) => res.arrayBuffer())
          .then((pdfBytes) => ({ pdfBytes, demanda }));

        pdfPromises.push(demandaPromise);
      }

      // Luego manejar las notificaciones
      for (const notificacion of notificaciones) {
        if (notificacion && notificacion.file) {
          if (notificacion.estado === 1) {
            const numnotifica = notificacion.numbenotifi;

            const data_expe = dateenvioExpediente;
            const data_demanda = dateenvioExpedienteDemanda;

            const fecha = notificacion.fechaCreacion.slice(0, 10);

            const resultado = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,
              destinatario: 1,
              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado);
            }

            const resultado2 = await ExpedientePdf({
              data_expe: data_expe,
              data_demanda: data_demanda,
              fecha: fecha,

              numnotifica: numnotifica,
            })
              .then((res) => res.arrayBuffer())
              .then((pdfBytes) => ({ pdfBytes, notificacion }))
              .catch((error) => {
                console.error("Error al cargar archivo PDF:", error);
                return null;
              });

            if (resultado) {
              pdfPromises.push(resultado2);
            }
          }

          const pdfPromise = fetch(
            `${window.BACKEND_URL}uploads/${notificacion.file}`
          )
            .then((res) => res.arrayBuffer())
            .then((pdfBytes) => ({ pdfBytes, notificacion }))
            .catch((error) => {
              console.error("Error al cargar archivo PDF:", error);
              return null;
            });

          if (pdfPromise) {
            pdfPromises.push(pdfPromise);
          }
        } else {
          console.log("No se encontró archivo en notificación:", notificacion);
        }
      }

      // Esperar a que se completen todas las promesas de carga de archivos PDF
      const pdfDataArray = await Promise.all(pdfPromises);

      // Crear un nuevo documento PDF
      const doc = await PDFDocument.create();

      for (const { pdfBytes, demanda, notificacion } of pdfDataArray) {
        // Agregar las páginas del PDF al documento creado
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await doc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => {
          doc.addPage(page);
        });

        // Aquí puedes hacer algo específico con la demanda o la notificación, por ejemplo:
        console.log("Se ha procesado la demanda:", demanda);
        console.log("Se ha procesado la notificación:", notificacion);
      }

      // Convertir el documento a un array de bytes
      const pdfBytes = await doc.save();

      // Abrir el documento PDF en una nueva ventana
      abrirDocumentoPDF(pdfBytes);
      Swal.fire("Se descargo correctamente", "", "success");
    } catch (error) {
      console.log(error);
      console.error("Error al obtener el expediente:", error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const abrirDocumentoPDF = (pdfBytes) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };
  return (
    <>
      {isLoading ? (
        <p>Cargando...</p>
      ) : (
        <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
          <div className="row container-fluid rounded-end-4">
            <div className="col-md-8 col-6">
              <h2 className="py-2 pt-3"> Expedientes Electrónicos</h2>
            </div>
            <div className="col-md-3 col-3 ">
              {" "}
              <button
                style={{
                  color: "#fff",
                  backgroundColor: "#009688",
                }}
                className="btn  mt-3"
                onClick={descargaex}
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Descargando...
                  </>
                ) : (
                  "Descargar"
                )}
              </button>
            </div>
            <div className="col-md-1 col-2  dropdown ">
              <button
                className="btn mt-3"
                onClick={toggleDropdown}
                aria-expanded={isOpen ? "true" : "false"}
              >
                <i className="fas fa-ellipsis fa-lg bg-stemis"></i>
              </button>
              <ul
                className={`dropdown-menu ${isOpen ? "show" : ""}`}
                onClick={toggleDropdown}
                style={{ left: "-5rem" }}
              >
                <li>
                  <button
                    onClick={toggleEdit}
                    className={"dropdown-item bg-stemis "}
                  >
                    <i className="fa-solid fa-pen-to-square"></i> Editar
                  </button>
                </li>
                <li>
                  <Link
                    to={`/${id}/${idcuaderno}/add_escritos`}
                    className="dropdown-item bg-stemis"
                  >
                    <i className="fa-regular fa-plus bg-stemis me-1"></i>
                    Escrito
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${id}/${idcuaderno}/add_notifi`}
                    className="dropdown-item bg-stemis"
                  >
                    <i className="fa-regular fa-plus bg-stemis me-1"></i>
                    Notificación
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {expediente.map((data) => (
            <section key={data._id}>
              <form onSubmit={confirmsFormu} id="myForm">
                <div className="container-fluid mt-3">
                  <h3>Identificación de Procesos</h3>
                  <div className="row">
                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            N° del Expediente:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data.nexpediente}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Juez:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data.alumnos
                              .filter((alumno) => alumno.rol_id === "Juez")
                              .map((alumno) => alumno.nombre)
                              .join(", ")}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Distrito Judicial:
                            <text className="text-danger">*</text>
                          </p>
                        </div>

                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="distrito_id"
                            id="distrito_id"
                            disabled={disabled}
                            style={style2}
                            onChange={(e) => {
                              setSelectedJudicial(e.target.value);
                            }}
                            required
                          >
                            {distrito_judicial
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((judicial) => (
                                <option
                                  key={judicial._id}
                                  value={judicial._id}
                                  selected={
                                    judicial._id ===
                                    (data?.demanda?.distrito_id || "")
                                  }
                                >
                                  {judicial.nombre}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Juzgado:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="juzgado_id"
                            id="juzgado_id"
                            disabled={disabled}
                            style={style2}
                            onChange={(e) => {
                              setJuzgado(e.target.value);
                            }}
                            required
                          >
                            {juzgado
                              .filter((juzga) => juzga.sede_id === selectedSede)
                              .map((datos) => (
                                <option
                                  key={datos._id}
                                  value={datos._id}
                                  selected={
                                    datos._id ===
                                    (data?.demanda.juzgado_id || "sa")
                                  }
                                >
                                  {datos.nombrejuzgado}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Sala suprema:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="sala_suprema_id"
                            id="sala_suprema_id"
                            disabled={disabled}
                            style={style2}
                          >
                            <option value=""></option>

                            {salasupre
                              .filter(
                                (dat) => dat.materia === seleJuzgado.materia
                              )
                              .map((datos) => (
                                <option
                                  key={datos._id}
                                  value={datos._id}
                                  selected={
                                    datos._id ===
                                    (data?.demanda?.sala_suprema_id || "")
                                  }
                                >
                                  {datos.nombre}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      {/* Otros campos similares se pueden agregar aquí */}
                    </div>

                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Docente:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={docente}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Especialista:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            value={data.alumnos
                              .filter(
                                (alumno) => alumno.rol_id === "Especialista"
                              )
                              .map((alumno) => alumno.nombre)
                              .join(", ")}
                            style={style2}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Sede:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <select
                            className="form-select"
                            name="sede_id"
                            id="sede_id"
                            disabled={disabled}
                            onChange={(e) => {
                              const select = sede_judi.find(
                                (data) => data._id === e.target.value
                              );

                              setSede(select._id);
                            }}
                            style={style2}
                            required
                          >
                            <option value=""></option>
                            {sede_judi
                              .filter(
                                (sede) => sede.distrito === selectedJudicial
                              )
                              .map((sedej) => (
                                <option
                                  key={sedej._id}
                                  value={sedej._id}
                                  selected={
                                    sedej._id === (data?.demanda.sede_id || "")
                                  }
                                >
                                  {sedej.sede}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Sala:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <select
                            className="form-select"
                            name="sala_id"
                            id="sala_id"
                            disabled={disabled}
                            style={style2}
                          >
                            <option value=""></option>
                            {sala
                              .filter((sala) => sala.sede_id === selectedSede)
                              .map((datos) => (
                                <option
                                  key={datos._id}
                                  value={datos._id}
                                  selected={
                                    datos._id ===
                                    (data?.demanda?.sala_id || "sa")
                                  }
                                >
                                  {datos.nombre}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Sumilla:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <input
                            type="text"
                            name="sumilla"
                            value={disabled ? data.demanda?.sumilla : null}
                            className="form-control"
                            style={style2}
                            disabled={disabled}
                          />
                        </div>
                      </div>

                      {/* Otros campos similares se pueden agregar aquí */}
                    </div>
                  </div>
                  <hr className="p-0" />
                </div>
                <div className="container-fluid mt-3">
                  <h3>Detalle del Procesos</h3>
                  <div className="row">
                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Fecha de inicio:
                            <text className="text-danger">*</text>
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="date"
                            className="form-control"
                            style={style2}
                            value={data.fecha ? data.fecha.split("T")[0] : ""}
                            // Asumiendo que data.created es en formato ISO8601
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Pretencion:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data?.datapretension[0]?.nombre}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Etapa de materia:
                            <text className="text-danger">*</text>
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="etapa_materia"
                            id="etapa_materia"
                            disabled={disabled}
                            style={style2}
                          >
                            {" "}
                            <option value=""></option>
                            {etapamateria
                              .filter(
                                (via) => via.viaprocedi === selectviaprocedi
                              )
                              .map((etapa_mate) => (
                                <option
                                  key={etapa_mate._id}
                                  value={etapa_mate._id}
                                  selected={
                                    etapa_mate._id ===
                                    (data?.avanze?.via_procedi || "")
                                  }
                                >
                                  {etapa_mate.nombre}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Costas Costos:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            name="costos"
                            className="form-control"
                            value={disabled ? data.avanze?.costos : null}
                            style={style2}
                            disabled={!disabled ? inputDisabled : true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Materia:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data?.datapretension[0]?.tipo}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Via Procidemental:
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            className="form-select"
                            name="via_procedi"
                            id="via_procedi"
                            disabled={disabled}
                            onChange={(e) => {
                              const select = viaprocedi.find(
                                (data) => data._id === e.target.value
                              );

                              setselectViaProcedi(select?._id);
                            }}
                            style={style2}
                          >
                            {" "}
                            <option value=""></option>
                            {viaprocedi
                              .filter(
                                (via) =>
                                  via.materia === data.datapretension[0].tipo
                              )
                              .map((viaprocedi) => (
                                <option
                                  key={viaprocedi._id}
                                  value={viaprocedi._id}
                                  selected={
                                    viaprocedi._id ===
                                    (data?.avanze?.via_procedi || "")
                                  }
                                >
                                  {viaprocedi.viaprocedi}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Indeterminado:
                          </p>
                        </div>
                        <div className="col-md-8 px-0 mt-1">
                          <select
                            className="form-select"
                            name="indeterminado"
                            id="indeterminado"
                            defaultValue={selectinderter === "SI" ? "SI" : "NO"} // Asignamos "SI" si selectinderter es "SI", de lo contrario, asignamos "NO"
                            disabled={disabled}
                            onChange={(e) => {
                              console.log(selectinderter);
                              const value = e.target.value;
                              setInputDisabled(value === "SI"); // Si el valor es "SI", setInputDisabled(true); de lo contrario, setInputDisabled(false);
                            }}
                            style={{
                              border: "1px solid rgb(172, 172, 172)",
                              fontSize: "14px",
                              color: "#4b4b4b",
                              maxWidth: "90px",
                            }}
                          >
                            {selectinderter === "SI" ? (
                              <>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </>
                            ) : (
                              <>
                                <option value="NO">NO</option>
                                <option value="SI">SI</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Monto Soles :
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-1">
                          <input
                            type="text"
                            name="monto_soles"
                            defaultValuevalue={
                              disabled ? data.demanda?.monto_soles : null
                            }
                            className="form-control"
                            style={style2}
                            disabled={disabled}
                          />
                        </div>
                      </div>

                      {/* Otros campos similares se pueden agregar aquí */}
                    </div>

                    <div className="col col-md-2 mt-2 px-4">
                      <p className="text-left" style={style1}>
                        Observaciones:
                      </p>
                    </div>
                    <div className="col-md-10 mt-2 px-1">
                      <textarea
                        className="form-control"
                        name="observa"
                        style={style2}
                        defaultValue={disabled ? data.demanda?.observa : null}
                        disabled={disabled}
                      ></textarea>
                    </div>
                  </div>
                  <hr className="p-0" />
                </div>
                <div className="container-fluid mt-3">
                  <h3>Partes de procesales</h3>
                  <div className="row">
                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-4 mt-2">
                          <p className="text-left" style={style1}>
                            Parte demandante:
                            <text className="text-danger">*</text>
                          </p>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data.alumnos
                              .filter(
                                (alumno) => alumno.rol_id === "Demandante"
                              )
                              .map((alumno) => alumno.nombre)
                              .join(", ")}
                            disabled
                          />
                        </div>
                      </div>

                      {/* Otros campos similares se pueden agregar aquí */}
                    </div>

                    <div className="col-md-6 px-4">
                      <div className="row">
                        <div className="col-6 col-md-3 mt-2">
                          <p className="text-left" style={style1}>
                            Parte demandada:
                          </p>
                        </div>
                        <div className="col-md-9 p-0 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            style={style2}
                            value={data.alumnos
                              .filter((alumno) => alumno.rol_id === "Demandado")
                              .map((alumno) => alumno.nombre)
                              .join(", ")}
                            disabled
                          />
                        </div>
                      </div>

                      {/* Otros campos similares se pueden agregar aquí */}
                    </div>
                  </div>
                  <hr className="p-0" />

                  {!disabled && (
                    <div className="row">
                      <div className="col-6 p-0 mb-3 mt-4"></div>
                      <div className="col-6 mb-4 mt-4 d-flex flex-row-reverse ">
                        <button
                          id="myButton"
                          className="btn btn-primary btn px-5"
                          type="submit"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>

              <div className="container ">
                <ul
                  className="timeline pb-4"
                  // id="expediante-scroll"
                >
                  {/* Demanda */}

                  {data.notifica
                    ? data.notifica.map((datanotifi, index) => (
                        <li key={index}>
                          <div className="timeline-time">
                            <span className="date">
                              {new Date(datanotifi.fechaCreacion).getDate()} de
                              {
                                meses[
                                  new Date(datanotifi.fechaCreacion).getMonth()
                                ]
                              }
                              de
                              {new Date(datanotifi.fechaCreacion).getFullYear()}
                            </span>
                            <span className="time">
                              {new Date(datanotifi.fechaCreacion).getHours()}:
                              {new Date(datanotifi.fechaCreacion).getMinutes()}:
                              {new Date(datanotifi.fechaCreacion).getSeconds()}
                            </span>
                          </div>
                          <div className="timeline-icon">
                            <a href="javascript:;">&nbsp;</a>
                          </div>
                          <div className="timeline-body  rounded shadow ">
                            <div className="timeline-header">
                              <div className="row  py-1">
                                <div className="col-md-4 text-white ">
                                  <span>
                                    <h3>
                                      {datanotifi?.tipo}: {"000" + (index + 1)}
                                    </h3>
                                  </span>
                                </div>

                                <div className="col-md-2 p-0 mx-0 text-end ">
                                  <button
                                    onClick={() =>
                                      AbrirNotiRevisar(datanotifi.noti_id)
                                    }
                                    className="btn btn-noti btn-sm"
                                  >
                                    <span className="me-1">Revisar</span>
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </button>
                                </div>
                                <div className="col-md-2 p-0 mx-0 text-end ">
                                  <button
                                    onClick={() => {
                                      notidelete(datanotifi.noti_id);
                                    }}
                                    className="btn btn-noti btn-sm"
                                  >
                                    <span className="me-1">Eliminar</span>
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                                <div className="col-md-2 p-0 mx-0 text-end">
                                  <Link
                                    to={`/noti-cuaderno/${id}/edit-cuaderno/${datanotifi.noti_id}`}
                                    className="btn btn-noti  btn-sm"
                                  >
                                    <span className="me-1">Editar</span>
                                    <i className="fa-solid fa-circle-plus "></i>{" "}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="timeline-content">
                              <div className="row  ">
                                <div className="col-md-5 p-0">
                                  <p>
                                    <h4>Acto:</h4> {datanotifi.acto}
                                  </p>
                                </div>
                                <div className="col-md-7 text-end ">
                                  <p>
                                    <h4>Creador por: </h4>
                                    {datanotifi.user}
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 px-0 mx-0">
                                  <div className="row">
                                    {datanotifi.estado === 2 ? (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row px-0 mx-0">
                                          <div
                                            className={`${
                                              datanotifi.grab_audiencia
                                                ? `col-6 `
                                                : `col-12 `
                                            }`}
                                          >
                                            <input
                                              type="text"
                                              className="form-control custom-input-width" // Agregamos una clase personalizada para controlar el ancho
                                              value={datanotifi.sumilla}
                                              disabled
                                              style={{
                                                height: "200px",
                                                paddingTop: "0",
                                                verticalAlign: "top",
                                              }} // Establecemos el ancho directamente en línea utilizando estilos en línea
                                            />
                                          </div>

                                          {datanotifi.grab_audiencia ? (
                                            <>
                                              <VideoPreview
                                                src={`${datanotifi.grab_audiencia}`}
                                              />
                                            </>
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                      </>
                                    ) : datanotifi.estado === 1 ? (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row px-0 mx-0 ">
                                          <div className="col-12">
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={datanotifi.sumilla}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12 py-2">
                                            <h4 className="">
                                              {/* N° de Notificaciones: */}
                                              Notificaciones: 2
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="integrat-cursos-scroll">
                                          <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                            <div className="row justify-content-center m-1 ">
                                              <div className="col-6 text-start mt-2 ">
                                                <div className="col">
                                                  <h5>
                                                    {" Notificacion  "}
                                                    {datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )}
                                                  </h5>
                                                </div>
                                                <div className="col">
                                                  <h4>Destinatario: </h4>
                                                  Demandados
                                                </div>
                                              </div>
                                              <div className="col-4 text-start align-self-end">
                                                <div className="pb-2">
                                                  <h4>Fecha: </h4>
                                                  {datanotifi.fechaCreacion.slice(
                                                    0,
                                                    10
                                                  )}
                                                </div>
                                              </div>

                                              <div className="col-2 text-center align-self-center">
                                                <PDF
                                                  data_href={`${datanotifi.file} `}
                                                  data_expe={
                                                    dateenvioExpediente
                                                  }
                                                  data_demanda={
                                                    dateenvioExpedienteDemanda
                                                  }
                                                  fecha={datanotifi.fechaCreacion.slice(
                                                    0,
                                                    10
                                                  )}
                                                  numnotifica={
                                                    datanotifi?.numbenotifi +
                                                    data.nexpediente.slice(
                                                      5,
                                                      10
                                                    ) +
                                                    data.nexpediente.slice(
                                                      17,
                                                      23
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="integrat-cursos-scroll">
                                          <div className="container-fluid bg-body-secondary text-center rounded-2 p-0 m-0">
                                            <div className="row justify-content-center m-1 ">
                                              <div className="col-6 text-start mt-2 ">
                                                <div className="col">
                                                  <h5>
                                                    {" Notificacion  "}
                                                    {datanotifi?.numbenotifi +
                                                      data.nexpediente.slice(
                                                        5,
                                                        10
                                                      ) +
                                                      data.nexpediente.slice(
                                                        17,
                                                        23
                                                      )}
                                                  </h5>
                                                </div>
                                                <div className="col">
                                                  <h4>Destinatario: </h4>
                                                  Demandantes
                                                </div>
                                              </div>
                                              <div className="col-4 text-start align-self-end">
                                                <div className="pb-2">
                                                  <h4>Fecha: </h4>
                                                  {datanotifi.fechaCreacion.slice(
                                                    0,
                                                    10
                                                  )}
                                                </div>
                                              </div>

                                              <div className="col-2 text-center align-self-center">
                                                <PDF
                                                  data_href={`${datanotifi.file} `}
                                                  data_expe={
                                                    dateenvioExpediente
                                                  }
                                                  data_demanda={
                                                    dateenvioExpedienteDemanda
                                                  }
                                                  destinatario={1}
                                                  fecha={datanotifi.fechaCreacion.slice(
                                                    0,
                                                    10
                                                  )}
                                                  numnotifica={
                                                    datanotifi?.numbenotifi +
                                                    data.nexpediente.slice(
                                                      5,
                                                      10
                                                    ) +
                                                    data.nexpediente.slice(
                                                      17,
                                                      23
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-12 col-md-2 mt-1 py-2">
                                          <h4>Sumilla:</h4>
                                        </div>
                                        <div className="row px-0 mx-0 ">
                                          <div className="col-12">
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={datanotifi.sumilla}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    : null}
                  {/* <DataNotifiExpe /> */}
                </ul>
              </div>
            </section>
          ))}
        </div>
      )}

      <ReactModal
        onAfterOpen={getNotiCuaderno}
        isOpen={RevisarNoti}
        onRequestClose={CerrarNotiRevisar}
        contentLabel="revisar tarea"
      >
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {notificacion ? (
              <>
                <form onSubmit={calificarAlumno}>
                  <div className="row">
                    <h2>Revisar {notificacion.acto}</h2>
                    <div className="col col-md-8">
                      <iframe
                        src={`${window.BACKEND_URL}uploads/${notificacion.file}`}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>
                    <div className="col col-md-3">
                      <select
                        value={tipoCalificacion}
                        onChange={handleChangeTipoCalificacion}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Selecione una opccion</option>
                        <option value="simple">Calificación Simple</option>
                        <option value="rubrica">
                          Calificación por Rubrica
                        </option>
                      </select>
                      {tipoCalificacion === "simple" && (
                        <>
                          <label htmlFor="calificacionSimple">
                            Calificación:
                          </label>
                          <input
                            type="number"
                            name="nota"
                            className="form-control"
                            min="0"
                            max="20"
                            value={nota}
                            onChange={(e) => setNota(e.target.value)}
                          />
                          <label htmlFor="observaciones">Observaciones:</label>
                          <textarea
                            id="observacion"
                            name="observacion"
                            className="form-control"
                            value={notiObser}
                            onChange={(e) => setObservacion(e.target.value)}
                          ></textarea>
                        </>
                      )}
                      {tipoCalificacion === "rubrica" && (
                        <>
                          <select
                            id="selectRubrica"
                            value={rubricaSeleccionada}
                            onChange={(e) => {
                              setRubricaSeleccionada(e.target.value);
                            }}
                            className="form-select mt-3"
                          >
                            <option value="">Seleccione una rúbrica</option>
                            {rubricas.map((rubrica) => (
                              <option
                                key={rubrica.rubrica_id}
                                value={rubrica.rubrica_id}
                              >
                                {rubrica.titulo}
                              </option>
                            ))}
                          </select>
                          {rubricaSeleccionada &&
                            rubricas
                              .filter(
                                (rubrica) =>
                                  rubrica.rubrica_id === rubricaSeleccionada
                              )
                              .map((rubrica) => (
                                <div
                                  key={rubrica.rubrica_id}
                                  className="rubrica-details"
                                >
                                  <h2>{rubrica.titulo}</h2>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Criterio</th>
                                        {rubrica.criterios[0]?.descripciones.map(
                                          (_, index) => (
                                            <th key={index}>
                                              Descripción {index + 1}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rubrica.criterios.map(
                                        (criterio, criterioIndex) => (
                                          <tr key={criterioIndex}>
                                            <td>{criterio.criterio}</td>
                                            {criterio.descripciones.map(
                                              (
                                                descripcion,
                                                descripcionIndex
                                              ) => (
                                                <td
                                                  key={descripcionIndex}
                                                  className={
                                                    seleccionadas[
                                                      criterioIndex
                                                    ] === descripcionIndex
                                                      ? "selected"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleSeleccionarDescripcion(
                                                      descripcionIndex,
                                                      criterioIndex
                                                    )
                                                  }
                                                >
                                                  {descripcion.descripcion}{" "}
                                                  (Nota: {descripcion.nota})
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <h3>Nota final: {calcularNotaTotal()}</h3>
                                </div>
                              ))}
                        </>
                      )}
                      <div className="row mt-3">
                        <div className="col col-md-6">
                          <button
                            className="btn btn-cancelar"
                            onClick={CerrarNotiRevisar}
                          >
                            Cerrar
                          </button>
                        </div>
                        <div className="col col-md-3">
                          <button
                            type="submit"
                            className="btn btn-stemis"
                            disabled={
                              isLoading || // Si isLoading es true, el botón está deshabilitado
                              tipoCalificacion === "" || // Si no se ha seleccionado el tipo de calificación, el botón está deshabilitado
                              (tipoCalificacion === "simple" &&
                                (nota === 0 ||
                                  (notiObser && notiObser.trim() === ""))) || // Si es calificación simple y nota es 0 o la observación está vacía, el botón está deshabilitado
                              (tipoCalificacion === "rubrica" &&
                                rubricaSeleccionada === "") // Si es calificación por rúbrica y no se ha seleccionado una rúbrica, el botón está deshabilitado
                            }
                          >
                            Calificar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <p>Notificación no encontrada</p>
            )}
          </>
        )}
      </ReactModal>

      <ReactModal
        onAfterOpen={getDemandaCu}
        isOpen={RevisarDeman}
        onRequestClose={CerrarDemanda}
        contentLabel="revisar tarea"
      >
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {DemandaRe ? (
              <>
                <h2>Revisar Demanda</h2>
                <form onSubmit={calificarDemanda}>
                  <div className="row">
                    <div className="col col-md-8">
                      <iframe
                        src={`${window.BACKEND_URL}uploads/${DemandaRe.filee}`}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>
                    <div className="col col-md-3">
                      <select
                        value={tipoCalificacion}
                        onChange={handleChangeTipoCalificacion}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Selecione una opccion</option>
                        <option value="simple">Calificación Simple</option>
                        <option value="rubrica">
                          Calificación por Rubrica
                        </option>
                      </select>
                      {tipoCalificacion === "simple" && (
                        <>
                          <label htmlFor="calificacionSimple">
                            Calificación:
                          </label>
                          <input
                            type="number"
                            name="nota"
                            className="form-control"
                            min="0"
                            max="20"
                            value={nota}
                            onChange={(e) => setNota(e.target.value)}
                          />
                          <label htmlFor="observaciones">Observaciones:</label>
                          <textarea
                            id="observacion"
                            name="observacion"
                            className="form-control"
                            value={notiObser}
                            onChange={(e) => setObservacion(e.target.value)}
                          ></textarea>
                        </>
                      )}
                      {tipoCalificacion === "rubrica" && (
                        <>
                          <select
                            id="selectRubrica"
                            value={rubricaSeleccionada}
                            onChange={(e) => {
                              setRubricaSeleccionada(e.target.value);
                            }}
                            className="form-select mt-3"
                          >
                            <option value="">Seleccione una rúbrica</option>
                            {rubricas.map((rubrica) => (
                              <option
                                key={rubrica.rubrica_id}
                                value={rubrica.rubrica_id}
                              >
                                {rubrica.titulo}
                              </option>
                            ))}
                          </select>
                          {rubricaSeleccionada &&
                            rubricas
                              .filter(
                                (rubrica) =>
                                  rubrica.rubrica_id === rubricaSeleccionada
                              )
                              .map((rubrica) => (
                                <div
                                  key={rubrica.rubrica_id}
                                  className="rubrica-details"
                                >
                                  <h2>{rubrica.titulo}</h2>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Criterio</th>
                                        {rubrica.criterios[0]?.descripciones.map(
                                          (_, index) => (
                                            <th key={index}>
                                              Descripción {index + 1}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rubrica.criterios.map(
                                        (criterio, criterioIndex) => (
                                          <tr key={criterioIndex}>
                                            <td>{criterio.criterio}</td>
                                            {criterio.descripciones.map(
                                              (
                                                descripcion,
                                                descripcionIndex
                                              ) => (
                                                <td
                                                  key={descripcionIndex}
                                                  className={
                                                    seleccionadas[
                                                      criterioIndex
                                                    ] === descripcionIndex
                                                      ? "selected"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleSeleccionarDescripcion(
                                                      descripcionIndex,
                                                      criterioIndex
                                                    )
                                                  }
                                                >
                                                  {descripcion.descripcion}{" "}
                                                  (Nota: {descripcion.nota})
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <h3>Nota final: {calcularNotaTotal()}</h3>
                                </div>
                              ))}
                        </>
                      )}
                      <div className="row mt-3">
                        <div className="col col-md-6">
                          <button
                            className="btn btn-cancelar"
                            onClick={CerrarDemanda}
                          >
                            Cerrar
                          </button>
                        </div>
                        <div className="col col-md-3">
                          <button
                            type="submit"
                            className="btn btn-stemis"
                            disabled={
                              isLoading || // Si isLoading es true, el botón está deshabilitado
                              tipoCalificacion === "" || // Si no se ha seleccionado el tipo de calificación, el botón está deshabilitado
                              (tipoCalificacion === "simple" &&
                                (nota === 0 ||
                                  (notiObser && notiObser.trim() === ""))) || // Si es calificación simple y nota es 0 o la observación está vacía, el botón está deshabilitado
                              (tipoCalificacion === "rubrica" &&
                                rubricaSeleccionada === "") // Si es calificación por rúbrica y no se ha seleccionado una rúbrica, el botón está deshabilitado
                            }
                          >
                            Calificar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <p>Demanda no encontrada</p>
            )}
          </>
        )}
      </ReactModal>
    </>
  );
}

export default CuadernoExpeDoc;
