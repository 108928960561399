import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ListGruop = () => {
  const [Grupos, setGrupos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [GruposComplet, setGruposComplet] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/grupocurso/datos_curso/get/${id}`,
        {
          params: { curso_id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const filteredGrupos = response.data.filter(
        (grupo) => grupo.curso_id === id
      );
      let alumnoIdsSet = new Set();
      response.data.forEach((data) => {
        data.alumnose.forEach((alumno) => {
          alumnoIdsSet.add(alumno.alumno_id);
        });
      });
      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}docente/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );

      const updatedGruposPromises = filteredGrupos.map(async (grupo) => {
        const alumnosWithNamesPromises = grupo.alumnose.map(async (alumno) => {
          const usuario = users.data.find(
            (data) => data._id === alumno.alumno_id
          );

          if (usuario) {
            alumno.nombre = usuario.name;
            alumno.photo = usuario.photo;
          } else {
            console.error(
              "Usuario no encontrado para el ID:",
              alumno.alumno_id
            );
          }
          return alumno;
        });

        const alumnosWithNames = await Promise.all(alumnosWithNamesPromises);

        return { ...grupo, alumnose: alumnosWithNames };
      });

      const updatedGrupos = await Promise.all(updatedGruposPromises);

      const gruposConNombres = await Promise.all(updatedGrupos);

      setGrupos(gruposConNombres);
      setGruposComplet(gruposConNombres);
      console.log("gruposConNombres", gruposConNombres);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cursos:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filtra los grupos según si alguno de sus alumnos coincide con el término de búsqueda

    if (searchTerm && searchTerm.length > 1) {
      const gruposFiltrados = Grupos.filter(
        (grupo) =>
          grupo.alumnose.some((alumno) =>
            alumno.nombre.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          grupo.datapretension.some(
            (dato) =>
              dato.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dato.tipo.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          grupo.expediente_info.some((dato) =>
            dato.nexpediente.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );

      // Almacena los resultados finales en el estado
      setGrupos(gruposFiltrados);
    } else {
      setGrupos(GruposComplet);
    }
  }, [searchTerm]);

  const handleDeleteGroup = async (expedienteId) => {
    try {
      // Mostrar el cuadro de diálogo de confirmación
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma, proceder con la eliminación
      if (result.isConfirmed) {
        await axios.delete(
          `${window.BACKEND_URL}docente/expediente/delet/${expedienteId}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        Swal.fire("Se elimino Correctamente", "", "success");
        const updatedGrupos = Grupos.filter(
          (grupo) => grupo.expe_id !== expedienteId
        );
        setGrupos(updatedGrupos);
      }
    } catch (error) {
      Swal.fire("Error al eliminar la expediente", "", "error");
      console.error("Error deleting expediente:", error);
    }
  };

  return (
    <>
      <div className="row p-3">
        <div className="col-sm-9 col-md-6">
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                className="form-control rounded-start bg-light-subtle border border-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn rounded-end"
              style={{ backgroundColor: "#009688", color: "white" }}
              /*           onClick={handleSearch} */
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-sm-9 col-md-6 d-flex justify-content-end py-sm-0 py-2">
          <Link to={`/creargrupo/${id}`} className="btn  btn-stemis">
            Crear grupo <i className="fa-solid fa-circle-plus"></i>
          </Link>
        </div>
      </div>
      {loading ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Grupos...</p>
        </div>
      ) : Grupos.length === 0 ? (
        <h1 className="text-center">No hay grupos creados</h1>
      ) : (
        <section>
          {Grupos.map((grupo, index) => (
            <div
              key={grupo.id}
              className="container-fluid border shadow  mb-3 rounded-4 "
            >
              <div className="p-2 ">
                <h2 className="">
                  <b>
                    Grupo {index + 1} : {grupo.materia}
                  </b>
                </h2>
              </div>

              <div className="d-xl-flex mb-4">
                <div className="p-2 flex-fill  col-md-4 col-12 me-2 mb-3 shadow rounded-4 ">
                  <h6 className="text-center pb-2 pt-2">
                    <b> Información del caso </b>
                  </h6>
                  <div className="container-fluid bg-body-secondary rounded-2">
                    <div className="row position-relative m-0 g-0 pt-2">
                      <div className="col-12 col-md-5 p-0 m-0">
                        <h4>Materia</h4>
                      </div>
                      <div className="col-12 col-md-7 p-0 m-0">
                        {grupo.materia}
                      </div>
                    </div>
                    <hr className="my-2 py-1" />
                    <div className="row m-0 p-0">
                      <div className="col-12 col-md-5 p-0 m-0">
                        <h4>Pretensión</h4>
                      </div>
                      <div className="col-12 col-md-7 p-0 m-0 fw-bolder">
                        {grupo.datapretension[0].nombre}
                      </div>
                    </div>
                    <hr className="my-2 py-1" />
                    <div className="row m-0 p-0">
                      <div className="col-12 col-md-5 p-0 m-0">
                        <h4>Expediente</h4>
                      </div>
                      <div className="col-12 col-md-7 p-0 m-0">
                        {grupo.expediente_info[0].nexpediente}
                      </div>
                    </div>
                    <hr className="my-2 py-1" />
                    <div className="row m-0 p-0 pb-0 pb-2">
                      <div className="col-12 col-md-5 p-0 m-0">
                        <h4>Integrantes</h4>
                      </div>
                      <div className="col-12 col-md-7 p-0 m-0">
                        {grupo.alumnose.length}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12 mb-3">
                  <div className="p-2 flex-fill shadow  rounded-4 ">
                    <h6 className="text-center pb-2 pt-2">
                      <b> Integrantes </b>
                    </h6>
                    <div className="row p-0">
                      <div className="col" id="integrat-cursos-scroll">
                        {grupo.alumnose.map((alumno) => (
                          <div
                            key={alumno.alumno_id}
                            className="row p-0 m-0 pb-2  text-center"
                          >
                            <div className="col-4 ps-0">
                              <img
                                className="rounded-circle "
                                src={`${window.BACKEND_URL}uploads/${alumno.photo}`}
                                alt=""
                                style={{
                                  minWidth: "2.5rem",
                                  minHeight: "2.5rem",
                                  objectFit: "cover",
                                  maxWidth: "2.5rem",
                                  maxHeight: "2.5rem",
                                }}
                              />
                            </div>
                            <div className="col-8 ps-0 pe-2">
                              <div className="row">
                                <p className="col-12 text-start m-0 p-0">
                                  {alumno.nombre}
                                </p>
                                <div className="col-12 p-0 m-0">
                                  <p className="col-12 text-start m-0 p-0">
                                    <h4 className="text-start p-0 m-0">
                                      {alumno.rol_id}
                                    </h4>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12 shadow rounded-4 ms-2 mb-3 ">
                  <h6 className="text-center pb-4 pt-2">
                    <b>Acciones</b>
                  </h6>
                  <div className="row justify-content-center">
                    <Link
                      to={`/expediente-docente/${grupo.expe_id}/${id}`}
                      className="btn btn-stemis m-2 w-75"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ margin: "auto" }}>Ver Expediente</span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                  <div className="row justify-content-center">
                    <Link
                      to={`/Editar-grupo/${id}/expediente/${grupo._id}`}
                      className="btn btn-stemis m-2 w-75 d-flex justify-content-between align-items-center"
                      style={{
                        width: "250px",
                        color: "#e4e4e4",
                        backgroundColor: "#009688",
                      }}
                    >
                      <span style={{ margin: "auto" }}>Editar</span>
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Link>
                  </div>

                  <div className="row justify-content-center">
                    <button
                      className="btn btn-stemis btn m-2 w-75 d-flex justify-content-between align-items-center"
                      style={{
                        width: "250px",
                        color: "#e4e4e4",
                        backgroundColor: "#009688",
                      }}
                      type="button"
                      data-toggle="modal"
                      data-target="#modalMensaje"
                      onClick={() => handleDeleteGroup(grupo.expe_id)}
                    >
                      <span style={{ margin: "auto" }}>Eliminar</span>
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default ListGruop;
