import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Homealu = () => {
  const [_id] = useState(sessionStorage.getItem("_id"));
  const [anuncioscurso, setdocente] = useState([]);
  const [anunciosexpediente, setgrupocurso] = useState([]);
  const style1 = {
    width: "100%",
    height: "11rem", // o "50%", depende de lo que prefieras
    objectFit: "cover",
  };

  const style2 = {
    width: "100%",
    backgroundColor: "#d9ebe9",
  };

  const style3 = {
    backgroundColor: "#009688",
    color: "#ffffff",
    fontSize: "14px",
    position: "relative",
    top: "-15px",
    textDecoration: "none",
  };

  const [datos, setCursos] = useState([]);
  const [data_id] = useState(sessionStorage.getItem("_id"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        //anuncios
        const response = await axios.get(
          `${window.BACKEND_URL}alumno/anuncios`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        const response2 = await axios.get(
          //cursogrupo
          `${window.BACKEND_URL}alumno/cursogrupo/anuncios/${_id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        let alumnoIdsSet = new Set();
        response2.data[0].anuncios.forEach((data) => {
          alumnoIdsSet.add(data.alumno_id);
        });
        let alumnoIds = Array.from(alumnoIdsSet);

        const users = await axios.get(
          `${window.BACKEND_URL}alumno/users/get/ids`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
            params: {
              userIds: alumnoIds,
            },
          }
        );

        response2.data[0].anuncios.forEach((data) => {
          const user = users.data.find((user) => user._id === data.alumno_id);
          if (user) {
            data.foto = user.photo;
            data.user = user.name;
          }
        });

        // filtro de anuncios de curso

        console.log("filteredData", response.data);
        const filteredData = response.data.filter(
          (data) => data.curso_id === response2.data[0].curso_id
        );
        console.log("filteredData", filteredData);

        if (filteredData) {
          console.log("filteredData", filteredData);

          setdocente(filteredData);
        }

        if (response2.data[0].anuncios) {
          setgrupocurso(response2.data[0].anuncios);
        }
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getCursos = async () => {
      try {
        const response = await axios.get(
          //cursos
          `${window.BACKEND_URL}alumno/curso/home/${data_id}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        );

        setCursos(response.data);
      } catch (error) {
        console.error("Error fetching cursos:", error);
      }
    };

    getCursos();
  }, [data_id]);

  return (
    <>
      <section>
        <div class="row justify-content-between gx-2 pt-3">
          <div class="col-sm-4">
            <div className=" p-3  bg-white rounded-4 pt-2">
              <h3 className="fs-4 text-center">Mis Cursos</h3>
              <hr />

              <div className="notifications-container-notifi ">
                {datos.map((cursos, index) => (
                  <div
                    className="card shadow rounded-4  rounded-4 border-0 mb-3  "
                    key={index}
                    style={style2}
                  >
                    <Link to={`/curso-alumno/${cursos._id}`}>
                      <img
                        src={`${window.BACKEND_URL}uploads/${cursos.imagen}`}
                        className="card-img-top "
                        style={style1}
                        alt="..."
                      />
                    </Link>
                    <h3 className="rounded-1 text-center " style={style3}>
                      {cursos.informacion_titulo[0].nombre}
                    </h3>

                    <div
                      className="row-6 d-flex ms-1 align-items-center"
                      style={{ marginTop: "-18px" }}
                    >
                      <div className="col-6">
                        <p className="">
                          <b>Aula: </b>
                          {cursos.aula}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="">
                          <b>Seccion: </b> {cursos.seccion}
                        </p>
                      </div>
                    </div>
                    <div
                      className="row-6 d-flex ms-1 align-items-center"
                      style={{ marginTop: "-19px" }}
                    >
                      <p className="">
                        <b>Docente: </b>
                        {cursos.informacion_usuario[0].name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div className=" p-3  bg-white rounded-4  pt-2">
              <h3 className="fs-5  text-center">Notificaciones Académicas</h3>
              <hr />

              <div className="notifications-container-notifi px-3 ">
                {anuncioscurso.map((datos, index) => (
                  <div
                    key={index}
                    className="row shadow-sm bg-body-secondary rounded-4 mb-2 pt-1"
                  >
                    <div className="col-md-12 d-flex flex-column justify-content-center ">
                      <div className="row ">
                        <div className="col-md-4 d-flex justify-content-center align-items-center p-0 m-0">
                          <img
                            src={`${window.BACKEND_URL}uploads/${
                              datos.fotodoc ? datos.fotodoc : "imagen.jpg"
                            }`}
                            alt=""
                            className="rounded-circle pt-1"
                            style={{
                              minWidth: "5.0rem",
                              minHeight: "5.5rem",
                              objectFit: "cover",
                              maxWidth: "5.0rem",
                              maxHeight: "5.5rem",
                            }}
                          />
                        </div>
                        <div className="col-md-8">
                          <span>{datos.titulo}</span>
                          <h5>{datos?.titulo_curso}</h5>
                        </div>
                      </div>
                      <div className="pt-1">
                        <p style={{ fontSize: 12 }}>{datos.detalles}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div className=" p-3  bg-white rounded-4  pt-2">
              <h3 className="fs-5  text-center">Notificaciones Académicas</h3>
              <hr />

              <div className="notifications-container-notifi  px-3">
                {anunciosexpediente.map((datos, index) => (
                  <div
                    key={index}
                    className="row shadow-sm bg-body-secondary rounded-4 mb-2 pt-1"
                  >
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={`${window.BACKEND_URL}uploads/${
                          datos.foto ? datos.foto : "imagen.jpg"
                        }`}
                        alt=""
                        className="rounded-circle"
                        style={{
                          minWidth: "5.0rem",
                          minHeight: "5.5rem",
                          objectFit: "cover",
                          maxWidth: "5.0rem",
                          maxHeight: "5.5rem",
                        }}
                      />
                    </div>
                    <div className="col-md-8 d-flex flex-column justify-content-center">
                      <div style={{ fontSize: 11 }} className="  ">
                        <span>{datos.user}</span>
                        <h5>{datos?.numexpe}</h5>
                      </div>
                      <div className=" ">
                        <p>{datos.detalles}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-between gx-2">
          <div className="col-md-4 col-12 pb-3   ">
            <h3 className=" text-center">Mis Cursos</h3>
            <hr />

            {datos.map((cursos, index) => (
              <div
                className="card shadow rounded-4  rounded-4 border-0  "
                key={index}
                style={style2}
              >
                <Link to={`/curso-alumno/${cursos._id}`}>
                  <img
                    src={`${window.BACKEND_URL}uploads/1712614496416-000089669M.jpg`}
                    className="card-img-top "
                    style={style1}
                    alt="..."
                  />
                </Link>
                <h3 className="rounded-1 text-center " style={style3}>
                  {cursos.informacion_titulo[0].nombre}
                </h3>

                <div
                  className="row-6 d-flex ms-1 align-items-center"
                  style={{ marginTop: "-18px" }}
                >
                  <div className="col-6">
                    <p className="">
                      <b>Aula: </b>
                      {cursos.aula}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="">
                      <b>Seccion: </b> {cursos.seccion}
                    </p>
                  </div>
                </div>
                <div
                  className="row-6 d-flex ms-1 align-items-center"
                  style={{ marginTop: "-19px" }}
                >
                  <p className="">
                    <b>Docente: </b>
                    {cursos.informacion_usuario[0].name}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="col-md-4 col-12 pb-3 ">
            <h3 className="  text-center">Notificaciones Académicas</h3>
            <hr />

            <div className="notifications-container-notifi px-3 ">
              {anuncioscurso.map((datos, index) => (
                <div
                  key={index}
                  className="row shadow-sm bg-body-secondary rounded-4 mb-2 pt-1"
                >
                  <div className="col-md-12 d-flex flex-column justify-content-center ">
                    <div className="row ">
                      <div className="col-md-4 d-flex justify-content-center align-items-center p-0 m-0">
                        <img
                          src={`${window.BACKEND_URL}uploads/${
                            datos.fotodoc ? datos.fotodoc : "imagen.jpg"
                          }`}
                          alt=""
                          className="rounded-circle pt-1"
                          style={{
                            minWidth: "5.0rem",
                            minHeight: "5.5rem",
                            objectFit: "cover",
                            maxWidth: "5.0rem",
                            maxHeight: "5.5rem",
                          }}
                        />
                      </div>
                      <div className="col-md-8">
                        <span>{datos.titulo}</span>
                        <h5>{datos?.titulo_curso}</h5>
                      </div>
                    </div>
                    <div className="">
                      <p>{datos.detalles}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-4 col-12 ">
            <h3 className="text-center">Notificaciones de tus clases</h3>
            <hr />

            <div className="notifications-container-notifi  px-3">
              {anunciosexpediente.map((datos, index) => (
                <div
                  key={index}
                  className="row shadow-sm bg-body-secondary rounded-4 mb-2 "
                >
                  <div className="col-md-4 d-flex justify-content-center align-items-center">
                    <img
                      src={`${window.BACKEND_URL}uploads/${
                        datos.foto ? datos.foto : "imagen.jpg"
                      }`}
                      alt=""
                      className="rounded-circle"
                      style={{
                        minWidth: "5.0rem",
                        minHeight: "5.5rem",
                        objectFit: "cover",
                        maxWidth: "5.0rem",
                        maxHeight: "5.5rem",
                      }}
                    />
                  </div>
                  <div className="col-md-8 d-flex flex-column justify-content-center">
                    <div className="  ">
                      <span>{datos.user}</span>
                      <h5>{datos?.numexpe}</h5>
                    </div>
                    <div className=" ">
                      <p>{datos.detalles}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Homealu;
