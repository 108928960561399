import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditDemanda = () => {
  const { id } = useParams();
  const [demanda, setDemanda] = useState({});
  const [loading, setLoading] = useState(true);
  const [filePreview, setFilePreview] = useState(null); // Estado para almacenar la vista previa del archivo

  useEffect(() => {
    const fetchDemanda = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/expediente/get/${id}/demanda`,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        );
        setDemanda(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching demanda:", error);
        setLoading(false);
      }
    };
    fetchDemanda();
  }, [id]);

  // Función para manejar el cambio en el campo de archivo y mostrar la vista previa
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);

        setFilePreview(reader.result); // Almacena la vista previa del archivo como un base64
      };
      reader.readAsDataURL(file); // Lee el archivo como un base64
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("sumilla", e.target.sumilla.value);
      formData.append("observaciones", e.target.observaciones.value);

      // Verificar si se ha seleccionado un archivo nuevo
      if (e.target.file.files[0]) {
        formData.append("file", e.target.file.files[0]);
      }

      await axios.put(
        `${window.BACKEND_URL}docente/editar/expediente/demanda/${id}`,
        formData,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.history.back();
      // Aquí puedes manejar el redireccionamiento o mostrar un mensaje de éxito
    } catch (error) {
      console.error("Error al editar la demanda:", error);
      // Aquí puedes manejar el error, como mostrar un mensaje al usuario
    }
  };
  const handleCancel = () => {
    window.history.back();
  };
  return (
    <section>
      <div className="container-fluid pt-3">
        <h2>Editar Demanda</h2>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="row g-3 needs-validation "
            noValidate
          >
            <div className="col-md-8 col-12 pt-3 ">
              <div className="row  align-items-center pt-2">
                <div className="col-6 col-md-4 ">
                  <p className="mb-0 text-left ">
                    Resumen<span className="text-danger">*</span>
                  </p>
                </div>
                <div className="col-md-8 p-0 ">
                  <input
                    type="text"
                    name="sumilla"
                    id="sumilla"
                    className="form-control"
                    defaultValue={demanda.sumilla}
                    required
                  />
                  <div className="invalid-feedback">Campo Obligatorio.</div>
                </div>
              </div>
              <div className="row  align-items-center pt-2">
                <div className="col-6 col-md-4">
                  <p className="mb-0 ">Observaciones:</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <textarea
                    className="form-control"
                    name="observaciones"
                    id="observaciones"
                    style={{
                      border: "1px solid rgb(231, 231, 231)",
                      height: "100px",
                      resize: "none",
                      fontSize: "14px",
                      color: "#4b4b4b",
                    }}
                    defaultValue={demanda.observaciones}
                  ></textarea>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 ">
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      accept="application/pdf"
                      name="file"
                      id="file"
                      onChange={handleFileChange} // Manejar el cambio de archivo
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              {filePreview && (
                <div className="mt-2 shadow d-none d-sm-inline">
                  <embed
                    src={filePreview}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-6  mt-4">
                <a className="btn btn-cancelar " onClick={handleCancel}>
                  Cancelar
                </a>
              </div>
              <div className="col-6  mt-4 d-flex flex-row-reverse p-0">
                <button type="submit" className="btn btn-stemis ">
                  Grabar
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default EditDemanda;
