import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Loginusuario = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    const fetchLogin = async () => {
      if (submitting) {
        return;
      }

      setSubmitting(true);
      try {
        const postData = {
          email: username,
          password: password,
        };
        const response = await axios.post(
          window.BACKEND_URL + "loginusuarios",
          postData
        );
        console.log(response);
        if (response.status === 200) {
          //setToken(response.data.token);
          sessionStorage.setItem("_id", response.data._id);
          sessionStorage.setItem("userName", response.data.name);

          // sessionStorage.setItem('userGuid', data.userGuid);
          sessionStorage.setItem("role", response.data.role);
          sessionStorage.setItem("photo", response.data.photo);
          // sessionStorage.setItem('tipIns', data.tipIns);
          sessionStorage.setItem("token", response.data.token);
          console.log(sessionStorage);
          navigate("/");
        }
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
        alert("Contraseña incorrecta. Por favor, inténtalo de nuevo.");
      } finally {
        setSubmitting(false);
      }
    };

    if (username && password) {
      fetchLogin();
    } else {
      alert("Por favor, introduce un nombre de usuario y contraseña válidos");
    }
  };

  return (
    <div className="container d-flex justify-content-center  mt-4  ">
      <div className=" row  ">
        <div className=" col-12 d-flex text-end justify-content-end pb-2">
          <a
            className="d-flex align-items-center bg-white rounded-5 shadow ps-2 text-decoration-none"
            href="https://web.whatsapp.com/send?phone=+51991301271&text=Hola%2C%20¿necesitas%20ayuda%3F"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="fa-brands fa-whatsapp bg-stemis px-2 fw-bold"
              style={{ fontSize: 30 }}
            ></i>
            <h2 className="pe-2 fw-bold mb-0" style={{ fontSize: 23 }}>
              ¿Necesitas ayuda?
            </h2>
          </a>
        </div>

        <div className=" col-xxl-5 col-xl-5  col-12     bg-light rounded-5 shadow">
          <div className="mx-4">
            <div className="row justify-content-between  mt-3 ">
              <img
                className="  p-0  "
                src="/img/logo stemis normal.png"
                alt=""
                style={{
                  width: "11.5rem",
                  maxWidth: "none",
                  maxHeight: "none",
                  height: "10%",
                }}
              />

              <img
                className=" p-0 "
                src="/img/UCSM.png"
                alt=""
                style={{
                  width: "4.5rem",
                  maxWidth: "none",
                  maxHeight: "none",
                  height: "10%",
                }}
              />
            </div>

            <form className="">
              <div className="text-center mb-4">
                <h1 className="fw-bold" style={{ fontSize: "3rem" }}>
                  BIENVENIDO
                </h1>
                <h1
                  className="fw-bold "
                  style={{ fontSize: "1rem", color: "#454c5b" }}
                >
                  SIMULADOR DE PROCESOS JUDICIALES
                </h1>
                <h2 className="text-secondary" style={{ fontSize: "1rem" }}>
                  ¿Listo para aprender?
                </h2>
              </div>

              {/* Email input */}
              <div className="mb-3">
                <label className="form-label">
                  <b>Email</b>
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="far fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    placeholder="Correo electrónico"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              {/* Password input */}
              <div className="mb-3">
                <label className="form-label">
                  <b>Contraseña</b>
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fas fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3 text-end">
                <Link
                  to={`/alumno-ForgotPassword`}
                  className="text-secondary"
                  style={{ textDecoration: "underline" }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>

              <div className="text-center mb-4">
                <button
                  type="submit"
                  className="btn btn-stemis btn-lg w-100"
                  onClick={handleLogin}
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="d-none d-sm-inline"> Verificando...</span>
                    </>
                  ) : (
                    <>
                      <span class="d-none d-sm-inline"> Ingresar</span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className=" col-xxl-7 col-xl-7       d-md-block d-none ">
          <img
            src="/img/illustracion stemis 1.png"
            className="img-fluid col-md-7 col-lg-8 "
            style={{
              width: "100%",
              height: "33rem",
              objectFit: "cover",
            }}
            alt="stemis"
          />
        </div>
      </div>
    </div>
  );
};

export default Loginusuario;
