import { Outlet, Link } from "react-router-dom";
import Sliderdoc from "../components/sildvardoc";

import NavBar from "../components/NavBar";
import { useState } from "react";
/* eslint-disable jsx-a11y/anchor-is-valid */

const Layout = () => {
  const photo = sessionStorage.getItem("photo");

  const role = useState(sessionStorage.getItem("role"));

  return (
    <div className="container">
      <nav className="container navbar navbar-expand-xl ">
        <NavBar />
      </nav>
      {/* aca estaba un px-0 */}
      <div
        className="container "
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <section className="d-none d-xl-block">
          <div className="sidebar bg-body-secondary p-3 rounded-4 me-2 custom-sidebar">
            <div className="container-fluid d-flex flex-column align-items-center">
              <div className="mt-3">
                <img
                  src={`${window.BACKEND_URL}uploads/${photo}`}
                  alt=""
                  className="rounded-circle img-fluid"
                  style={{
                    border: "solid 3px #009688",
                    minWidth: "7rem",
                    minHeight: "7rem",
                    objectFit: "cover",
                    maxWidth: "7rem",
                    maxHeight: "7rem",
                  }}
                />
              </div>

              <h5 className="fs-5 pb-0 mb-0 pt-3">{role}</h5>
            </div>

            <hr />

            <Sliderdoc />
          </div>
        </section>
        <div
          className="container-fluid rounded-4 border border-start-5"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Outlet />

          <br />
        </div>
      </div>
    </div>
  );
};

export default Layout;
