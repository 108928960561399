import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SliderAlu = () => {
  const [datos, setCursos] = useState([]);
  const [data_id] = useState(sessionStorage.getItem("_id"));

  useEffect(() => {
    const getCursos = async () => {
      try {
        // cursos titulo
        const response = await axios.get(
          `${window.BACKEND_URL}alumno/cursos/titulo`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        const filteredCursos = response.data.filter((curso) =>
          curso.alumnos_id.some((alumno) => alumno.alu_id === data_id)
        );

        setCursos(filteredCursos);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };

    getCursos();
  }, [data_id]);

  const [showMisCursos, setShowMisCursos] = useState(false);

  const handleMisCursosClick = () => {
    setShowMisCursos(!showMisCursos);
  };

  const [showMiExpedientes, setShowMiExpedientes] = useState(false);

  const handleMisExpedientesClick = () => {
    setShowMiExpedientes(!showMiExpedientes);
  };
  return (
    <>
      <ul className="nav nav-pills flex-column mb-auto px-0 centered-list">
        <li className="nav-item">
          <button
            href="#"
            className="nav-link link-body-emphasis"
            aria-current="page"
            onClick={handleMisCursosClick}
          >
            <i className="fa-solid fa-graduation-cap icon px-1"></i>
            Mis cursos
          </button>
          {/* Subtemas de Mis cursos */}
          {showMisCursos && (
            <ul className="nav nav-pills flex-column mb-auto ms-5">
              {datos.map((curso) => (
                <li key={curso._id} className="nav-item">
                  <Link
                    className="nav-link link-body-emphasis px-0 mx-0"
                    style={{ fontSize: "11px" }}
                    to={`/curso-alumno/${curso._id}`}
                  >
                    {curso.informacion_titulo[0].nombre.substring(0, 50)}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>

        <li className="nav-item">
          <button
            href="#"
            className="nav-link link-body-emphasis"
            aria-current="page"
            onClick={handleMisExpedientesClick}
          >
            <i className="fa-regular fa-folder-open icon  px-1"></i>
            Expedientes
          </button>
          {/* Subtemas de Mis cursos */}
          {showMiExpedientes && (
            <ul className="nav nav-pills flex-column mb-auto ms-5">
              <li className="nav-item">
                <Link
                  to={`/my_expediente`}
                  className="nav-link link-body-emphasis"
                  style={{ fontSize: "12px" }}
                >
                  Mis Expedientes
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/repo_expediente`}
                  className="nav-link link-body-emphasis"
                  style={{ fontSize: "12px" }}
                >
                  Repositorio de Expedientes
                </Link>
              </li>
            </ul>
          )}
        </li>

        <li>
          <Link to={`/calendario`} className="nav-link link-body-emphasis">
            <i className="fa-regular fa-calendar  px-1"></i>
            Calendario
          </Link>
        </li>
        <li>
          <Link to={`/calificaciones`} className="nav-link link-body-emphasis">
            <i className="fa-solid fa-chart-simple icon  px-1"></i>
            Mis notas
          </Link>
        </li>
        <li>
          <Link
            to={`/normatividad/uni`}
            className="nav-link link-body-emphasis"
          >
            <i className="fa-solid fa-book icon px-1"></i>
            Normativa
          </Link>
        </li>
        {/*   <li>
          <a href="/normatividad" className="nav-link link-body-emphasis">
            <i className="fa-solid fa-scale-balanced icon  px-1"></i>
            Normatividad
          </a>
        </li> */}
      </ul>
    </>
  );
};

export default SliderAlu;
